import { combineReducers } from 'redux';

import AcquirersReducer from './ducks/Conciliation/Acquirers';
import CardBrandsReducer from './ducks/Conciliation/CardBrands';
import ConciliationReducer from './ducks/Conciliation/ConciliationAcquirerList';
import ConciliationReceiveReducer from './ducks/Conciliation/ConciliationCardReceiveList';
import FiltersBarsCardsReducer from './ducks/Conciliation/FiltersBars';
import FiltersChartReducer from './ducks/Conciliation/FiltersChart';
import SubsidiariesReducer from './ducks/Conciliation/Subsidiaries';
import ConferenciaDeCaixaReducer from './ducks/Dashboard/Conferencia&Conciliacao/ConferenciaDeCaixa';
import ContaBancariaReducer from './ducks/Dashboard/Conferencia&Conciliacao/ContaBancaria';
import VendaDeCartaoReducer from './ducks/Dashboard/Conferencia&Conciliacao/VendaDeCartao';
import ObterContaReducer from './ducks/Dashboard/LucroLiquido/ObterConta';
import ObterVisaoReducer from './ducks/Dashboard/LucroLiquido/ObterVisao';
import LucroLiquidoReducer from './ducks/Dashboard/LucroLiquido/Ranking';
import UpdateVisaoContaReducer from './ducks/Dashboard/LucroLiquido/UpdateVisaoConta';
import VisaoDeContaReducer from './ducks/Dashboard/LucroLiquido/VisaoDeConta';
import PendenciasFinanceirasReducer from './ducks/Dashboard/PendenciasFinanceiras';
import ResultadosDreReducer from './ducks/Dashboard/ResultadosDRE';
import SaldoDisponivelAntecipacaoReducer from './ducks/Dashboard/SaldoDisponivelAntecipacao';

const reducer = combineReducers({
  AcquirersReducer,
  CardBrandsReducer,
  SubsidiariesReducer,
  FiltersChartReducer,
  ConciliationReducer,
  FiltersBarsCardsReducer,
  ConciliationReceiveReducer,
  ResultadosDreReducer,
  LucroLiquidoReducer,
  ObterVisaoReducer,
  ObterContaReducer,
  UpdateVisaoContaReducer,
  VisaoDeContaReducer,
  PendenciasFinanceirasReducer,
  VendaDeCartaoReducer,
  ConferenciaDeCaixaReducer,
  SaldoDisponivelAntecipacaoReducer,
  ContaBancariaReducer,
});

export default reducer;
