export const Types = {
    GET_CONCILIATION_ACQUIRER:'conciliation/GET_CONCILIATION_ACQUIRER',
    GET_CONCILIATION_ACQUIRER_SUCCESS:'conciliation/GET_CONCILIATION_ACQUIRER_SUCCESS',
    GET_CONCILIATION_ACQUIRER_ERROR:'conciliation/GET_CONCILIATION_ACQUIRER_ERROR'
  }
  
  const INITIAL_STATE = {
    acquirerSalesIds:[],
    loading: false,
    error: '',
  }
  
  export default function conciliationAcquirers(state = INITIAL_STATE, action){
      
      switch(action.type){
        case Types.GET_CONCILIATION_ACQUIRER:{
          return {
            acquirerSalesIds:[],
            loading:true,
            error:''
          }
        }
        case Types.GET_CONCILIATION_ACQUIRER_SUCCESS:{
          return {
            acquirerSalesIds:action.payload,
            loading:false, 
            error:''
          }
        }
  
        case Types.GET_CONCILIATION_ACQUIRER_ERROR:{
          return {
            acquirerSalesIds:[], 
            loading:false, 
            error:action.payload.error
          }
        }
  
        default:
          return state;
      }
  }
  