import {put} from 'redux-saga/effects';
import * as ConciliationServices from 'services/conciliationServices';

import {Types as FiltersBarsCards} from '../../../ducks/Conciliation/FiltersBars'
import {Types as FiltersChart} from '../../../ducks/Conciliation/FiltersChart';

export function* getFiltersBars(action) {
    
    try {
        
        const data = action.payload;
  
        const cardReceive = yield ConciliationServices.getCardReceiveUnconcealedSales(data);
        const sizeCardReceive = cardReceive.records.length;

        const sumResponseFileReceive = yield cardReceive.records.reduce((accumulator, currentValue) => accumulator + currentValue.valorOriginal, 0);
        const sumReceive = Number.parseFloat(sumResponseFileReceive).toFixed(2);
       
        const cardAcquirer = yield ConciliationServices.getAcquirerUnconcealedSales(data);
        const sizeCardAcquirer = cardAcquirer.records.length;


        const sumResponseFile = yield cardAcquirer.records.reduce((accumulator, currentValue) => accumulator + currentValue.grossValue, 0);
        const sumAcquirer = Number.parseFloat(sumResponseFile).toFixed(2);

        yield put({
            type:  FiltersBarsCards.GET_FILTERSBARS_SUCCESS,
            payload: {
                cardReceive,
                sizeCardReceive,
                cardAcquirer,
                sizeCardAcquirer,
                sumAcquirer,
                sumReceive
            }
        });

        
    } catch (error) {

        yield put({
            type: FiltersBarsCards.GET_FILTERSBARS_ERROR,
            payload: error
        });
        
    }
}


export function* getFiltersChart(action) {
    
    try {
        const filters = action.payload;
        
        const records = yield ConciliationServices.getChartResume(filters);
        
        yield put({
            type   : FiltersChart.GET_FILTERSBARS_CHART_SUCCESS,
            payload: records
        });
        
    } catch (error) {
        yield put({
            type   : FiltersChart.GET_FILTERSBARS_CHART_ERROR,
            payload: error
        });
    }
}

