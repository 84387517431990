const baseVersion = '0.1.0';

export const storageKeys = {
  STORE: 'store',
};

const everestSessionStorage = {
  setItem: (key, value, version = baseVersion) => {
    sessionStorage.setItem(
      `@everest/v${version}/${key}`,
      btoa(JSON.stringify(value)),
    );
  },
  getItem: (key, version = baseVersion) => {
    const value = sessionStorage.getItem(`@everest/v${version}/${key}`);
    if (value) {
      return JSON.parse(atob(value));
    }
    return null;
  },
  clean: () => {
    sessionStorage.clear();
  },
};

export default everestSessionStorage;
