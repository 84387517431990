import PropTypes from 'prop-types';
import React from 'react';

import { StyledProgressBar } from './styles';

export default function ProgressBar({ percent, reverse, color, children }) {
  return (
    <>
      <StyledProgressBar percent={percent} reverse={reverse} color={color}>
        {children}
      </StyledProgressBar>
    </>
  );
}

ProgressBar.propTypes = {
  percent: PropTypes.number,
  reverse: PropTypes.bool,
  color: PropTypes.string,
  children: PropTypes.node,
};

ProgressBar.defaultProps = {
  children: <></>,
};

ProgressBar.defaultProps = {
  percent: 0,
  reverse: false,
  color: '#c5b9d2',
};
