/* eslint-disable func-names */
/* eslint-disable no-script-url */
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

const getNeoXUrl = function () {
    return urlParams.get('neox');
}
const getNeoXEndpoint = function (route) {
    const BASE_URL = getNeoXUrl();
    if (!BASE_URL) return 'javascript:void(0)'
    return `${BASE_URL}${route}`;
}
const openNeoXLink = function (e) {
    const link = e.currentTarget.getAttribute('href');
    if (!!link && link.indexOf('http') > -1) {
        window.open(link, '_blank', "noopener noreferrer");
    }
    e.stopPropagation();
    e.preventDefault();
    return false;
}
module.exports = {
    getNeoXUrl,
    getNeoXEndpoint,
    openNeoXLink,
};