import { call, put } from 'redux-saga/effects';

import api from '../../../../../services/api';
import { Types as VendaDeCartaoTypes } from '../../../../ducks/Dashboard/Conferencia&Conciliacao/VendaDeCartao';

export function* getVendaDeCartao(action) {
  try {
    const { startDate } = action.payload.date;

    const response = yield call(
      api.get,
      `/api/v2/conciliation/unconcilied-sales?InitialDate=${startDate.format(
        'Y-MM-DD',
      )}`,
    );

    if (response.status === 200) {
      yield put({
        type: VendaDeCartaoTypes.GET_VENDA_DE_CARTAO_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: VendaDeCartaoTypes.GET_VENDA_DE_CARTAO_ERROR,
      error,
    });
  }
}
