export const Types = {
  SET_VISAO: '@Dashboard/UpdateVisaoConta/SET_VISAO',
  SET_VISAO_SUCCESS: '@Dashboard/UpdateVisaoConta/SET_VISAO_SUCCESS',
  SET_VISAO_ERROR: '@Dashboard/UpdateVisaoConta/SET_VISAO_ERROR',
};

const INITIAL_STATE = {
  data: [],
  loading: false,
  valid: false,
  error: '',
};

export default function updateVisaoConta(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_VISAO: {
      return { data: [], loading: true, valid: false, error: '' };
    }
    case Types.SET_VISAO_SUCCESS: {
      return {
        data: action.data,
        loading: false,
        valid: action.valid,
        error: '',
      };
    }
    case Types.SET_VISAO_ERROR: {
      return {
        data: [],
        loading: false,
        valid: action.valid,
        error: action.error,
      };
    }
    default:
      return state;
  }
}
