
import alertCautionIcon from 'assets/icons/alert-caution.svg';
import alertSuccessIcon from 'assets/icons/alert-check.svg';
import Col from 'components/Grid/Col';
import Container from 'components/Grid/Container';
import Row from 'components/Grid/Row';
import Modal from 'components/Modals/Modal';
import Icon from 'components/Typografy/Icon';
import Paragraph from 'components/Typografy/Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import { withTheme } from 'styled-components';
import theme from 'styles/theme';

const Alert = ({
  message,
  children,
  onCloseCallback,
  isSuccess,
  isLoading,
}) => {
  return (
    <Modal
      isVisible
      showCloseButton
      isLoading={isLoading}
      onCloseCallback={onCloseCallback}
    >
      <Row alignItems="flex-end">
        <Col size={50} alignItems="center" padding="0px">
          <Container width="70px" height="60px">
            {isSuccess ? (
              <Icon icon={alertSuccessIcon} color={theme.colors.green} />
            ) : (
              <Icon icon={alertCautionIcon} color={theme.colors.orange} />
            )}
          </Container>
        </Col>
        <Col 
          size={50}
          padding="20px 0px 20px 0px"
          style={{
            maxHeight: '120px',
            overflowY: 'auto'
          }}
        >
          <Paragraph
            fontSize="16px"
            color={theme.colors.black55}
            textAlign="center"
            padding="0px"
          >
            {message}
          </Paragraph>
        </Col>
        <Col size={12} padding="0px">
          {children}
        </Col>
      </Row>
    </Modal>
  );
};

Alert.propTypes = {
  message        : PropTypes.string.isRequired,
  children       : PropTypes.node.isRequired,
  onCloseCallback: PropTypes.func,
  isSuccess      : PropTypes.bool.isRequired,
  isLoading      : PropTypes.bool.isRequired,
};

Alert.defaultProps = {
  onCloseCallback: () => null,
};

export default withTheme(Alert);
