/* eslint-disable no-unused-vars */
import Divider from 'components/Grid/Divider';
import Row from 'components/Grid/Row';
import React from 'react';

import ConciliationChart from '../ConciliationChart/index';



const ConciliationGraphic = () => {
  const [chartData, setChartData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

 
  return (
    <>
      <Row>
        <ConciliationChart
          chartBars={chartData}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
        />
      </Row> 
      <Divider />
    </>
  )
}

export default ConciliationGraphic
