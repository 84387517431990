/* eslint-disable no-unused-vars */

import Col from 'components/Grid/Col';
import Row from 'components/Grid/Row';
import styled from 'styled-components';

export const FinancialSummaryBoxDiv = styled.div`
  width: 100%;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  box-shadow: 0px 0px 5px RGBA(0, 0, 0, 0.11);
`;

export const ColDivider = styled.div`
  border-right: 1px solid rgba(242, 242, 242, 0.8);
  padding: 0;
  width: 220px;
  &:last-child {
    border-right: none;
  }
  &:first-child {
    width: 57px;
  }
  /* :nth-child(-n + 2) {
  } */
`;

export const FinancialSummaryBoxValues = styled.div`
  width: 100%;
  display: block;
  margin: 16px 40px;
  &:first-child {
    margin: 16px 20px;
  }

  span {
    padding-right: 20px;
  }
  img {
    margin-top: 5px;
    width: 17px;
  }
`;

export const BorderRow = styled.div`
  display: flex;
  flex-direction: row;
  & {
    position: relative;
  }

  &:after {
    background: linear-gradient(
      to right,
      #828282 57px,
      #e0e0e0 57px,
      #e0e0e0 100%
    );
    position: absolute;
    bottom: 0;
    height: 2px;
    content: '';
    width: 100%;
  }
`;

export const FinancialSummaryBalanceKey = styled.span`
  display: block;
  font-family: Open Sans;
  font-weight: 300;
  color: #828282;
  font-size: 13px;
  line-height: 10px;
`;

export const FinancialSummaryBalanceValue = styled.span`
  display: block;
  font-family: Open Sans;
  font-weight: 400;
  font-size: 13px;
  font-style: normal;
  line-height: 13px;
  letter-spacing: 0.1px;
  margin-top: 6px;
  color: ${props => props.color};
`;

export const HelpIcon = styled.div`
  color:#fff;
  background-color:#444444;
  padding: 2px 5px;
  display:inline-block;
  border-radius:100%;
  font-size:10px;
  text-align:center;
  text-decoration:none;
  -webkit-box-shadow: inset -1px -1px 1px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: inset -1px -1px 1px 0px rgba(0,0,0,0.25);
  box-shadow: inset -1px -1px 1px 0px rgba(0,0,0,0.25);
  margin-left: 5px;
  cursor:pointer;
`;