    export const Types = {
    GET_FILTERSBARS: "filtersBars/GET_FILTERSBARS",
    GET_FILTERSBARS_SUCCESS: "filtersBars/GET_FILTERSBARS_SUCCESS",
    GET_FILTERSBARS_ERROR: "filtersBars/GET_FILTERSBARS_ERROR",
    }

    const INITIAL_STATE = {
      cardAcquirers: [],
      cardReceive: [],
      loading: false,
      error: '',
    };

    export default function filtersBars(state = INITIAL_STATE, action){
      
      switch(action.type){
  
        case Types.GET_FILTERSBARS: {
          return {
            cardAcquirers: [],
            cardReceive: [],
            loading:true,
            error:''
        }}
        

        case Types.GET_FILTERSBARS_SUCCESS: {
          return {
            cardAcquirers: action.payload.cardAcquirer,
            cardReceive: action.payload.cardReceive,
            acquirersLength:action.payload.sizeCardAcquirer,
            receiveLength:action.payload.sizeCardReceive,
            acquirersSum:action.payload.sumAcquirer,
            receiveSum:action.payload.sumReceive,
            filtros:action.payload,
            loading:false, 
            error:''
          }
        }

        case Types.GET_FILTERSBARS_ERROR: {
          return {
            loading:false, 
            error:action.payload.error, 
            ...state
          }
        }
          
        default:
          return state;
        }
    }  