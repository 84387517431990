import Divider from 'components/Grid/Divider';
import React from 'react'


import FiltersBars from '../../Hooks/index'

const ConciliationBarFiltros = () => {
  
  return (
    <>
      <FiltersBars />
      <Divider />
    </>
  )
}

export default ConciliationBarFiltros;
