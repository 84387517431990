/* eslint-disable react/prop-types */

import Card from 'components/Grid/Card';
import Col from 'components/Grid/Col';
import Row from 'components/Grid/Row';
import Text from 'components/Typografy/Text';
import React from 'react';
import { withTheme } from 'styled-components';

import { StyledShortcut } from './styles';

const Shortcut = ({ theme, text, image, url }) => {
  const onClick = () => {
    const win = window.open(url, '_blank');
    win.focus();
  };

  return (
    <StyledShortcut>
      <Card width="100%" backgroundColor={theme.colors.white}>
        <Col size={3} padding="1px;" alignItems="flex-center">
          <img alt={text} width="100%" src={image} />
        </Col>
        <Col size={10}>
          <Row>
            <Text fontSize="15px" color={theme.colors.black}>
              {text}
            </Text>
          </Row>
          <Row>
            <Text
              fontSize="10px"
              color="#828282"
              fontWeight={600}
              cursor="pointer"
              onClick={() => onClick()}
            >
              VER MAIS
            </Text>
          </Row>
        </Col>
      </Card>
    </StyledShortcut>
  );
};

export default withTheme(Shortcut);
