/* eslint-disable func-names */
/* eslint-disable no-extend-native */

import * as UtilDate from 'utils/util-date';


Date.prototype.getMonthName = function () {
    return UtilDate.getMonthName(this);
};

Date.prototype.getShortMonthName = function () {
    return UtilDate.getShortMonthName(this);
};

Date.prototype.formatDate = function (showText = false) {
    return UtilDate.formatDate(this, showText);
};
