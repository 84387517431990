import Container from 'components/Grid/Container/index';
import React from 'react';
import Skeleton,{SkeletonTheme} from "react-loading-skeleton";

 const SkeletonAcquirerList = () => (
   <Container>  
     <SkeletonTheme>
       <section style={{display:"flex", justifyContent:'flex-start', alignItems:"center"}}>
         <Skeleton circle height={50} width={50} />

         <div style={{marginLeft:10}}>
           <Skeleton duration={3} height={8} width={50} />
           <div>
             <Skeleton duration={3} height={8} width={60} />
           </div>
         </div>

         <div style={{marginLeft:10}}>
           <Skeleton duration={3} height={8} width={50} />
           <div>
             <Skeleton duration={3} height={8} width={60} />
           </div>
         </div>

         <div style={{marginLeft:10}}>
           <Skeleton duration={3} height={8} width={50} />
           <div>
             <Skeleton duration={3} height={8} width={60} />
           </div>
         </div>

         <div style={{marginLeft:10}}>
           <Skeleton duration={3} height={8} width={50} />
           <div>
             <Skeleton duration={3} height={8} width={60} />
           </div>
         </div>

         <div style={{marginLeft:10}}>
           <Skeleton duration={3} height={8} width={50} />
           <div>
             <Skeleton duration={3} height={8} width={60} />
           </div>
         </div>
            
       </section>
     </SkeletonTheme>
   </Container>
  
);

export default SkeletonAcquirerList;