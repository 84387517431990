import Col from 'components/Grid/Col';
import Row from 'components/Grid/Row';
import React from 'react'

import AcquirerList from '../CardAcquirerList/index';
import CardReceiveList from '../CardReceiveList/index';

const Cards = () => {

  return (
    <>
      <Row justifyContent="space-between">
        <Col size={5.8} justifyContent="flex-start" alignItems="flex-start">
          <AcquirerList testId="test-acquirer-list" />
        </Col>
        <Col size={0.1} padding="none">
          <hr style={{
              display: 'flex',
              flexDirection: 'row',
              width: '1px',
              height: '400px',
              borderStyle: 'dashed',
              borderColor: '#CCCCCC',
            }}
          />        
        </Col>
        <Col size={5.8} justifyContent="flex-start" alignItems="flex-start">
          <CardReceiveList
            testId="test-receive-list"
          />
        </Col>
      </Row>
    </>
  )
}

export default Cards
