import { call, put } from 'redux-saga/effects';

import api from '../../../../../services/api';
import { Types as UpdateVisaoTypes } from '../../../../ducks/Dashboard/LucroLiquido/UpdateVisaoConta';

export function* setUpdateVisao(action) {
  try {
    const { visao, conta } = action.payload;
    const response = yield call(
      api.post,
      `Home/UpdateNetProfitParameters?visao=${visao}&conta=${conta}`,
    );

    if (response.status === 200) {
      yield put({
        type: UpdateVisaoTypes.SET_VISAO_SUCCESS,
        valid: true,
      });
    }
  } catch (error) {
    yield put({
      type: UpdateVisaoTypes.SET_VISAO_ERROR,
      valid: error,
      error,
    });
  }
}
