/* eslint-disable react/jsx-no-bind */
/* eslint-disable func-names */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import HomeImage from 'assets/icons/home.svg';
import Alert from 'components/Alert';
import { ButtonDropDown } from 'components/Forms/ButtonDropdown';
import { DropDownMenu } from 'components/Forms/ButtonDropdown/DropdownMenu/index';
import { MenuItem } from 'components/Forms/ButtonDropdown/DropdownMenu/menu-item';
import Col from 'components/Grid/Col';
import Row from 'components/Grid/Row';
import React, { useState, useContext, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import api from 'services/api';

import HomeDateContext from '../context';
import FinancialSummaryChart from '../FinancialSummaryChart/index';
import * as Styled from './styles';

export default function FinancialSummaryBox() {
  const [data, setData] = useState([]);
  const [summaryValuesStatus, setSummaryValuesStatus] = useState({
    summaryValuesLoadingStatus: 'idle',
    errorMessage: '',
  });
  const [showAlert, setShowAlert] = useState(false);

  const { startDate, endDate } = useContext(HomeDateContext);

  const handleSumarization = function (obj) {
    const { data, status, err } = obj;
    setData(data);
    setSummaryValuesStatus({
      ...setSummaryValuesStatus,
      summaryValuesLoadingStatus: status,
      errorMessage: err
    });

  };

  useEffect(() => {
    setSummaryValuesStatus({
      ...setSummaryValuesStatus,
      summaryValuesLoadingStatus: 'loading',
    });

    //   api
    //     .get(
    //       `Home/DadosResumoFinanceiro?inicio=${startDate.format(
    //         'Y-MM-DD',
    //       )}&fim=${endDate.format('Y-MM-DD')}`,
    //     )
    //     .then(response => {
    //       setData(response.data.Valores);
    //       // setShowAlert(response.data.Valores.balance < 0);
    //       setSummaryValuesStatus({
    //         ...setSummaryValuesStatus,
    //         summaryValuesLoadingStatus: 'loaded',
    //       });
    //     })
    //     .catch(err => {
    //       setSummaryValuesStatus({
    //         summaryValuesLoadingStatus: 'error',
    //         errorMessage: err.response,
    //       });
    //     });
  }, [startDate, endDate]);

  function renderValue(valueType) {
    switch (summaryValuesStatus.summaryValuesLoadingStatus) {
      case 'idle':
      case 'loading':
        return <span>Carregando...</span>;
      case 'error':
        return <span>Erro ao carregar os valores</span>;
      case 'loaded':
        return (
          <span>
            {parseFloat(data[valueType]).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
              style: 'currency',
              currency: 'BRL',
            })}
          </span>
        );
      default:
        return <h1>R$ 0,00</h1>;
    }
  }

  function handleOnClose() {
    setShowAlert(false);
  }

  return (
    <>
      <Styled.FinancialSummaryBoxDiv>
        <Styled.BorderRow>
          <Styled.ColDivider>
            <Styled.FinancialSummaryBoxValues>
              <img src={HomeImage} alt="Home" />
            </Styled.FinancialSummaryBoxValues>
          </Styled.ColDivider>
          <Styled.ColDivider>
            <Styled.FinancialSummaryBoxValues>
              <Styled.FinancialSummaryBalanceKey>
                Saldo
                <Styled.HelpIcon data-tip data-for='global' data-event='click focus'>?</Styled.HelpIcon>
                <ReactTooltip id='global' aria-haspopup='true' globalEventOff='click'>
                  <p>O Saldo é referente ao período selecionado, e demonstra o acumulado de entradas/saidas (recebimentos - pagamentos)</p>
                </ReactTooltip>
              </Styled.FinancialSummaryBalanceKey>
              <Styled.FinancialSummaryBalanceValue color="#3EA2FF">
                {renderValue('balance')}
              </Styled.FinancialSummaryBalanceValue>
            </Styled.FinancialSummaryBoxValues>
          </Styled.ColDivider>
          <Styled.ColDivider>
            <Styled.FinancialSummaryBoxValues>
              <Styled.FinancialSummaryBalanceKey>
                A Receber
              </Styled.FinancialSummaryBalanceKey>
              <Styled.FinancialSummaryBalanceValue color="#6FCF97">
                {renderValue('receipts')}
              </Styled.FinancialSummaryBalanceValue>
            </Styled.FinancialSummaryBoxValues>
          </Styled.ColDivider>
          <Styled.ColDivider>
            <Styled.FinancialSummaryBoxValues>
              <Styled.FinancialSummaryBalanceKey>
                A Pagar
              </Styled.FinancialSummaryBalanceKey>
              <Styled.FinancialSummaryBalanceValue color="#EB5757">
                {renderValue('payments')}
              </Styled.FinancialSummaryBalanceValue>
            </Styled.FinancialSummaryBoxValues>
          </Styled.ColDivider>
        </Styled.BorderRow>
        <Row>
          <Col size={12}>
            <FinancialSummaryChart handleSumarization={handleSumarization} />
          </Col>
        </Row>
        {/* <Row>
          <Col size="4">
            <ButtonDropDown>
              NOVA MOVIMENTAÇÃO
              <DropDownMenu>
                <MenuItem
                  handleClick={() => console.log('RECEBIMENTO')}
                  text="NOVO RECEBIMENTO"
                />
                <MenuItem
                  handleClick={() => console.log('PAGAMENTO')}
                  text="NOVO PAGAMENTO"
                />
              </DropDownMenu>
            </ButtonDropDown>
          </Col>
        </Row> */}
      </Styled.FinancialSummaryBoxDiv>
      <Row>
        <Col size={12} padding="0px">
          <Alert onClose={handleOnClose} visible={showAlert}>
            Identificamos pontos de atenção no seu fluxo de caixa. Clique para verificar
          </Alert>
        </Col>
      </Row>
    </>
  );
}
