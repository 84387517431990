/* eslint-disable no-bitwise */
export function colorIsDark(color) {
    // Variables for red, green, blue values
    let r;
    let g;
    let b;
    let hsp;
    
    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {
        // If HEX --> store the red, green, blue values in separate variables
        const hexColor = color.match(
            /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/,
        );
        
        [r, g, b] = hexColor;
    } else {
        // If RGB --> Convert it to HEX: http://gist.github.com/983661
        const rgbColor = +`0x${ color
            .slice(1)
            .replace(color.length < 5 && /./g, '$&$&') }`;
        
        r = rgbColor >> 16;
        g = (rgbColor >> 8) & 255;
        b = rgbColor & 255;
    }
    
    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    // eslint-disable-next-line prefer-const
    hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
    
    // Using the HSP value, determine whether the color is light or dark
    return hsp <= 127.5;
}
