import styled from 'styled-components';

export const StyledBackOfficeHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 15px 15px 15px 32px;
  margin-bottom: 26px;
  justify-content: space-between;
  border-bottom: #ccc 1px solid;
`;

export const StyledTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const StyledStore = styled.div`
  width: 500px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`;
