import PropTypes from 'prop-types';
import React from 'react';

import {StyledToggleButton, StyledToggleBox, StyledToggleBoxLabel} from './styles';

const ToggleButton = ({ activeText, disabledText, onClick }) => (
  <StyledToggleButton>
    <StyledToggleBox
      id="checkbox"
      type="checkbox"
      activeText={activeText}
      disabledText={disabledText}
      onClick={onClick}
    />
    <StyledToggleBoxLabel
      htmlFor="checkbox"
      activeText={activeText}
      disabledText={disabledText}
    />
  </StyledToggleButton>
);

ToggleButton.propTypes = {
  activeText: PropTypes.string,
  disabledText: PropTypes.string,
  onClick: PropTypes.func,
};

ToggleButton.defaultProps = {
  activeText: '',
  disabledText: '',
  onClick: () => {},
};

export default ToggleButton;
