import moment from 'moment';


export const monthNames = [
    'janeiro', 'fevereiro', 'março',
    'abril', 'maio', 'junho',
    'julho', 'agosto', 'setembro',
    'outubro', 'novembro', 'dezembro'
];

export function formatJsonDate(date) {
    if (date == null) return '-';
    
    return moment(new Date(date.match(/\d+/)[0] * 1))
        .format('DD/MM/YYYY')
        .toString();
}

export function formatDateRange(dateStart, dateFinish) {
    return `${ formatDate(dateStart) } - ${ formatDate(dateFinish) }`;
}

export function formatDate(date, showText = true) {
    if (date === undefined) {
        return showText === true ? 'sem data' : '';
    }
    
    if (!(date instanceof Date)) {
        date = new Date(date)
    }

    let day = date.getDate();
    let month = date.getMonth() + 1
    let year = date.getFullYear()
    
    return `${("0" + day).slice(-2)}/${("0" + month).slice(-2)}/${year}`;
}

export function FormatDateTimeDDMMYYYY(dateT) {
    const day   = dateT.date().toString();
    const month = (dateT.month() + 1).toString();
    const year  = dateT.year().toString();
    
    return `${ dateT.date() > 9 ? day : `0${ day }` }/${ dateT.month() + 1 > 9 ? month : `0${ month }` }/${ year }`;
}

export function getMonthName(date) {
    return monthNames[date.getMonth()];
}

export function getShortMonthName(date) {
    return monthNames[date.getMonth()].substr(0, 3);
}

export function parseJsonDate(jsonDateString) {
  
    return new Date(parseInt(jsonDateString.replace('/Date(', '')));
}
