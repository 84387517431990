import PropTypes from 'prop-types';
import React from 'react';

import TagFilterStyled from './styles';

const TagFilter = ({
  elementKey,
  element,
  tagKey,
  tagValue,
  onRemoveFilter,
}) => (
  <TagFilterStyled>
    <button
      type="button"
      title="Fechar"
      onClick={() => onRemoveFilter(elementKey, element)}
    >
      <span>×</span>
    </button>
    <p>
      {tagKey}
      :
      {' '}
      <strong>{tagValue}</strong>
    </p>
  </TagFilterStyled>
);

TagFilter.propTypes = {
  tagKey: PropTypes.string,
  tagValue: PropTypes.string,
  onRemoveFilter: PropTypes.func,
  elementKey: PropTypes.number,
  element: PropTypes.object,
};

TagFilter.defaultProps = {
  elementKey:0,
  element:{},
  tagKey: '',
  tagValue: '',
  onRemoveFilter: (elementKey, element) => elementKey + element
};

export default TagFilter;
