export const Types = {
  GET_CONTA_BANCARIA:
    '@Dashboard/Conferencia&Conciliacao/ContaBancaria/GET_CONTA_BANCARIA',
  GET_CONTA_BANCARIA_SUCCESS:
    '@Dashboard/Conferencia&Conciliacao/ContaBancaria/GET_CONTA_BANCARIA_SUCCESS',
  GET_CONTA_BANCARIA_ERROR:
    '@Dashboard/Conferencia&Conciliacao/ContaBancaria/GET_CONTA_BANCARIA_ERROR',
};

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: '',
};

export default function contaBancaria(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_CONTA_BANCARIA: {
      return { data: {}, loading: true, error: '' };
    }
    case Types.GET_CONTA_BANCARIA_SUCCESS: {
      return {
        data: action.data,
        loading: false,
        error: '',
      };
    }
    case Types.GET_CONTA_BANCARIA_ERROR: {
      return {
        data: {},
        loading: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
}
