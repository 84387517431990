import { call, put } from 'redux-saga/effects';

import api from '../../../../../services/api';
import { Types as ObterContaTypes } from '../../../../ducks/Dashboard/LucroLiquido/ObterConta';

export function* getObterConta(action) {
  try {
    const { idFinVisao } = action.payload;
    const response = yield call(
      api.post,
      `filtros/contas?idFinVisao=${Number(idFinVisao)}`,
    );

    if (response.status === 200) {
      yield put({
        type: ObterContaTypes.GET_OBTER_CONTA_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: ObterContaTypes.GET_OBTER_CONTA_ERROR,
      error,
    });
  }
}
