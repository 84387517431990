/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */

import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { StyledPagination, StyledListItem } from './styles';

export default function Pagination({ count, defaultPage, onChange }) {
  const [active, setActive] = useState(defaultPage);

  useEffect(() => {
    onChange(active);
  }, [active]);

  const ItemList = () => {
    const items = [];

    for (let currentIndex = 0; currentIndex < 7; currentIndex += 1) {
      const number = active + currentIndex;

      items.push(
        <li>
          <StyledListItem
            onClick={() => setActive(number)}
            active={active === number}
          >
            {number}
          </StyledListItem>
        </li>,
      );

      if (number === count) {
        break;
      }
    }

    return items;
  };

  return (
    <StyledPagination>
      <li>
        <StyledListItem
          onClick={() => {
            if (active > 1) {
              setActive(active - 1);
            }
          }}
          disable={active === 1}
        >
          Anterior
        </StyledListItem>
      </li>
      {ItemList()}
      <li>
        <StyledListItem
          onClick={() => {
            if (active !== count) {
              setActive(active + 1);
            }
          }}
          disable={active === count}
        >
          Próximo
        </StyledListItem>
      </li>
    </StyledPagination>
  );
}

Pagination.propTypes = {
  count: PropTypes.number.isRequired,
  defaultPage: PropTypes.number,
};

Pagination.defaultProps = {
  defaultPage: 1,
};
