import {call, put} from 'redux-saga/effects';
import * as ConciliationServices from 'services/conciliationServices';

import {Types as CardBrands} from '../../../ducks/Conciliation/CardBrands'; 

export function* getCardBrands(){
  try {
    
    const response = yield call(ConciliationServices.getCardBrands);

    yield put({
      type: CardBrands.GET_CARDBRANDS_SUCCESS,
      data:response
    })
    
  } catch (error) {

    yield put({
      type: CardBrands.GET_CARDBRANDS_ERROR, error
    })
    
  }
}
