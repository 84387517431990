import { call, put } from 'redux-saga/effects';

import api from '../../../../../services/api';
import { Types as ContaBancariaTypes } from '../../../../ducks/Dashboard/Conferencia&Conciliacao/ContaBancaria';

export function* getContaBancaria(action) {
  try {
    const { startDate, endDate } = action.payload.date;

    const response = yield call(
      api.get,
      `/api/v1/bank-conciliation/status?InitialDate=${startDate.format(
        'Y-MM-DD',
      )}&FinalDate=${endDate.format('Y-MM-DD')}`,
    );

    if (response.status === 200) {
      yield put({
        type: ContaBancariaTypes.GET_CONTA_BANCARIA_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: ContaBancariaTypes.GET_CONTA_BANCARIA_ERROR,
      error,
    });
  }
}
