import PropTypes from 'prop-types';
import React from 'react';
import {withTheme} from 'styled-components';
import theme from 'styles/theme';

import {IconStyledComponent} from './style';

const Icon = ({
  color,
  icon,
  hoverColor,
  width,
  height
}) => {
    return (
      
      <IconStyledComponent
        icon={icon}
        color={color}
        hoverColor={hoverColor}
        width={width}
        height={height}
      />
    );
};

Icon.propTypes = {
    icon      : PropTypes.string.isRequired,
    color     : PropTypes.string,
    hoverColor: PropTypes.string,
    width     : PropTypes.string,
    height    : PropTypes.string
};

Icon.defaultProps = {
    color     : theme.colors.gray,
    hoverColor: theme.colors.gray,
    width     : "100%",
    height    : "100%"
};

export default withTheme(Icon);