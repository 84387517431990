import styled from 'styled-components';

export const StyledDropdownItem = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 30px;
  background: white;
  font-weight: normal;
  font-size: 13px;
  padding-left: 10px;
  color: #828282;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #eaf5fb;
  }
`;

export const StyledDropdownCheckbox = styled.div`
  & {
    text-align: center;
    display: block;
    position: absolute;
    padding-left: 20px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    right: 0;
    margin-top: -8px;

    & input[type='checkbox'] {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    & .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 14px;
      width: 14px;
      background: #ffffff;
      border: 1px solid #cccccc;
      box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.1);
      border-radius: 2px;

      &:after {
        content: '';
        position: absolute;
        display: none;
        left: 4px;
        top: 0;
        width: 3px;
        height: 8px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }

  & :hover {
    & input[type='checkbox'] ~ .checkmark {
      background-color: #ccc;
    }
  }

  & {
    & input[type='checkbox']:checked ~ .checkmark {
      background-color: #5ab0ff;
      border: none;

      &:after {
        display: block;
      }
    }
  }
`;
