/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import Text from 'components/Typografy/Text/index';
import ChartFilters from 'pages/Conciliation/components/ConciliationChart/ChartFilters/index';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as Recharts from 'recharts';
import {Types as FilterBars} from 'store/ducks/Conciliation/FiltersBars';
import {Types as FiltersChart} from 'store/ducks/Conciliation/FiltersChart';
import {withTheme} from 'styled-components';
import theme from 'styles/theme';
import * as UtilDate from 'utils/util-date'


import ConciliationChartLoader from './loader';
import ConciliationChartStyled from './styles';
import {ConciliationChartTooltip} from './tooltip';

const ConciliationChart = () => {
    
    const [chartListFilters, setChartListFilters] = useState('');
    const [activeBarIndex, setActiveBarIndex]     = useState(undefined);
    
    const dispatch  = useDispatch();
    const chartData = useSelector(state => state.FiltersChartReducer);
    
    const chartBars    = chartData.records ?? [];
    
    const initialDay   = new Date().getDate();
    const initialMonth = new Date().getMonth();
    const initialYear  = new Date().getFullYear();
    
    const finalDay  = new Date().getDate();
    const finalMonth  = new Date().getMonth();
    const finalYear = new Date().getFullYear();
    
    const initialDate = `${initialYear}-${initialMonth }-${initialDay}`;
    const finalDate   = `${finalYear}-${finalMonth + 1}-${finalDay}`;
    
    const data = {
        initialDate,
        finalDate,
        cardBrands  : [],
        acquirers   : [],
        subsidiaries: []
    };
    
    useEffect(() => {
        dispatch({ type: FilterBars.GET_FILTERSBARS, payload: data });
        dispatch({ type: FiltersChart.GET_FILTERSBARS_CHART, payload: data });
    }, []);
    
    
    const onBarClick = (e, i) => {
        setChartListFilters(e.payload);
        setActiveBarIndex(i);
    };
    
    const onRemoveChartFilter = i => {
        delete chartListFilters[i];
        
        setChartListFilters('');
        setActiveBarIndex(undefined);
    };
    
    const colorRulesToConciliate = (entry) => {
        if (entry.conciliated == entry.value){
            return theme.colors.lightBlue;
        }
        
        if (entry.netValueSystem > entry.netValueArchive)
            return theme.colors.red;
        
        if (entry.netValueSystem <= entry.netValueArchive)
            return "#F8E1D1";
        
        return theme.colors.black20;
    };
    
    const colorRulesConciliated = (entry) => {
        if (entry.conciliated == entry.value)
            return theme.colors.lightBlue;
        
        if (entry.netValueSystem > entry.netValueArchive)
            return theme.colors.red;
        
        if (entry.netValueSystem <= entry.netValueArchive)
            return theme.colors.orange;
        
        return theme.colors.black20;
    };
    
    if (chartBars === null || chartBars.length <= 0) {
        return (
          <>
            { chartData.loading ? (<ConciliationChartLoader />) : (
              <>
                <div style={{ margin: '1rem' }}>
                  Resumo
                </div>
              </>
            )}
          </>
        );
    }
    
    return (
      <>
        <ConciliationChartStyled>
          <div className="chartFiltersBox">
            <ChartFilters
              listFilters={chartListFilters}
              onRemoveFilter={onRemoveChartFilter}
            />
          </div>
          {chartData.isLoading ? (<ConciliationChartLoader />) : (
            <Recharts.ResponsiveContainer width="100%" height={250}>
              <Recharts.BarChart
                data={chartBars}
                margin={{ top: 0, right: 10, left: 10, bottom: 5 }}
              >
        
                <Recharts.Tooltip
                  isAnimationActive={false}
                  content={<ConciliationChartTooltip />}
                />
                  
                <Recharts.XAxis
                  dataKey="date"
                  tickLine={false}
                  dy={10}
                  tick={<XAxisLabel />}
                  interval={0}
                />
                <Recharts.Bar
                  dataKey="conciliated"
                  stackId="a"
                  barSize={7}
                  maxBarSize={7}
                >
                  {chartBars && chartBars.map((entry, index) => (
                    <Recharts.Cell
                      key={`cell-${index}`}
                      cursor="pointer"
                      fill={colorRulesConciliated(entry)}
                    />
                    ))}
                </Recharts.Bar>
                  
                <Recharts.Bar
                  dataKey="toConciliate"
                  stackId="a"
                  barSize={7}
                  maxBarSize={7}
                >
                  {chartBars && chartBars.map((entry, index) => (
                    <Recharts.Cell
                      key={`cell-${index}`}
                      cursor="pointer"
                      fill={colorRulesToConciliate(entry)}
                    />
                    ))}
                </Recharts.Bar>
              </Recharts.BarChart>
            </Recharts.ResponsiveContainer>
            )}
          <div>
            <Text>Legenda:</Text>
            <Text fontSize='8px'>
              <span style={{
                        height: '8px',
                        width: '8px',
                        backgroundColor: theme.colors.lightBlue,
                        borderRadius: '50%',
                        display: 'inline-block',
                        margin: '0 5px 0px 5px'
                    }}
              />
              Todas as conciliações foram lançadas nesse dia
            </Text>
            <Text fontSize='8px'>
              <span style={{
                        height: '8px',
                        width: '8px',
                        backgroundColor: theme.colors.orange,
                        borderRadius: '50%',
                        display: 'inline-block',
                        margin: '0 5px 0px 5px'
                    }}
              />
              Existem conciliações pendentes
            </Text>
            <Text fontSize='8px'>
              <span style={{
                        height: '8px',
                        width: '8px',
                        backgroundColor: theme.colors.red,
                        borderRadius: '50%',
                        display: 'inline-block',
                        margin: '0 5px 0px 5px'
                    }}
              />
              Existem conciliações pendentes, porém o valor total no arquivo está menor do que o valor total do sistema

            </Text>
          </div>
        </ConciliationChartStyled>
      </>
    );
};

const XAxisLabel = values => {
    const { x, y, payload } = values;
    
    const data = payload.value;
    
    const day  = new Date(data).getDate();
    const month  = UtilDate.getShortMonthName(new Date(data));
    
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          fontSize={8}
          fontWeight="bolder"
          fontFamily="Open Sans"
          x={5}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
        >
          <tspan y={0}>{day}</tspan>
          <tspan y={25} x={7}>{month}</tspan>
        </text>
      </g>
    );
};

export default withTheme(ConciliationChart) ;
