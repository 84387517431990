/* eslint-disable react-hooks/exhaustive-deps */

import DropdownItem from 'components/Forms/Dropdown/DropdownItem/index';
import Dropdown from 'components/Forms/Dropdown/index';
import Text from 'components/Typografy/Text/index';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";

import {Types as Acquirers} from '../../../../../store/ducks/Conciliation/Acquirers';


function AcquirersDropDown({
  register
}) {

  const dispatch = useDispatch();

  useEffect(() => {

    dispatch({type:Acquirers.GET_ACQUIRERS});

  },[])

    const dataAcquirers = useSelector(state => state.AcquirersReducer)

    return(
      <>
        <Text fontWeight={600}>Adquirente</Text>
        
        <Dropdown
          name="acquirers"
          register={register}
          testId="dropdown-acquirers-filter"
          multiple
        
        >
          
          {dataAcquirers.loading === true ? <p>Carregando...</p> :
          dataAcquirers.data.map(item => (
            <DropdownItem
              key={item.codTef} 
              label={item.descriptionTef} 
              value={item.codTef}
            />
         ))}
        </Dropdown>
      </>
    );
}

AcquirersDropDown.propTypes = {
    register: PropTypes.func.isRequired,
};

export default AcquirersDropDown;
