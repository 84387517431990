/* eslint-disable no-unused-vars */
import Col from 'components/Grid/Col';
import Row from 'components/Grid/Row';
import PropTypes from 'prop-types';
import React from 'react';


import { BackgroundDiv, TooltipTitle, TooltipItemKey, TooltipItemValue} from './styles';

export default function FinancialSummaryChartTooltip({
  active,
  payload,
  label,
}) {
  return (
    <BackgroundDiv>
      <Row>
        <Col size={6}>
          <TooltipTitle>{label}</TooltipTitle>
        </Col>
      </Row>
      <Row>
        <Col size={6} justifyContent="left">
          <TooltipItemKey>Saldo do dia</TooltipItemKey>
        </Col>
        <Col size={6} justifyContent="left">
          <TooltipItemValue color="#3EA2FF">
            {payload.length > 0
              ? parseFloat(payload[0].value).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                  style: 'currency',
                  currency: 'BRL',
                })
              : 'R$ 0,00'}
          </TooltipItemValue>
        </Col>
      </Row>
      <Row>
        <Col size={6} justifyContent="left">
          <TooltipItemKey>Recebimentos</TooltipItemKey>
        </Col>
        <Col size={6} justifyContent="left">
          <TooltipItemValue color="#6FCF97">
            {payload.length > 1
              ? parseFloat(payload[1].value).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                  style: 'currency',
                  currency: 'BRL',
                })
              : 'R$ 0,00'}
          </TooltipItemValue>
        </Col>
      </Row>
      <Row>
        <Col size={6} justifyContent="left">
          <TooltipItemKey>Pagamentos</TooltipItemKey>
        </Col>
        <Col size={6} justifyContent="left">
          <TooltipItemValue color="#EB5757">
            {payload.length > 2
              ? parseFloat(payload[2].value).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                  style: 'currency',
                  currency: 'BRL',
                })
              : 'R$ 0,00'}
          </TooltipItemValue>
        </Col>
      </Row>
    </BackgroundDiv>
  );
}

FinancialSummaryChartTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(PropTypes.any),
  label: PropTypes.string,
};

FinancialSummaryChartTooltip.defaultProps = {
  active: false,
  payload: [],
  label: '',
};
