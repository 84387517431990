import styled, { css } from 'styled-components';

export const StyledPagination = styled.ul`
  display: flex;
  list-style: none;
`;

export const StyledListItem = styled.a`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 4px;
  font-size: 10px;
  height: 35px;
  width: 35px;

  ${props =>
    props.disable &&
    css`
      color: #cccccc;
    `}

  ${props =>
    props.active &&
    css`
      border-radius: 4px;
      border: 1px solid #3ea2ff;
      color: #3ea2ff;
    `}
`;
