import styled from 'styled-components';

export const StyledContainer = styled.div`
  max-width: 100%;
  height: 100%;
  overflow: auto;
  margin-top: 10%;
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledDivButton = styled.div`
  width: 90%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 25px;
`;

export const StyledButtonA = styled.div`
  width: 110px;
  height: 35px;
  background-color: #3ea2ff;
  border-radius: 4px;
  justify-items: center;
  padding: 5px 10px;
  cursor: pointer;
`;
