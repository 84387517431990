import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import DropdownContext from '../DropdownContext';
import {StyledDropdownItem, StyledDropdownCheckbox} from './styles';

function DropdownItem({ label, value, testId }) {
  const {onClick, multiple, selectedItems} = useContext(DropdownContext);

  return (
    <StyledDropdownItem
      onClick={e => onClick(e, { label, value })}
      data-testid={testId}
    >
      {label} 
      
      {multiple && (
        <StyledDropdownCheckbox className="checkbox-container">
          <input  
            type="checkbox" 
            readOnly 
            checked={selectedItems.some(x => x.value === value) ? 'checked' : ''}
          />
          <span className="checkmark" />
        </StyledDropdownCheckbox>
      )}
      
    </StyledDropdownItem>
  );
}

DropdownItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  testId: PropTypes.string,
};

DropdownItem.defaultProps = {
  testId: 'test-dropdown-item',
};

export default DropdownItem;
