/* eslint-disable no-console */

import React from 'react';
import {Provider} from 'react-redux';
import {ThemeProvider} from 'styled-components';
import './config/configReactotron';
import * as appInfo from 'utils/app-info';

import Routes from './routes/index';
import store from './store/store';
import GlobalStyle from './styles/global';
import theme from './styles/theme';


console.log(appInfo.getAppVersion());
console.log(appInfo.getAppName());

function App() {
    return (
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <Routes />
          <GlobalStyle />
        </Provider>
      </ThemeProvider>
    );
}

export default App;