import styled, { css } from 'styled-components';

export const StyledDivider = styled.hr`
  margin: 5px 0;
  background-color: unset;
  border-width: ${props => props.size};
  border-style: ${props => props.lineStyle};
  border-color: ${props => props.color};
  width: ${props => props.width};

  ${props =>
    props.orientation === 'vertical' &&
    css`
      align-self: stretch;
    `}
`;
