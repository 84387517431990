/* eslint-disable react-hooks/exhaustive-deps */

import DropdownItem from 'components/Forms/Dropdown/DropdownItem/index';
import Dropdown from 'components/Forms/Dropdown/index';
import Text from 'components/Typografy/Text/index';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";

import {Types as Subsidiaries} from '../../../../../store/ducks/Conciliation/Subsidiaries';

function SubsidiariesDropDown({
    register
}) {


  const dispatch = useDispatch();

  useEffect(() => {

    dispatch({type:Subsidiaries.GET_SUBSIDIARIES});

  },[])

    const dataSubsidiaries = useSelector(
      subsidiaries => subsidiaries.SubsidiariesReducer
    )

    return(
      <>
        <Text fontWeight={600}>Filial</Text>
        <Dropdown
          name="subsidiaries"
          register={register}
          testId="dropdown-subsidiary-filter"
          multiple
        >
          {dataSubsidiaries.loading === true ? <p>Carregando...</p> : 
           dataSubsidiaries.data.map(item => (
             <DropdownItem 
               key={item.idFilialPjf} 
               label={item.nomeFilial} 
               value={item.idFilialPjf}
             />
))}
        </Dropdown>
      </>
    );
}

SubsidiariesDropDown.propTypes = {
    register: PropTypes.func.isRequired,
};

export default SubsidiariesDropDown;
