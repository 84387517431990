import styled, { css } from 'styled-components';

export const StyledProgressBar = styled.div`
  width: 0;
  overflow: hidden;
  background-color: ${props => props.color};
  height: 6px;
  border-radius: 10px;
  transition: all 1s ease-in-out;

  ${props =>
    props.reverse &&
    css`
      float: right;
    `}

  ${props =>
    props.percent &&
    css`
      width: ${props.percent}%;
    `}
`;
