import PropTypes from 'prop-types';
import React from 'react';
import theme from 'styles/theme';

import {StyledText} from './styles';

const Text = ({
  children,
  fontWeight,
  fontSize,
  color,
  cursor,
  onClick,
  textAlign
}) => (
  <StyledText
    fontWeight={fontWeight}
    fontSize={fontSize}
    color={color}
    cursor={cursor}
    onClick={onClick}
    textAlign={textAlign}
  >
    {children}
  </StyledText>
);

Text.propTypes = {
  children: PropTypes.string,
  fontWeight: PropTypes.number,
  fontSize: PropTypes.string,
  color: PropTypes.string,
  cursor: PropTypes.string,
  onClick: PropTypes.func,
  textAlign: PropTypes.string
};

Text.defaultProps = {
  fontWeight: 300,
  fontSize: '12px',
  cursor: 'initial',
  color: theme.colors.black85,
  textAlign: 'right',
  children: '',
  onClick: () => {}
};

export default Text;
