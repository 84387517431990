/* eslint-disable jsx-a11y/label-has-associated-control */
import PropTypes from 'prop-types';
import React from 'react';

import { Container } from './styles';

const Label = ({ children, fontSize, color, bgColor, width }) => (
  <Container fontSize={fontSize} color={color} bgColor={bgColor} width={width}>
    <label>{children}</label>
  </Container>
);

Label.propTypes = {
  children: PropTypes.any,
  fontSize: PropTypes.string,
  color: PropTypes.string,
  bgColor: PropTypes.string,
  width: PropTypes.string,
};

Label.defaultProps = {
  fontSize: '13px',
  color: '#4F4F4F',
  bgColor: '',
  width: '100%',
  children: undefined
};

export default Label;
