/* eslint-disable no-underscore-dangle */
import styled from 'styled-components';

export const StyledTableContainer = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};

  overflow-y: auto;
`;

export const StyledTable = styled.table`
  width: 100%;

  align-items: center;

  border-spacing: 0;
  border-collapse: collapse;
  table-layout: fixed;
  position: relative;

  color: ${props => props.colors};

  & tr {
    vertical-align: middle;
    background: #fff;
  }

  & th,
  td {
    padding: 5px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    font-style: normal;
    font-weight: normal;
    font-size: ${props => props.theme.fontSize._12px}rem;
    color: ${props => props.theme.colors.black20};
  }

  & tbody tr {
    border-bottom: 1px solid #f6f6f6;
    :last-child {
      border-bottom: none;
    }
  }

  & tbody td {
    font-size: ${props => props.theme.fontSize._12px}rem;
    color: ${props => props.theme.colors.black55};
  }

  & thead > th {
    text-align: center;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }
`;
