

const checkIsNullOrUndefined = function isNullOrUndefined (value) {
    switch (value){
        case 'null':
        case 'Null':
        {
            return null;
        }
        case 'undefined':
        case 'Undefined':
        {
            return undefined;
        }
        default: {
            return value;
        }
    }
}

export default checkIsNullOrUndefined;