import Row from 'components/Grid/Row';
import styled from 'styled-components';

import Button from '../../components/Forms/Button';

export const BoldSpan = styled.span`
  font-weight: 700;
  font-size: 20px;
`;

export const TitleSpan = styled.span`
  margin-left: 30px;
  margin-top: 10px;
`;

export const HorizontalDivider = styled(Row)`
  border-bottom: 1px solid #ebebeb;
  padding: 10px;
`;

export const MoreInfoLink = styled.a`
  text-decoration: none;
  color: #828282;
  font-weight: bold;
  font-size: 13px;
  font-family: Open Sans;
  cursor: pointer;
  margin-top: 20px;
`;
export const TitleSpanDRE = styled.span`
  font-size: 18px;
  color: #828282;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
export const ImageDRE = styled.img`
  width: 18px;
  height: 17px;
  margin-right: 5px;
`;
export const SectionDRE = styled.section`
  padding: 15px 30px 10px 30px;
  width: 100%;
  minHeight: 328px;
`;
export const ListDL = styled.ul`
  margin-top: 15px;
  margin-bottom: 10px;
  border-bottom: ${props => props.borderBottom || 'none'};
  /* &:last-child {
    border-bottom: none;
  } */
`;
export const ItemLista = styled.li`
  font-size: ${props => props.fontSize};
  line-height: 15px;
  color: ${props => props.color || '#828282'};
  margin-right: ${props => props.marginRight};
  margin-top: ${props => props.marginTop};
  margin-bottom: 3px;
  list-style: none;
  /* border-bottom: 1px solid #828282;
  &:last-child{
    border-bottom: none;
  } */
`;

export const ContentDRE = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const SubContentDRE = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-left: 20px;
  border-bottom: ${props => props.borderBottom};
`;

export const TextLink = styled.a`
  font-size: 13px;
  font-weight: bold;
  color: ${props => props.color};
  cursor: pointer;
  text-decoration: none;
`;

export const ContainerCard = styled.div`
  justify-content: ${props => props.justifyContente || 'space-between'};
  display: flex;
  padding: ${props => props.padding};
  align-items: ${props => props.alingItems};
  justify-content: space-between;
  display: flex;
  padding: 24px 10px 30px 10px;
  width: 100%;

  > div {
    width: 365px;
  }

  @media (max-width: 1100px) {
    > div {
      width: 300px;
      margin-bottom: 10px;
    }
  }

  @media (max-width: 850px) {
    width: 100%;
    flex-direction: column;
    > div {
      width: 100%;
      margin-bottom: 10px;
    }
  }
`;

export const StyledModal = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  justify-content: center;
  align-items: center;
  margin: 0.5px;
  width: 99.8%;
  height: 99.6%;
  border-radius: 3px;
  display: flex;

  > div {
    background-color: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    width: 300px;
    height: 270px;
    margin: 0 auto;
    padding: 28px 30px;
    display: flex;
    flex-direction: column;

    > span {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      color: #828282;
    }

    label {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 10px;
      color: #4f4f4f;
      margin-top: 18px;
    }

    select {
      background: #ffffff;
      border: 1px solid #cccccc;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 11px;
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 13px;
      margin-top: 2px;
      color: #828282;
    }
  }
`;

export const StyledModalDre = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  justify-content: center;
  align-items: center;
  margin: 0.5px;
  width: 48.8%;
  height: 96.6%;
  border-radius: 3px;
  display: flex;

  > div {
    background-color: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    width: 300px;
    height: 190px;
    margin: 0 auto;
    padding: 28px 30px;
    display: flex;
    flex-direction: column;

    > span {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      color: #828282;
    }

    label {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 10px;
      color: #4f4f4f;
      margin-top: 18px;
    }

    select {
      background: #ffffff;
      border: 1px solid #cccccc;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 11px;
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 13px;
      margin-top: 2px;
      color: #828282;
    }
  }
`;

export const StyledButton = styled(Button)`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 700;
  line-height: 10px;
  letter-spacing: 0.3px;
`;
export const StyledLabelVisao = styled.label`
  color: #5b2e90;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 21px;
`;

export const StatusTitle = styled.span`
  color: #ccccd6;
  font-size: 14px;
  font-family: Open Sans;
  width: 100%;
  display: block;
  text-align: left;
`;