/* eslint-disable no-unused-vars */
import {put} from 'redux-saga/effects';
import * as ConciliationServices from 'services/conciliationServices';

import {Types as Conciliation} from '../../../ducks/Conciliation/ConciliationAcquirerList'; 
import {Types as ConciliationReceive} from '../../../ducks/Conciliation/ConciliationCardReceiveList'; 

export function* getConciliation(action){
  try {
    
    const [{id}] = action.payload;
    const [{idReceberCartao}] = action.payload;


    // const conciliate = yield ConciliationServices.conciliate({dados});

    yield put({
      type:Conciliation.GET_CONCILIATION_ACQUIRER_SUCCESS,
      payload:{
        id
      }
    })
  
    yield put({
      type:ConciliationReceive.GET_CONCILIATION_RECEIVE_SUCCESS,
      payload:{
        idReceberCartao
      }
    })
    
  } catch (error) {

    yield put({
      // type: Acquirers.GET_ACQUIRERS_ERROR, error
    })
    
  }
}
