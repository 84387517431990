import Col from 'components/Grid/Col';
import Row from 'components/Grid/Row';
import ConciliationContext from 'pages/Conciliation/ConciliationContext';
import React from 'react';
import {useSelector} from 'react-redux';

import ConciliationBar from '../ConciliationBar/index';
import ConciliateButton from '../ConciliationButton/index';

const ConciliationBarsCards = () => {

  const { acquirerSelectedValue, systemSelectedValue} = React.useContext(ConciliationContext);

  const fileSumValue = useSelector(state => state.FiltersBarsCardsReducer);
  const systemSumValue = useSelector(state => state.FiltersBarsCardsReducer);


  return (
    <>
      <Row>
        <Col size={5.25}>
          <ConciliationBar
            filledValue={acquirerSelectedValue}
            totalValue={fileSumValue.acquirersSum || 0}
            name="Arquivo"
          />
        </Col>
        <Col size={1.5}>
          <ConciliateButton />
        </Col>
        <Col size={5.25}>
          <ConciliationBar
            reverse
            filledValue={systemSelectedValue}
            totalValue={systemSumValue.receiveSum || 0}
            name="Sistema"
          />
        </Col>
      </Row>
  
    </>
  )
}

export default ConciliationBarsCards
