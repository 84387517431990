/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
/* eslint-disable import/named */
/* eslint-disable no-unused-vars */
import Label from 'components/Forms/Label/index';
import Modal from 'components/Modals/Modal/index';
import Text from 'components/Typografy/Text/index';
import { loja } from 'pages/Home/mock';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import * as CustomersServices from 'services/customers-services';
import smartSalesSessionStorage, {
  storageKeys,
} from 'storage/everest-session-storage';
import theme from 'styles/theme';

import {
  StyledContainer,
  StyledButtonA,
  StyledDivButton,
} from "./styles";

const SelectStoreModal = ({ onCloseCallback, teste }) => {
  const [storeIndex, setStoreIndex] = useState(0);
  const [stores, setStores] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getStores = async () => {
    const response = await CustomersServices.getStore();
    setStores(response.data.payload);
    setIsLoading(false);
  };

  // useEffect(() => {
  //   getStores();
  //   handleSelectStore(storeIndex);
  // }, []);

  const handleSelectStore = currentIndex => {
    setStoreIndex(currentIndex);
  };

  const handleClick = () => {
    // smartSalesSessionStorage.setItem(storageKeys.STORE, stores[storeIndex]);
    onCloseCallback();
    // window.location.reload(false);
  };

  return (
    <Modal
      showCloseButton
      width="400px"
      height="280px"
      padding={0}
      isLoading={isLoading}
      onCloseCallback={onCloseCallback}
      isVisible
    >
      <StyledContainer>
        <Text fontSize="18px">Selecione a Loja</Text>
        <br />
        <Label style={{ paddingTop: '25px' }}>Loja</Label>
        <select
          style={{
            width: '100%',
            height: '40px',
            color: '#828282',
            border: '1px solid #CECECE',
            borderRadius: '4px',
          }}
          value="teste"
          // value={storeIndex}
          onChange={e => handleSelectStore(e.target.value)}
        >
          {/* {stores &&
            stores.map((item, index) => (
              <option key={item.portalUrl} value={index}>
                {item.name}
              </option>
            ))} */}
          <option value="teste">Loja teste</option>
        </select>
        <br />
        <StyledDivButton>
          <StyledButtonA onClick={handleClick}>
            <Text fontSize="10px" cursor="pointer" color="#fff">
              CONTINUAR
            </Text>
          </StyledButtonA>
        </StyledDivButton>
      </StyledContainer>
    </Modal>
  );
};

SelectStoreModal.propTypes = {
  onCloseCallback: PropTypes.func,
};

SelectStoreModal.defaultProps = {
  onCloseCallback: () => null,
};

export default SelectStoreModal;
