export const cardBrandsEnum = {
    default            : '00000',
    visa               : '00001',
    mastercard         : '00002',
    diners             : '00003',
    americanExpress    : '00004',
    solo               : '00005',
    sidecard           : '00006',
    privateLabel       : '00007',
    redeshop           : '00008',
    fininvest          : '00010',
    jcb                : '00011',
    hipercard          : '00012',
    aura               : '00013',
    losango            : '00014',
    sorocred           : '00015',
    visaVale           : '00016',
    sodexo             : '00017',
    cabal              : '00018',
    ticket             : '00019',
    elo                : '00020',
    credSystem         : '00021',
    vr                 : '00022',
    hiper              : '00023',
    boticario          : '00064',
    eloDebito          : '01251',
    eloCredito         : '01252',
    visaConciliacao    : '01253',
    masterConciliacao  : '01254',
    bandeiraAlternativa: '1255',
    gera               : '9090',
};