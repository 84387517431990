import styled from 'styled-components';

export const StyledToggleButton = styled.div`
  position: relative;
`;

export const StyledToggleBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 240px;
  height: 30px;
  border-radius: 15px;
  border: 1px solid rgba(255, 255, 255, 0.7);
  cursor: pointer;
  padding-left: 15px;
  padding-right: 15px;
  color: #FFFFFF;
  &::before {
    font-size: 10px;
    padding-bottom: 3px;
    content: '${props => props.disabledText}';
    padding: 10px;
  }
  &::after {
    font-size: 10px;
    text-align: center;
    content: '${props => props.activeText}';
    display: block;
    position: relative;
    border-radius: 15px;
    width: 130px;
    height: 25px;
    margin-left: 95px;
    background: #6544ac;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.4s;
    top: -25px;
    padding-top:10px;
  }
`;

export const StyledToggleBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  &:checked + ${StyledToggleBoxLabel} {
    text-align: right;
    &::before {
      font-size: 10px;
      content: '${props => props.activeText}';
    }
    &::after {
      text-align: center;
      font-size: 10px;
      content: '${props => props.disabledText}';
      background: #6544ac;
      display: block;
      border-radius: 15px;
      width: 130px;
      height: 25px;
      margin-left: -17px;
      transition: 0.4s;
      padding-top:10px;
    }
  }
`;
