import styled from 'styled-components';

export const StyledDropdown = styled.div`
  position: relative;
  display: flex;
  height: 35px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #cccccc;
  cursor: pointer;
  background: white;
  justify-content: space-between;
`;

export const StyledDropdownContainer = styled.div`
  position: absolute;
  top: ${props => props.coordinates.y}px;
  left: ${props => props.coordinates.x}px;
  width: 100%;
  max-height: 105px;
  overflow-y: scroll;
  overflow-x: hidden;
  background: white;
  border-radius: 4px;
  border: 1px solid #cccccc;
  transition: all 0.2s ease-out;
  z-index: 1;
 
  /* firefox works */
  scrollbar-color: #E0E0E0 #F9F9F9;
  scrollbar-width: thin;
  
  &::-webkit-scrollbar {
    width: 3.85px;
  }
  &::-webkit-scrollbar-track {
    background: #f9f9f9;
  }
  &::-webkit-scrollbar-thumb {
    background: #e0e0e0;
    border-radius: 4px;
  }
`;


export const StyledDropdownIcon = styled.div`
  display: flex;
  height: 100%;
  padding-right: 10px;
`;

export const StyledSelectedItem = styled.div`
  height: 100%;
  width: 90%;
  font-weight: 600;
  font-size: 13px;
  padding-left: 10px;
  color: #828282;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  
  & div {
    margin-right: 5px;
    padding-top: 4px;
    padding-bottom: 4px;
   
    & label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;
