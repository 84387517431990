/* eslint-disable react/prop-types */
import amexIcon from 'assets/icons/cards/amex.svg';
import auraIcon from 'assets/icons/cards/aura.svg';
import cabalIcon from 'assets/icons/cards/cabal.svg';
import credSystemIcon from 'assets/icons/cards/credsystem.svg';
import defaultIcon from 'assets/icons/cards/default.svg';
import dinnersIcon from 'assets/icons/cards/dinners.svg';
import eloDebitoIcon from 'assets/icons/cards/elo-debito-credito.svg';
import eloIcon from 'assets/icons/cards/elo.svg';
import fininvestIcon from 'assets/icons/cards/fininvest.svg';
import geraIcon from 'assets/icons/cards/gera.svg';
import grupoBoticarioIcon from 'assets/icons/cards/grupoboticario.svg';
import hipercardIcon from 'assets/icons/cards/hipercard.svg';
import jcbIcon from 'assets/icons/cards/jcb.svg';
import losangoIcon from 'assets/icons/cards/losango.svg';
import mastercardIcon from 'assets/icons/cards/mastercard.svg';
import sodexoIcon from 'assets/icons/cards/sodexo.svg';
import soloIcon from 'assets/icons/cards/solo.svg';
import sorocredIcon from 'assets/icons/cards/sorocred.svg';
import ticketIcon from 'assets/icons/cards/ticket.svg';
import visaValeIcon from 'assets/icons/cards/visa-vale.svg';
import visaIcon from 'assets/icons/cards/visa.svg';
import vrIcon from 'assets/icons/cards/vr.svg';
import Card from 'components/Grid/Card';
import Col from 'components/Grid/Col';
import Row from 'components/Grid/Row';
import Text from 'components/Typografy/Text';
import {cardBrandsEnum} from 'enums/cardBrandsEnum';
import React from 'react';
import {withTheme} from 'styled-components';
import * as UtilDate from 'utils/util-date';

import {Container} from './styles';

const ConciliationCard = ({
  isSelected,
  onClick,
  value,
  grossValue,
  theme,
  name,
  authorizationCode,
  nsu,
  date,
  idBandeira,
  nota
}) => {
  const backgroundColor = isSelected
    ? theme.colors.principal
    : theme.colors.white;
  
  const textColor = isSelected
      ? theme.colors.white
      : theme.colors.black;
  
  function getCardBrand(key) {
      try {
        
        const cardBrand = [
          { key: cardBrandsEnum.default, value: defaultIcon },
          { key: cardBrandsEnum.visa, value: visaIcon },
          { key: cardBrandsEnum.mastercard, value: mastercardIcon },
          { key: cardBrandsEnum.diners, value: dinnersIcon },
          { key: cardBrandsEnum.americanExpress, value: amexIcon },
          { key: cardBrandsEnum.solo, value: soloIcon },
          { key: cardBrandsEnum.sidecard, value: defaultIcon },
          { key: cardBrandsEnum.privateLabel, value: defaultIcon },
          { key: cardBrandsEnum.redeshop, value: defaultIcon },
          { key: cardBrandsEnum.fininvest, value: fininvestIcon },
          { key: cardBrandsEnum.jcb, value: jcbIcon },
          { key: cardBrandsEnum.hipercard, value: hipercardIcon },
          { key: cardBrandsEnum.aura, value: auraIcon },
          { key: cardBrandsEnum.losango, value: losangoIcon },
          { key: cardBrandsEnum.sorocred, value: sorocredIcon },
          { key: cardBrandsEnum.visaVale, value: visaValeIcon },
          { key: cardBrandsEnum.sodexo, value: sodexoIcon },
          { key: cardBrandsEnum.cabal, value: cabalIcon },
          { key: cardBrandsEnum.ticket, value: ticketIcon },
          { key: cardBrandsEnum.elo, value: eloIcon },
          { key: cardBrandsEnum.credSystem, value: credSystemIcon },
          { key: cardBrandsEnum.vr, value: vrIcon },
          { key: cardBrandsEnum.hiper, value: hipercardIcon },
          { key: cardBrandsEnum.boticario, value: grupoBoticarioIcon },
          { key: cardBrandsEnum.eloDebito, value: eloDebitoIcon },
          { key: cardBrandsEnum.eloCredito, value: eloDebitoIcon },
          { key: cardBrandsEnum.visaConciliacao, value: visaIcon },
          { key: cardBrandsEnum.masterConciliacao, value: mastercardIcon },
          { key: cardBrandsEnum.bandeiraAlternativa, value: defaultIcon },
          { key: cardBrandsEnum.gera, value: geraIcon },
        ];
        
        const obj = cardBrand.find(o => o.key === key);
        
        if (obj === undefined) {
            
            // console.log(key);
        }
        
        return obj === undefined
            ? cardBrand[0].value
            : obj.value;
      
      } catch {
        return null;
      }
    }


  return (
    <Container onClick={() => onClick(value)}>
      <Card width="100%" backgroundColor={backgroundColor}>
        <Col size={1}>
          <img alt="card" width="150%" src={getCardBrand(idBandeira)} />
        </Col>
        {name !== null && (
        <Col size={2}>
          <Row>
            <Text color={textColor}>Cliente</Text>
          </Row>
          <Row>
            <Text color={textColor} fontWeight={600}>
              {name}
            </Text>
          </Row>
        </Col>
      )}
        <Col size={2}>
          <Row>
            <Text color={textColor}>Data</Text>
          </Row>
          <Row>
            <Text color={textColor} fontWeight={600}>
              {UtilDate.formatDate(date)}
            </Text>
          </Row>
        </Col>
        <Col size={2}>
          <Row>
            <Text color={textColor}>NSU</Text>
          </Row>
          <Row>
            <Text color={textColor} fontWeight={600}>
              {nsu}
            </Text>
          </Row>
        </Col>
        <Col size={2}>
          <Row>
            <Text color={textColor}>Cod. Aut.</Text>
          </Row>
          <Row>
            <Text color={textColor} fontWeight={600}>
              {authorizationCode}
            </Text>
          </Row>
        </Col>
        <Col size={2}>
          <Row>
            <Text color={textColor}>Valor Bruto</Text>
          </Row>
          <Row>
            <Text color={textColor} fontWeight={600}>
              {Intl.NumberFormat('pt-BR', {style: 'currency', currency:'BRL'}).format(grossValue)}
            </Text>
          </Row>
        </Col>
        {nota !== undefined &&  (
        <Col size={2}>
          <Row>
            <Text color={textColor}>Nº nota</Text>
          </Row>
          <Row>
            <Text color={textColor} fontWeight={600}>
              {nota}
            </Text>
          </Row>
        </Col>
      )}
      </Card>
    </Container>
  );
};

export default withTheme(ConciliationCard);
