/* eslint-disable react-hooks/exhaustive-deps */

import Close from 'assets/icons/close.svg';
import WarningWhite from 'assets/icons/warning-white.svg';
import PropTypes from 'prop-types';
import React, { useRef, useState, useEffect } from 'react';


import { Container, ImgWarning, ImgClose } from './styles';

function Alert(props) {
  const { children, onClick, onClose, visible } = props;
  const containerElementRef = useRef();

  const [isVisible, setIsVisible] = useState(visible);

  function handleCloseAlert() {
    if (onClose && typeof onClose === 'function') {
      onClose();
    }
    setIsVisible(false);
  }

  useEffect(() => {
    setIsVisible(visible);
  });

  return (
    <>
      {isVisible && (
        <Container onClick={onClick} ref={containerElementRef}>
          <ImgWarning src={WarningWhite} alt="warning" />
          {children}
          <ImgClose src={Close} alt="close" onClick={handleCloseAlert} />
        </Container>
      )}
    </>
  );
}

Alert.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  visible: PropTypes.bool,
};

Alert.defaultProps = {
  children: undefined,
  onClick: undefined,
  onClose: undefined,
  visible: true,
};

export default Alert;
