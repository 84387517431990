import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: ${props => props.padding};
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #eee;
  border-radius: 4px;
  padding: ${props => props.padding};
  background-color: ${props => props.backgroundColor};
  box-shadow: 0px 2px 2px rgba(242, 242, 242, 0.15);
  transition: all 0.3s linear;
  background: ${props => props.background};
  box-shadow: ${props => props.boxShadow};
  border-radius: ${props => props.borderRadius};
  margin-top: ${props => props.marginTop};

  ${props =>
    css`
      flex: 1 1 ${props.width};
    `}

  ${props =>
    css`
      height: ${props.height};
    `}
`;
