export const Types = {
    GET_FILTERSBARS_CHART        : 'filtersChart/GET_FILTERSBARS_CHART',
    GET_FILTERSBARS_CHART_SUCCESS: 'filtersChart/GET_FILTERSBARS_CHART_SUCCESS',
    GET_FILTERSBARS_CHART_ERROR  : 'filtersChart/GET_FILTERSBARS_CHART_ERROR',
};

const INITIAL_STATE = {
    records   : [],
    loading: false,
    error  : '',
};

export default function filtersChart(state = INITIAL_STATE, action) {
    
    switch (action.type) {
        
        case Types.GET_FILTERSBARS_CHART: {
            return {
                records: [],
                loading: true,
                error  : ''
            };
        }
        
        case Types.GET_FILTERSBARS_CHART_SUCCESS: {
    
            return {
                records: action.payload,
                loading: false,
                error  : ''
            };
        }
        
        case Types.GET_FILTERSBARS_CHART_ERROR: {
            return {
                records: [],
                loading: false,
                error  : action.payload.error
            };
        }
        
        default:
            return state;
    }
    
}
