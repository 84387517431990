export const Types = {
    GET_ACQUIRERS: "acquirers/GET_ACQUIRERS",
    GET_ACQUIRERS_SUCCESS: "acquirers/GET_ACQUIRERS_SUCCESS",
    GET_ACQUIRERS_ERROR: "acquirers/GET_ACQUIRERS_ERROR",
    }
   
    const INITIAL_STATE = {
      data: [],
      loading: false,
      error: '',
    };

    export default function acquirers(state = INITIAL_STATE, action){

      switch(action.type){

        case Types.GET_ACQUIRERS: {
          return {data:[], loading:true, error:''}
        }

        case Types.GET_ACQUIRERS_SUCCESS: {
          return {data: action.data, loading:false,  error:''}
        }

        case Types.GET_ACQUIRERS_ERROR: {
          return {loading:false, error:'Error', ...state}
        }

        default:
          return state;
      }

    }