import styled from 'styled-components';


export const Container = styled.div`
  width: 100%;
  max-width:1000px;
  min-width:400px;
  flex: 1 1 100%;
  margin: 0 0 ${ props => props.theme.spacing.margin10 } 0;
  cursor: pointer;
`;
