import PropTypes from 'prop-types';
import React from 'react';
import theme from 'styles/theme';

import {StyledParagraph} from './styles';

const Paragraph = ({
  children,
  textAlign,
  fontSize,
  fontWeight,
  color,
  cursor,
  padding,
  onClick
}) => (
  <StyledParagraph
    textAlign={textAlign}
    fontSize={fontSize}
    fontWeight={fontWeight}
    color={color}
    cursor={cursor}
    onClick={onClick}
    padding={padding}
  >
    { children }
  </StyledParagraph>
);

Paragraph.propTypes = {
  children  : PropTypes.node.isRequired,
  fontWeight: PropTypes.string,
  fontSize  : PropTypes.string,
  color     : PropTypes.string,
  cursor    : PropTypes.string,
  onClick   : PropTypes.func,
  textAlign : PropTypes.string,
  padding   : PropTypes.string
};

Paragraph.defaultProps = {
  fontWeight: '300',
  fontSize  : '12px',
  cursor    : 'initial',
  color     : theme.colors.principal,
  textAlign : 'justify',
  padding   : '10px',
  onClick   : () => {}
};

export default Paragraph;
