import PropTypes from 'prop-types';
import React from 'react';

import {StyledContainer} from './styles';


const Container = ({
   children,
   
   paddingTop,
   paddingBottom,
   paddingLeft,
   paddingRight,

   marginTop,
   marginBottom,
   marginLeft,
   marginRight,

   width,
   height,
   maxWidth,
   maxHeight
}) => {
    const props = {
        paddingTop, paddingBottom, paddingLeft,
        paddingRight, marginTop, marginBottom,
        marginLeft, marginRight, width,
        height, maxWidth, maxHeight
    };
    
    return (
      <StyledContainer {...props}>
        { children }
      </StyledContainer>
    );
};

Container.propTypes = {
    children: PropTypes.any,
    
    paddingTop   : PropTypes.string,
    paddingBottom: PropTypes.string,
    paddingLeft  : PropTypes.string,
    paddingRight : PropTypes.string,
    
    marginTop   : PropTypes.string,
    marginBottom: PropTypes.string,
    marginLeft  : PropTypes.string,
    marginRight : PropTypes.string,
    
    width    : PropTypes.string,
    height   : PropTypes.string,
    maxWidth : PropTypes.string,
    maxHeight: PropTypes.string
};

Container.defaultProps = {
    children: undefined,

    paddingTop   : '0px',
    paddingBottom: '0px',
    paddingLeft  : '5px',
    paddingRight : '5px',
    
    marginTop   : '0px',
    marginBottom: '0px',
    marginLeft  : 'auto',
    marginRight : 'auto',
    
    width : '100%',
    height: 'auto',
    
    maxWidth : 'max-content',
    maxHeight: 'max-content'
};

export default Container;