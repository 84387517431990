import { call, put } from 'redux-saga/effects';

import api from '../../../../../services/api';
import { Types as ObterVisaoTypes } from '../../../../ducks/Dashboard/LucroLiquido/ObterVisao';

export function* getObterVisao() {
  try {
    const response = yield call(api.post, 'filtros/visoes');

    if (response.status === 200) {
      yield put({
        type: ObterVisaoTypes.GET_OBTER_VISAO_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: ObterVisaoTypes.GET_OBTER_VISAO_ERROR,
      error,
    });
  }
}
