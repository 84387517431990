import styled from 'styled-components';

export const StyledWrapper = styled.div`
  width: 100%;

  .SingleDatePicker {
    width: 100%;
  }

  .DateRangePicker {
    width: 100%;
  }

  .SingleDatePickerInput {
    width: 100%;
  }

  .DateRangePickerInput {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 35px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #cccccc;
  }

  .DateInput {
    width: 40%;
  }

  .DateInput_input {
    font-size: 12px;
    padding: unset;
    text-align: center;
  }

  .DateInput_input__focused {
    font-size: 12px;
    padding: unset;
    text-align: center;
    border-bottom: 2px solid #3ea2ff;
  }

  .DateRangePickerInput_arrow_svg {
    width: 15px;
  }

  .SingleDatePickerInput__withBorder {
    border: none;
  }

  .CalendarDay__hovered_span {
    background: rgba(128, 194, 255, 0.5);
    border-color: rgba(62, 162, 255, 0.5);
    color: white;
  }

  .CalendarDay__selected_span {
    background: rgba(62, 162, 255, 0.5);
    border-color: rgba(62, 162, 255, 0.5);
    color: white;
  }

  .CalendarDay__selected {
    background: #3ea2ff;
    border-color: #3ea2ff;
    color: white;
  }

  .DateRangePickerInput_calendarIcon {
    padding: unset;
    margin-bottom: 4px;
    margin-right: 10px;
  }

  .DateRangePickerInput_calendarIcon_svg {
    width: 13px;
    height: 13px;
  }
`;
