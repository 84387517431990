export const Types = {
    GET_CARDBRANDS: "cardBrands/GET_CARDBRANDS",
    GET_CARDBRANDS_SUCCESS: "cardBrands/GET_CARDBRANDS_SUCCESS",
    GET_CARDBRANDS_ERROR: "cardBrands/GET_CARDBRANDS_ERROR",
  }

    const INITIAL_STATE = {
      data: [],
      loading: false,
      error: '',
    };

    export default function cardBrands(state = INITIAL_STATE, action){

      switch(action.type){

        case Types.GET_CARDBRANDS: {
          return {data:[], loading:true, error:''}
        }

        case Types.GET_CARDBRANDS_SUCCESS: {
          return {data: action.data, loading:false, error:''}
        }

        case Types.GET_CARDBRANDS_ERROR: {
          return {loading:false, error:'Error', ...state}
        }

        default:
          return state;
      }

  }
  