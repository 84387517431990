import { call, put } from 'redux-saga/effects';

import api from '../../../../services/api';
import { Types as ResultadosDreTypes } from '../../../ducks/Dashboard/ResultadosDRE';

export function* getResultadosDre(action) {
  try {
    const { date: {startDate, endDate}, idVisao } = action.payload;

    const response = yield call(
      api.get,
      `Home/GetCardFromVisao?dataInicial=${startDate.format(
        'Y-MM-DD',
      )}&dataFinal=${endDate.format('Y-MM-DD')}&idVisao=${idVisao}`,
    );

    if (response.status === 200) {
      yield put({
        type: ResultadosDreTypes.GET_RESULTADOS_DRE_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: ResultadosDreTypes.GET_RESULTADOS_DRE_ERROR,
      error,
    });
  }
}
