import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { Container } from './styles';

const Card = ({
  children,
  height,
  width,
  backgroundColor,
  padding,
  background,
  boxShadow,
  borderRadius,
  marginTop,
}) => (
  <Container
    data-testid="test-card"
    height={height}
    width={width}
    backgroundColor={backgroundColor}
    padding={padding}
    background={background}
    boxShadow={boxShadow}
    borderRadius={borderRadius}
    marginTop={marginTop}
  >
    {children}
  </Container>
);

Card.propTypes = {
  children: PropTypes.node,
  height: PropTypes.string,
  width: PropTypes.string,
  backgroundColor: PropTypes.string,
  padding: PropTypes.string,
  background: PropTypes.string,
  boxShadow: PropTypes.string,
  borderRadius: PropTypes.string,
  marginTop: PropTypes.string,
};

Card.defaultProps = {
  children: undefined,
  height: 'auto',
  width: '100%',
  backgroundColor: 'white',
  padding: '10px',
  background: '',
  boxShadow: '',
  borderRadius: '',
  marginTop: '0px,',
};

export default memo(Card);
