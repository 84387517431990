export const Types = {
    GET_CONCILIATION_RECEIVE:'conciliation/GET_CONCILIATION_RECEIVE',
    GET_CONCILIATION_RECEIVE_SUCCESS:'conciliation/GET_CONCILIATION_RECEIVE_SUCCESS',
    GET_CONCILIATION_RECEIVE_ERROR:'conciliation/GET_CONCILIATION_RECEIVE_ERROR'
  }
  
  const INITIAL_STATE = {
    acquirerSalesIds:[],
    loading: false,
    error: '',
  }
  
  export default function conciliationReceive(state = INITIAL_STATE, action){
      
      switch(action.type){
        case Types.GET_CONCILIATION_RECEIVE:{
          return {
            cardReceiveIds:[],
            loading:true,
            error:''
          }
        }
        case Types.GET_CONCILIATION_RECEIVE_SUCCESS:{
          return {
            cardReceiveIds:action.payload.conciliate, 
            loading:false, 
            error:''
          }
        }
  
        case Types.GET_CONCILIATION_RECEIVE_ERROR:{
          return {
            cardReceiveIds:[], 
            loading:false, 
            error:action.payload.error
          }
        }
  
        default:
          return state;
      }
  }
  