/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */

import ok from 'assets/icons/ok.svg';
import warning from 'assets/icons/warning.svg';
import Card from 'components/Grid/Card';
import Col from 'components/Grid/Col';
import Row from 'components/Grid/Row';
import checkIsNullOrUndefined from 'NullCheck';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { getNeoXEndpoint, openNeoXLink } from '../../../services/config';
import {
  CardTitle,
  StatusTitle,
  ItemKey,
  ItemValue,
  MarginRow,
  MoreInfoLink,
} from './styles';

function CashConferenceStatusCard() {
  const ConferenciaDeCaixaData = useSelector(
    state => state.ConferenciaDeCaixaReducer,
  );

  const [info, setInfo] = useState({
    isOk: true,
    conciliatedRecords: 49239.23,
    unconciliatedRecords: 45239.23,
  });

  return (
    <Card boxShadow="0px 0px 5px RGBA(0,0,0,0.11)">
      <Col size={12}>
        <Row>
          <Col size={9} justifyContent="flex-start" padding="5px 5px 0px 5px">
            <CardTitle>Conferência de Caixa</CardTitle>
            <StatusTitle isOk={ConferenciaDeCaixaData.data.payload?.isOk}>
              {ConferenciaDeCaixaData.data.payload?.isOk
                ? 'Conferido'
                : 'Conferências pendentes'}
            </StatusTitle>
          </Col>
          <Col size={3} justifyContent="flex-end" padding="5px 5px 0px 5px">
            <img
              src={ConferenciaDeCaixaData.data.payload?.isOk ? ok : warning}
              alt={ConferenciaDeCaixaData.data.payload?.isOk ? 'ok' : 'warning'}
            />
          </Col>
        </Row>

        <MarginRow>
          <Col size={6} justifyContent="flex-start" padding="5px">
            <ItemKey>Valor de venda</ItemKey>
          </Col>
          <Col
            size={6}
            justifyContent="flex-end"
            padding="5px"
            alignItems="right"
          >
            <ItemValue>
              {ConferenciaDeCaixaData.data.payload?.valorVenda.toLocaleString(
                'pt-BR',
                {
                  minimumFractionDigits: 2,
                  style: 'currency',
                  currency: 'BRL',
                },
              )}
            </ItemValue>
          </Col>
        </MarginRow>
        <Row>
          <Col size={6} justifyContent="left" padding="5px">
            <ItemKey>Valor gerado do financeiro</ItemKey>
          </Col>
          <Col
            size={6}
            justifyContent="flex-end"
            padding="5px"
            alignItems="right"
          >
            <ItemValue>
              {ConferenciaDeCaixaData.data.payload?.valorFinanceiro.toLocaleString(
                'pt-BR',
                {
                  minimumFractionDigits: 2,
                  style: 'currency',
                  currency: 'BRL',
                },
              )}
            </ItemValue>
          </Col>
        </Row>
        <MarginRow>
          <Col size={4} justifyContent="left" padding="5px 5px 0px 5px">
            <MoreInfoLink
              href={getNeoXEndpoint(checkIsNullOrUndefined(localStorage.getItem('@UxTransPathEverest/ExtratoMovimentacaoFinanceiraLoja')) ?? `#Linx-Administrativo/Financeiro/Consultas/Extrato-Movimentacao-Financeira-de-Loja`)}
              onClick={openNeoXLink}
            >
              VER MAIS

            </MoreInfoLink>
          </Col>
        </MarginRow>
      </Col>
    </Card>
  );
}

export default CashConferenceStatusCard;
