/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unused-vars */

import Button from 'components/Forms/Button/index';
import DatePicker from 'components/Forms/DatePicker';
import DropdownItem from 'components/Forms/Dropdown/DropdownItem/index';
import Dropdown from 'components/Forms/Dropdown/index';
import Form from 'components/Forms/Form';
import Pagination from 'components/Forms/Pagination';
import Col from 'components/Grid/Col';
import Divider from 'components/Grid/Divider';
import Row from 'components/Grid/Row';
import Table from 'components/Table';
import Text from 'components/Typografy/Text';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import api from 'services/api';
import theme from 'styles/theme';
import {formatJsonDate} from 'utils/util-date';


export default function FinancialMovement() {
  const [subsidiaries, setSubsidiaries] = useState([]);
  const [paymentTypes, setPaymentTypes] = useState([]);

  // TROCAR PARA FORMIK
  const [subsidiarieId, setSubsidiarieId] = useState(null);
  const [paymentTypeId, setPaymentTypeId] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [startDate, setStartDate] = useState(moment());
  const [finalDate, setFinalDate] = useState(moment().subtract(1, 'M'));
  const [pageNumber, setPageNumber] = useState(null);

  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);


  // const [cor, setCor] = useState()


  const dados = [
    {
    id:1,
    operacao:'Pagemento 01',
    parcelas: '1/1',
    emissao: '15/12/2018',
    vencimento:'15/12/2019',
    filial:'Filial01',
    fornecedor:'Alfredo',
    valor:147.49,
    status:'Em aberto'
  },
  {
    id:2,
    operacao:'Pagemento 02',
    parcelas: '1/1',
    emissao: '15/12/2018',
    vencimento:'15/12/2019',
    filial:'Filial01',
    fornecedor:'Alfredo',
    valor:147.49,
    status:'Liquidado'
  },
  {
    id:3,
    operacao:'Pagemento 03',
    parcelas: '1/1',
    emissao: '15/12/2018',
    vencimento:'15/12/2019',
    filial:'Filial01',
    fornecedor:'Alfredo',
    valor:147.49,
    status:'Em atraso'
  },
  {
    id:4,
    operacao:'Pagemento 04',
    parcelas: '1/1',
    emissao: '15/12/2018',
    vencimento:'15/12/2019',
    filial:'Filial01',
    fornecedor:'Alfredo',
    valor:147.49,
    status:'Em aberto'
  },
  {
    id:5,
    operacao:'Pagemento 05',
    parcelas: '1/1',
    emissao: '15/12/2018',
    vencimento:'15/12/2019',
    filial:'Filial01',
    fornecedor:'Alfredo',
    valor:147.49,
    status:'Liquidado'
  },
]


  useEffect(() => {
    
  }, [pageNumber]);

  return (
    <>
      <Row>
        <Col justifyContent="flex-start">
          <Text fontSize="24px" color="#616161">
            Movimentação Financeira
          </Text>
        </Col>
      </Row>
      <Divider />
      <Form>
        <Row justifyContent="center">
          <Col size={11}>
            <Row>
              <Col size={3}>
                <Row>
                  <Text fontWeight={600}>Período de Compra</Text>
                </Row>
                <DatePicker
                  initialStartDate={startDate}
                  initialEndDate={finalDate}
                  onSelectedStartDate={date => setStartDate(date)}
                  onSelectedEndDate={date => setFinalDate(date)}
                />
              </Col>
              <Col size={3}>
                <Row>
                  <Text fontWeight={600}>Filial</Text>
                </Row>
                <Dropdown name="Filial" onChange={id => setSubsidiarieId(id)}>
                  <DropdownItem label="Todos" />
                  {subsidiaries.map(subsidiarie => (
                    <DropdownItem
                      key={subsidiarie.IdFilialPjf}
                      label={subsidiarie.NomeFilial}
                      value={subsidiarie.IdFilialPjf}
                    />
                  ))}
                </Dropdown>
              </Col>
              <Col size={1}>
                <Row>
                  <Text fontWeight={600}> </Text>
                </Row>
                <Button
                  backgroundColor={theme.colors.black55}
                  outline
                  onClick={() => {}}
                >
                  BUSCAR
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justifyContent="center">
          <Col size={11}>    
            <Row>
              <Table>
                <thead>
                  <tr>
                    <th><input type="checkbox" name="mov" id="x" /></th>
                    <th>Operação</th>
                    <th>Parcelas</th>
                    <th>Data emissão</th>
                    <th>Vencimento</th>
                    <th>Filial</th>
                    <th>Fornecedor</th>
                    <th>Valor total</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {dados.map(dado => (
                    <tr key={dado.id}>
                      <td><input type="checkbox" name="mov" id="x" /></td>
                      <td>{dado.operacao}</td>
                      <td>{dado.parcelas}</td>
                      <td>{dado.emissao}</td>
                      <td>{dado.vencimento}</td>
                      <td>{dado.filial}</td>
                      <td>{dado.fornecedor}</td>
                      <td>{dado.valor}</td>
                      <td>{dado.status}</td>
                    </tr>
                  ))} 
                </tbody>
              </Table>
            </Row>
            <Row justifyContent="space-between">
              <Col size={6} justifyContent="flex-start">
                <Text fontWeight={400} fontSize="10px">
                  Mostrando 
                  {' '}
                  {currentPage * pageSize}
                  {' '}
                  de
                  {' '}
                  {totalRows}
                  {' '}
                  resultados
                </Text>
              </Col>
              <Col size={2}>
                <Dropdown
                  name="quantidade"
                  onChange={value => setPageSize(value)}
                >
                  <DropdownItem label="Mostrar 10 por vez" value={10} />
                  <DropdownItem label="Mostrar 20 por vez" value={20} />
                  <DropdownItem label="Mostrar 30 por vez" value={30} />
                </Dropdown>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justifyContent="center">
          <Pagination
            count={totalPages}
            defaultPage={currentPage}
            onChange={number => setPageNumber(number)}
          />
        </Row>
      </Form>
    </>
  );
}
