/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */

import ok from 'assets/icons/ok.svg';
import warning from 'assets/icons/warning.svg';
import Card from 'components/Grid/Card';
import Col from 'components/Grid/Col';
import Row from 'components/Grid/Row';
import checkIsNullOrUndefined from 'NullCheck';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { getNeoXEndpoint, openNeoXLink } from '../../../services/config';
import { CardTitle, StatusTitle, ItemKey, ItemValue, MarginRow, MoreInfoLink } from './styles';

function CreditCardSalesConciliationStatusCard() {
  const VendaDeCartaoData = useSelector(state => state.VendaDeCartaoReducer);

  const [info, setInfo] = useState({
    isOk: false,
    conciliatedRecords: 2,
    unconciliatedRecords: 7,
  });

  return (
    <Card boxShadow="0px 0px 5px RGBA(0,0,0,0.11)">
      <Col size={12}>
        <Row>
          <Col size={9} justifyContent="flex-start" padding="5px 5px 0px 5px">
            <CardTitle>Venda de Cartão</CardTitle>
            <StatusTitle isOk={VendaDeCartaoData.data.payload?.isOk}>
              {VendaDeCartaoData.data.payload?.isOk
                ? 'Conciliado'
                : 'Conciliações pendentes'}
            </StatusTitle>
          </Col>
          <Col size={3} justifyContent="flex-end" padding="5px 5px 0px 5px">
            <img
              src={VendaDeCartaoData.data.payload?.isOk ? ok : warning}
              alt={VendaDeCartaoData.data.payload?.isOk ? 'ok' : 'warning'}
            />
          </Col>
        </Row>

        <MarginRow>
          <Col size={6} justifyContent="flex-start" padding="5px">
            <ItemKey>Divergências na adquirente</ItemKey>
          </Col>
          <Col
            size={6}
            justifyContent="flex-end"
            padding="5px"
            alignItems="right"
          >
            <ItemValue>
              {VendaDeCartaoData.data.payload?.acquirerCount}
            </ItemValue>
          </Col>
        </MarginRow>
        <Row>
          <Col size={6} justifyContent="left" padding="5px">
            <ItemKey>Divergências no sistema</ItemKey>
          </Col>
          <Col
            size={6}
            justifyContent="flex-end"
            padding="5px"
            alignItems="right"
          >
            <ItemValue>
              {VendaDeCartaoData.data.payload?.cardReceiveCount}
            </ItemValue>
          </Col>
        </Row>
        <MarginRow>
          <Col size={4} justifyContent="left" padding="5px 5px 0px 5px">
            <MoreInfoLink            
              href={getNeoXEndpoint(checkIsNullOrUndefined(localStorage.getItem('@UxTransPathEverest/ConferenciaConciliacaoVenda')) ?? `#Linx-Administrativo/Financeiro/Conciliacao/Conciliacao-de-Venda-de-Cartao-New`)}
              onClick={openNeoXLink}
            >
              VER MAIS
            </MoreInfoLink>
          </Col>
        </MarginRow>
      </Col>
    </Card>
  );
}

export default CreditCardSalesConciliationStatusCard;
