
import ProgressBar from 'components/Forms/ProgressBar';
import Card from 'components/Grid/Card';
import Col from 'components/Grid/Col';
import Container from 'components/Grid/Container';
import Row from 'components/Grid/Row';
import Text from 'components/Typografy/Text';
import ConciliationContext from 'pages/Conciliation/ConciliationContext';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

export default function ConciliationBar({ filledValue, totalValue, reverse, name }) {


  const [barColor, setBarColor] = useState(false);
  const percent = (filledValue / totalValue) * 100;
  const justifyContent = reverse ? 'flex-end' : 'flex-start';

  const { acquirerSelectedValue, systemSelectedValue } = useContext(
    ConciliationContext,
  );
  

  useEffect(() => {   
    if (
      systemSelectedValue > 0 &&
      acquirerSelectedValue > 0 &&
      acquirerSelectedValue === systemSelectedValue
    ) {
      setBarColor({
        barColor: '#cee7ff',
        filledBarColor: '#5AB0FF',
      });
    } else {
      setBarColor({
        barColor: '#c5b9d2',
        filledBarColor: '#5B2E90',
      });
      
    }
  }, [acquirerSelectedValue, systemSelectedValue]);

  return (
    <Card padding="7px" height="35px">
      <Container>
        <Row>
          <Col padding="none" justifyContent={justifyContent}>
            <Text fontSize="10px" color="#828282">
              {name}
              : R$
              {' '}
              {filledValue}
              {' '}
              de R$
              {' '}
              {totalValue}
            </Text>
          </Col>
        </Row>
        <Row>
          <ProgressBar percent={100}>
            <ProgressBar
              percent={percent}
              reverse={reverse}
              color={barColor.filledBarColor}
            />
          </ProgressBar>
        </Row>
      </Container>
    </Card>
  );
}

ConciliationBar.propTypes = {
  filledValue: PropTypes.number.isRequired,
  totalValue: PropTypes.number.isRequired,
  reverse: PropTypes.bool,
  name: PropTypes.string,
};

ConciliationBar.defaultProps = {
  reverse: false,
  name: ''
};
