import {call, put} from 'redux-saga/effects';
import * as ConciliationServices from 'services/conciliationServices';

import {Types as Acquirers} from '../../../ducks/Conciliation/Acquirers'; 

export function* getAcquirers(){
  try {
    
    const response = yield call(ConciliationServices.getAcquirers);

    yield put({
      type: Acquirers.GET_ACQUIRERS_SUCCESS,
      data:response
    })
    
  } catch (error) {

    yield put({
      type: Acquirers.GET_ACQUIRERS_ERROR, error
    })
    
  }
}
