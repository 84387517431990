/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import Text from 'components/Typografy/Text/index';
import PropTypes from 'prop-types';
import React, { memo, useState, useEffect } from 'react';
import everestSessionStorage, {
  storageKeys,
} from 'storage/everest-session-storage';
import theme from 'styles/theme';
import { getAppName, getAppVersion } from 'utils/app-info';

import SelectStoreButton from '../SelectStoreButton';
import SelectStoreModal from '../SelectStoreModal';
import {
  StyledBackOfficeHeaderContainer,
  StyledTitle,
  StyledStore,
} from './styles';

const BackOfficeHeader = ({ title, version, path }) => {
  const [storeState, setStoreState] = useState(undefined);
  const [showModalState, setShowModalState] = useState(false);

  useEffect(() => {
    const store = everestSessionStorage.getItem(storageKeys.STORE);
    if (store !== null && store !== 'null') {
      setStoreState(store);
    }
  }, []);

  function resetShowModalStateHandler() {
    setShowModalState(true);
  }

  const showModal = () => (
    <SelectStoreModal onCloseCallback={resetShowModalStateHandler} />
  );

  return (
    <>
      {/* {storeState === undefined ? !showModalState && showModal() : <></>} */}

      <StyledBackOfficeHeaderContainer>
        <StyledTitle>
          <Text fontSize="24px" color={theme.colors.black70} fontWeight={400}>
            {title}
          </Text>
          <Text fontSize="10px" color={theme.colors.black55}>
            {path}
          </Text>
        </StyledTitle>

        {/* <StyledStore>
          <Text fontSize={theme.fontSize._13px}>
            Filial selecionada:
            <Text fontSize={theme.fontSize._13px} fontWeight={600}>
              {
              ' Loja Teste' // {storeState?.name}
              }
            </Text>
          </Text>
          <SelectStoreButton
            onStatusChangeCallBack={status => setStoreState(status)}
            label="TROCAR LOJA"
            style={{ float: 'right', width: '80px' }}
          />
        </StyledStore> */}
      </StyledBackOfficeHeaderContainer>
    </>
  );
};

BackOfficeHeader.propTypes = {
  title: PropTypes.string,
  version: PropTypes.string,
  path: PropTypes.string,
};

BackOfficeHeader.defaultProps = {
  title: getAppName(),
  version: getAppVersion(),
  path: ''
};

export default memo(BackOfficeHeader);
