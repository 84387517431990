import styled from 'styled-components';

export const MenuContainer = styled.div`
  position: absolute;
  top: ${props => props.offset}px;
  width: ${props => props.width}px;
  left: 0px;
  display: ${props => (props.isOpen ? 'block' : 'none')};
  color: ${props => props.color};
  background-color: ${props => props.backgroundColor};
  z-index: 32432043;
`;

export const MenuButton = styled.button`
  color: ${props => props.color};
  background-color: ${props => props.backgroundColor};
  display: block;
  text-decoration: none;
  padding: 15px;
  border: none;
  font-weight: 600;
  cursor: pointer;
`;
