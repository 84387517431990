import * as qs from 'qs';
import api from 'services/api';


const routeV1 = 'conciliation';
const routeV2 = 'api/v2/conciliation';


const routes = {
    cardBrands                 : `${routeV2}/card-brand`,
    acquirers                  : `${routeV2}/acquirer`,
    subsidiaries               : `${routeV2}/subsidiary`,
    acquirerUnconcealedSales   : `${routeV2}/acquirer/sales/unconcealed`,
    cardReceiveUnconcealedSales: `${routeV2}/card-receive/sales/unconcealed`,
    chartResume                : `${routeV2}/chart-resume`,
    
    conciliate                 : `${routeV1}/conciliate`,
};




export async function getCardBrands() {
    return api.get(routes.cardBrands).then(res => {
        return res.data.payload;
    });
}

export async function getAcquirers() {
    return api.get(routes.acquirers).then(res => {
        return res.data.payload;
    });
}

export async function getSubsidiaries() {
    return api.get(routes.subsidiaries).then(res => {
        return res.data.payload;
    });
}

export async function getAcquirerUnconcealedSales({
  acquirers = [],
  cardBrands = [],
  subsidiaries = [],
  divergencesOnly = false,
  initialDate = null,
  finalDate = null,
  pageIndex = null,
  pageSize = 10
}) {
    const filters = {
        acquirers,
        cardBrands,
        subsidiaries,
        divergencesOnly,
        initialDate,
        finalDate,
        pageIndex, 
        pageSize 
    };

    return api.get(routes.acquirerUnconcealedSales, {
        params: filters,
        paramsSerializer: params => {
            return qs.stringify(params)
        }
    }).then(res => {
        return {
            records: res.data.payload,
            pager: res.data.pager
        };
    });
}




export async function getCardReceiveUnconcealedSales({
   acquirers = [],
   cardBrands = [],
   subsidiaries = [],
   divergencesOnly = false,
   initialDate = null,
   finalDate = null,
   pageIndex = 1,
   pageSize = 10
}) {
    const filters = {
        acquirers,
        cardBrands,
        subsidiaries,
        divergencesOnly,
        initialDate,
        finalDate,
        pageIndex,
        pageSize
    };
    
    return api.get(routes.cardReceiveUnconcealedSales, {
        params: filters,
        paramsSerializer: params => {
            return qs.stringify(params)
        }
    }).then(res => {
        return {
            records: res.data.payload,
            pager: res.data.pager
        };
    });
}

export async function getChartResume({
  acquirers = [],
  cardBrands = [],
  subsidiaries = [],
  divergencesOnly = false,
  initialDate = null,
  finalDate = null,
}){
    const filters = {
        acquirers,
        cardBrands,
        subsidiaries,
        divergencesOnly,
        initialDate,
        finalDate,
    };
    
    return api.get(routes.chartResume, {
        params: filters,
        paramsSerializer: params => {
            return qs.stringify(params)
        }
    }).then(res => {
        return res.data.payload;
    });
}


export async function conciliate(data) {
    return api.post(routes.conciliate, data).then(res => {
        return res.data;
    });
}
