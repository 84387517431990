export const Types = {
  GET_SALDO_DISPONIVEL_ANTECIPACAO:
    '@Dashboard/SaldoDisponivelAntecipacao/GET_SALDO_DISPONIVEL_ANTECIPACAO',
  GET_SALDO_DISPONIVEL_ANTECIPACAO_SUCCESS:
    '@Dashboard/PendenciasFinanceiras/GET_SALDO_DISPONIVEL_ANTECIPACAO_SUCCESS',
  GET_SALDO_DISPONIVEL_ANTECIPACAO_ERROR:
    '@Dashboard/PendenciasFinanceiras/GET_SALDO_DISPONIVEL_ANTECIPACAO_ERROR',
};

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: '',
};

export default function saldoDisponivelAntecipacao(
  state = INITIAL_STATE,
  action,
) {
  switch (action.type) {
    case Types.GET_SALDO_DISPONIVEL_ANTECIPACAO: {
      return { data: {}, loading: true, error: '' };
    }
    case Types.GET_SALDO_DISPONIVEL_ANTECIPACAO_SUCCESS: {
      return {
        data: action.data,
        loading: false,
        error: '',
      };
    }
    case Types.GET_SALDO_DISPONIVEL_ANTECIPACAO_ERROR: {
      return {
        data: {},
        loading: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
}
