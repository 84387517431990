/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import bancoDoBrasilIcon from 'assets/icons/brands/banco-do-brasil.svg';
import bradescoIcon from 'assets/icons/brands/bradesco.svg';
import caixaIcon from 'assets/icons/brands/caixa-economica-federal.svg';
import bancoDefaultIcon from 'assets/icons/brands/default.svg';
import hsbcIcon from 'assets/icons/brands/hsbc.svg';
import itauIcon from 'assets/icons/brands/itau.svg';
import realIcon from 'assets/icons/brands/real.svg';
import safraIcon from 'assets/icons/brands/safra.svg';
import santanderIcon from 'assets/icons/brands/santander.svg';
import contaCaixaIcon from 'assets/icons/conta-caixa.svg';
import ProgressBar from 'components/Forms/ProgressBar';
import ToggleButton from 'components/Forms/ToggleButton';
import Card from 'components/Grid/Card';
import Col from 'components/Grid/Col';
import Divider from 'components/Grid/Divider';
import Row from 'components/Grid/Row';
import Text from 'components/Typografy/Text';
import checkIsNullOrUndefined from 'NullCheck';
import React, { useState, useEffect, Fragment, useContext } from 'react';
import getBalanceAccounts, {getUpdatedOfx} from 'services/balanceAccountsServices';
import { withTheme } from 'styled-components';

import { getNeoXEndpoint, openNeoXLink } from '../../../services/config';
import HomeDateContext from '../context';

const BalanceAccountsCard = () => {
  let i = 1;
  const [isBalanceSystem, setIsBalanceSystem] = useState(true);
  const [updatedOfx, setUpdatedOfx] = useState({
    File: '',
    UpdatedAt: '',
  });
  const [balanceAccounts, setBalanceAccounts] = useState([
    { Id: 0, Account: '', System: 0, Banco: 0 },
  ]);

  const { startDate, endDate } = useContext(HomeDateContext);

  function BankLogos(descAccount) {
    const account = descAccount.toLowerCase();
    let logo;

    if (account.includes('itau') || account.includes('itaú')) {
      logo = itauIcon;
    } else if (account.includes('economica') || account.includes('econômica')) {
      logo = caixaIcon;
    } else if (account.includes('hsbc')) {
      logo = hsbcIcon;
    } else if (account.includes('real')) {
      logo = realIcon;
    } else if (account.includes('safra')) {
      logo = safraIcon;
    } else if (account.includes('brasil')) {
      logo = bancoDoBrasilIcon;
    } else if (account.includes('bradesco')) {
      logo = bradescoIcon;
    } else if (account.includes('santander')) {
      logo = santanderIcon;
    } else if (account.includes('conta caixa')) {
      logo = contaCaixaIcon;
    } else {
      logo = bancoDefaultIcon;
    }

    return logo;
  }

  function ToBalanceAccounts(data) {
    let result = [];
    let id = 0;
    if (data.length > 0) {
      result = data.map(item => {
        id += 1;
        return {
          Id: id,
          Account: item.Conta,
          System: item.Sistema,
          Bank: item.Banco,
          Percentage: item.Porcentagem || 0
        };
      });
    }
    return result;
  }

  const evtChangeBalance = (e) => {
    // disabled toggle function #15058
    e.stopPropagation();
    e.preventDefault();
    return false;
    // if (isBalanceSystem) setIsBalanceSystem(false);
    // else setIsBalanceSystem(true);
  };

  const getInfoOfx = () => {
    getUpdatedOfx().then(result => {
      setUpdatedOfx({ File: result.Arquivo, UpdatedAt: result.DataImportacao });
    });
  };

  const getBalances = () => {
    getBalanceAccounts(
      startDate.format('Y-MM-DD'),
      endDate.format('Y-MM-DD'),
    ).then(result => {
      const balances = ToBalanceAccounts(result);
      setBalanceAccounts(balances);
    });
  };

  useEffect(() => {
    getInfoOfx();
    getBalances();
  }, [startDate, endDate]);

  return (
    <>
      <Card
        width="100%"
        background="linear-gradient(101.62deg, rgba(67, 67, 67, 0.86) 0.01%, rgba(0, 0, 0, 0.86) 100%);"
        boxShadow="0px 0px 5px rgba(0, 0, 0, 0.15);"
        borderRadius="4px;"
      >
        <Col size={12}>
          <Row>
            <Text fontSize="25px" color="#EAEFF7" fontWeight={600}>
              Saldo Contas
            </Text>
          </Row>
          <Row padding="0px 0px 30px 0px">
            {/* <Text fontSize="10px" color="#EAEFF7">
              {`Ofx atualizado em ${updatedOfx.UpdatedAt}`}
            </Text> */}
          </Row>
          {balanceAccounts.map(balance => {
            let value = 0;
            value = isBalanceSystem ? balance.System : balance.Bank;


            i += 1;

            return (
              <Fragment key={balance.Id}>
                <Row width="100%">
                  <Col size={1} padding="10px 10px 10px 0px">
                    <img
                      alt="{balance.Account}"
                      width="15px"
                      height="15px"
                      src={BankLogos(balance.Account)}
                    />
                  </Col>
                  <Col
                    size={7}
                    justifyContent="flex-start"
                    padding="10px 10px 10px 0px"
                  >
                    <Text fontSize="12px" color="#EAEFF7">
                      {balance.Account}
                    </Text>
                  </Col>
                  <Col
                    size={4}
                    justifyContent="flex-end"
                    padding="10px 0px 10px 10px"
                  >
                    <Text fontSize="12px" color="#EAEFF7">
                      {value?.toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </Text>
                  </Col>
                </Row>
                <Row>
                  <ProgressBar percent={100}>
                    <ProgressBar percent={balance.Percentage} color="#6544AC" />
                  </ProgressBar>
                </Row>
                {i <= balanceAccounts.length && (
                  <Row>
                    <Col size={12} padding="10px 0px 0px 0px">
                      <Divider
                        width="100%"
                        lineStyle="dashed"
                        color="#828282"
                      />
                    </Col>
                  </Row>
                )}
              </Fragment>
            );
          })}
          <Row style={{ margin: '20px' }} padding="60px 0px 0px 0px">
            <Col
              size={4}
              fontWeight={600}
              padding="40px 10px 10px 0px"
              justifyContent="flex-start"
            >
              <a
                href={getNeoXEndpoint(checkIsNullOrUndefined(localStorage.getItem('@UxTransPathEverest/ExtratoContaCaixaCorrente')) ?? `#Linx-Administrativo/Financeiro/Consultas/Extrato-de-Contas---Caixa-e-Corrente`)}
                style={{ textDecoration: 'none' }}
                onClick={openNeoXLink}
              >
                <Text fontSize="15px" color="#FFFFFF" cursor="pointer">
                  VER MAIS
                </Text>
              </a>
            </Col>
            <Col
              padding="40px 10px 10px 0px"
              size={8}
              justifyContent="flex-start"
            >
              <ToggleButton
                onClick={evtChangeBalance}
                activeText="SALDO SISTEMA"
                disabledText=""
              />
            </Col>
          </Row>
        </Col>
      </Card>
    </>
  );
};

export default withTheme(BalanceAccountsCard);
