import { call, put } from 'redux-saga/effects';

import api from '../../../../../services/api';
import { Types as ConferenciaDeCaixaTypes } from '../../../../ducks/Dashboard/Conferencia&Conciliacao/ConferenciaDeCaixa';

export function* getConferenciaDeCaixa(action) {
  try {
    const { startDate, endDate } = action.payload.date;

    const response = yield call(
      api.get,
      `/api/v1/cash-conference/store-movement?InitialDate=${startDate.format(
        'Y-MM-DD',
      )}&FinalDate=${endDate.format('Y-MM-DD')}`,
    );

    if (response.status === 200) {
      yield put({
        type: ConferenciaDeCaixaTypes.GET_CONFERENCIA_DE_CAIXA_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: ConferenciaDeCaixaTypes.GET_CONFERENCIA_DE_CAIXA_ERROR,
      error,
    });
  }
}
