/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
import Button from 'components/Forms/Button/index';
import Alert from 'components/Modals/Alert';
import React, {useState, useContext, useEffect} from 'react';
import {useDispatch} from 'react-redux'
import * as ConciliationServices from 'services/conciliationServices';
import {Types as FiltersBarsCards} from 'store/ducks/Conciliation/FiltersBars';
import {Types as FiltersChart} from 'store/ducks/Conciliation/FiltersChart';
import {withTheme} from 'styled-components';
import theme from 'styles/theme';

import ConciliationContext from '../../ConciliationContext';

const ConciliateButton = () => {


    const dispatch = useDispatch();

    const [alertState, setAlertState] = useState({ visible: false, message: '', isSuccess: false, isLoading: true });
  
    const {
      acquirerSalesIds, 
      cardReceiveIds, 
      data, 
      setSelectedCards,
      setAcquirerSalesIds, 
      setAcquirerSelectedValue,
      setSelectedCardsReceive,
      setCardReceiveIds,
      setSystemSelectedValue
    
    } = useContext(ConciliationContext);

    const dataIds = {
      acquirerSalesIds,
      cardReceiveIds
    }

    
    async function handleGetConciliation() {

      setAlertState({ visible: true, message: '', isSuccess: false, isLoading: true });

      if (acquirerSalesIds === undefined || cardReceiveIds === undefined) return 0;

      if (acquirerSalesIds.length === 0 || cardReceiveIds.length === 0) {
        return setAlertState({
            visible  : true,
            message  : 'Os cards não estão selecionado, por favor selecione os cards para iniciar o processo de conciliação.',
            isSuccess: false,
            isLoading: false
        });
    }

    const conciliate = await ConciliationServices.conciliate(dataIds);


    if (!conciliate.IsError) {
      
      setAlertState({
          visible  : true,
          message  : 'Conciliação efetuada com sucesso',
          isSuccess: true,
          isLoading: false
      }); 

      dispatch({
        type: FiltersBarsCards.GET_FILTERSBARS,
        payload: data
      });

      dispatch({ 
        type: FiltersChart.GET_FILTERSBARS_CHART, 
        payload: data 
      });


      const value = 0;
      setSelectedCards([]);
      setAcquirerSalesIds([])
      setAcquirerSelectedValue(value);
      setSelectedCardsReceive([]);
      setCardReceiveIds([]);
      setSystemSelectedValue(value);

      } else {
          setAlertState({
              visible  : true,
              message  : `Houve um erro no processamento, Tente novamente! \n${conciliate.Message}`,
              isSuccess: false,
              isLoading: false
          });
      }

    }
    
    function resetAlertState() {
        setAlertState({ visible: false, message: '', isSuccess: false, isLoading: true });
    }
    
    function showAlert() {
        
        return (
          <Alert
            isSuccess={alertState.isSuccess}
            isLoading={alertState.isLoading}
            onCloseCallback={resetAlertState}
            message={alertState.message}
          />
        );
    };
    
    return (
      <>
        { alertState.visible && showAlert() }
            
        <Button
          backgroundColor={theme.colors.lightBlue}
          onClick={handleGetConciliation}
          testId='conciliate-button'
        >
          CONCILIAR
        </Button>
      </>
    );
};

export default withTheme(ConciliateButton);
