
import downArrowWhite from 'assets/icons/seta-down-branca.svg';
import downArrowBlack from 'assets/icons/seta-down-preta.svg';
import styled from 'styled-components';
import {colorIsDark} from 'utils/util-colors';

export const Container = styled.button`
  width: ${props => props.width};
  height: ${props => props.height};
  border-radius: 4px;
  background-color: ${props => props.backgroundColor};
  border: ${props => props.border};
  color: ${props => props.color};
  font-weight: 600;
  font-size: 13px;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  line-height: 50%;
  position: relative;
`;

export const Icon = styled.span`
  ${props =>
    colorIsDark(props.backgroundColor)
      ? `background: url('${downArrowWhite}') no-repeat;`
      : `background: url('${downArrowBlack}') no-repeat;`};
  width: 5px;
  height: 5px;
  float: right;
  margin-right: 20px;
`;
