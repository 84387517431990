import styled from 'styled-components';

const ConciliationChartStyled = styled.div`
    display: flex;  
    flex: 0 0 100%; 
    flex-wrap: wrap;
    margin: 0 auto;
    height: 100%;
    width:100%;

@media (min-width: 1000px) {
    width:100%;
    max-width:1000px;
    margin:0 auto;
}

@media (min-width: 1200px) {
    width:100%;
    max-width:1200px;
    margin:0 auto;
}

@media (min-width: 1300px) {
    width:100%;
    max-width:1300px;
    margin:0 auto;
}

@media (min-width: 1400px) {
    width:100%;
    max-width:1400px;
    margin:0 auto;
}

@media (min-width: 1500px) {
    width:100%;
    max-width:1500px;
    margin:0 auto;
}

@media (min-width: 1600px) {
    width:100%;
    max-width:1600px;
    margin:0 auto;
}

@media (min-width: 1700px) {
    width:100%;
    max-width:1700px;
    margin:0 auto;
}

@media (min-width: 1800px) {
    width:100%;
    max-width:1800px;
    margin:0 auto;
}


 
  & > div.chartFiltersBox {
    display: flex;
    flex: 0 0 100%; 
    height: 30px;
    margin-bottom: 20px;
  }
  
`;

export const ConciliationChartTooltipStyled = styled.div`
    width: 400px;
    height: 137px;
    
    background-color: #FFF;
    border: solid 1px #CCCCCC;
    border-radius: 4px;
    border-color: #cccccc;
    padding: 20px;
    
    

    overflow-y: scroll;
    overflow-x: hidden;
    
    /* firefox works */
    scrollbar-color: #E0E0E0 #F9F9F9;
    scrollbar-width: thin;
  
    &::-webkit-scrollbar {
        width: 3.85px;
    }
    &::-webkit-scrollbar-track {
        background: #f9f9f9;
    }
    &::-webkit-scrollbar-thumb {
       background: #e0e0e0;
       border-radius: 4px;
    }
`;


export default ConciliationChartStyled;
