export const Types = {
  GET_LUCRO_LIQUIDO: '@Dashboard/LucroLiquido/GET_LUCRO_LIQUIDO',
  GET_LUCRO_LIQUIDO_SUCCESS:
    '@Dashboard/LucroLiquido/GET_LUCRO_LIQUIDO_SUCCESS',
  GET_LUCRO_LIQUIDO_ERROR: '@Dashboard/LucroLiquido/GET_LUCRO_LIQUIDO_ERROR',
};

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: '',
};

export default function lucroLiquido(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_LUCRO_LIQUIDO: {
      return { data: {}, loading: true, error: '' };
    }
    case Types.GET_LUCRO_LIQUIDO_SUCCESS: {
      return {
        data: action.data,
        loading: false,
        error: '',
      };
    }
    case Types.GET_LUCRO_LIQUIDO_ERROR: {
      return {
        data: {},
        loading: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
}
