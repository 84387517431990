import PropTypes from 'prop-types';
import React from 'react';

import {StyledCol} from './styles';

const Col = ({
  children,
  size,
  justifyContent,
  justifySelf,
  alignItems,
  alignSelf,
  padding,
  ...rest
}) => (
  <StyledCol
    size={size}
    justifyContent={justifyContent}
    justifySelf={justifySelf}
    alignItems={alignItems}
    alignSelf={alignSelf}
    padding={padding}
    {...rest}
  >
    {children}
  </StyledCol>
);

Col.propTypes = {
  children      : PropTypes.any,
  size          : PropTypes.number,
  justifyContent: PropTypes.string,
  justifySelf   : PropTypes.string,
  alignItems    : PropTypes.string,
  alignSelf     : PropTypes.string,
  padding       : PropTypes.string,
};

Col.defaultProps = {
  size          : 12,
  justifyContent: 'center',
  justifySelf   : 'unset',
  alignItems    : 'center',
  alignSelf     : 'unset',
  padding       : '10px',
  children      : undefined
};

export default Col;
