export const Types = {
    GET_SUBSIDIARIES: "subsidiaries/GET_SUBSIDIARIES",
    GET_SUBSIDIARIES_SUCCESS: "subsidiaries/GET_SUBSIDIARIES_SUCCESS",
    GET_SUBSIDIARIES_ERROR: "subsidiaries/GET_SUBSIDIARIES_ERROR",
   }
   
   const INITIAL_STATE = {
     data: [],
     loading: false,
     error: '',
   };
   
   export default function subsidiaries(state = INITIAL_STATE, action){
   
     switch(action.type){
   
       case Types.GET_SUBSIDIARIES: {
         return {data:[], loading:true, error:''}
       }
       
       case Types.GET_SUBSIDIARIES_SUCCESS: {
         return {data: action.data, loading:false, error:''}
       }
   
       case Types.GET_SUBSIDIARIES_ERROR: {
         return {loading:false, error:'Error', ...state}
       }
   
       default:
         return state;
     }
   
   }
  