/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import mastercard from 'assets/icons/cards/mastercard.svg';
import visa from 'assets/icons/cards/visa.svg';
import Text from 'components/Typografy/Text';
import React, { useEffect, useState } from 'react';
import { Arrow } from 'react-laag';
import theme from 'styles/theme';

import {ConciliationChartTooltipStyled} from './styles';


export const ConciliationChartTooltip = ({ active, payload, label }) => {
  const [isLoading, setIsLoading] = useState(true);
 
  const data = payload && payload[0]?.payload;
  
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(!isLoading);
    }, 100);
  }, []);

  const renderList = () => {
    return (
      <tr style={{ borderBottom: '1px dashed black;' }}>
        <td>
          <Text
            textAlign="center"
            color={theme.colors.black55}
            fontWeight={600}
          >
            {payload === null ? 0 : data?.netValueArchive?.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                style: 'currency',
                currency: 'BRL',
            })}
          </Text>
        </td>
        <td>
          <Text
            textAlign="center"
            color={theme.colors.black55}
            fontWeight={600}
          >
            {payload === null ? 0 : data?.netValueSystem?.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                style: 'currency',
                currency: 'BRL',
              })}
          </Text>
        </td>
        <td>
          <Text
            textAlign="center"
            color={theme.colors.orange}
            fontWeight={600}
          >
            {payload === null ? 0 : (data?.netValueArchive - data?.netValueSystem).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              style: 'currency',
              currency: 'BRL',
            })}
          </Text>
        </td>
        <td>
          <Text
            textAlign="center"
            color={theme.colors.lightBlue}
            fontWeight={600}
          >
            {payload === null ? 0 : data?.conciliated?.toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              style: 'currency',
              currency: 'BRL',
            })}
          </Text>
        </td>
      </tr>
      );
  };

  return isLoading === true ? (
    <div />
  ) : (
    <ConciliationChartTooltipStyled>
      <table width="100%">
        <thead>
          <tr>
            <th>
              <Text textAlign="center" color={theme.colors.black55}>
                Valor Arquivo
              </Text>
            </th>
            
            <th>
              <Text textAlign="center" color={theme.colors.black55}>
                Valor Sistema
              </Text>
            </th>
            
            <th>
              <Text textAlign="center" color={theme.colors.black55}>
                Diferença
              </Text>
            </th>
            <th>
              <Text textAlign="center" color={theme.colors.black55}>
                Conciliado
              </Text>
            </th>
          </tr>
        </thead>
        <tbody>{renderList()}</tbody>
      </table>
    </ConciliationChartTooltipStyled>
  );
};
