import styled from 'styled-components';


export const StyledContainer = styled.div`
  width: ${ props => props.width };
  /*max-width: ${ props => props.maxWidth };
  */
  height: ${ props => props.height };
  /*max-height: ${ props => props.maxHeight };
  */
  padding: ${ props => `${ props.paddingTop } ${ props.paddingRight } ${ props.paddingBottom } ${ props.paddingLeft }` };
  margin: ${ props => `${ props.marginTop } ${ props.marginRight } ${ props.marginBottom } ${ props.marginLeft }` };
`;