import styled from 'styled-components';

const TagFilterStyled = styled.div`
  display: flex;
  margin: 0 5px;
  color: #ffffff;
  background-color: #3ea2ff;
  position: relative;
  padding: 0 0.5rem;
  padding-left: 2rem;
  border-radius: 3px;
  justify-content: flex-start;
  align-items: center;

  & > button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    top: -3px;
    left: 0;
    padding: 0 0.7rem;
    color: #10718f;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    cursor: pointer;
    max-height: 100%;

    &:hover {
      top: -4px;
      transform: scale(1.3);
    }

    span {
      display: flex;
      font-size: 25px;
      font-weight: bold;
    }
  }

  & > p {
    display: flex;
    display: block;
    font-size: 13px;
  }
`;

export default TagFilterStyled;
