/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import PropTypes from 'prop-types';
import React, { useState, useCallback, useEffect } from 'react';

import FormContext from './FormContext';

export default function Form({ children, onSubmit }) {
  const [registeredFields, setRegisteredFields] = useState([]);

  const registerField = useCallback((name, ref) => {
    setRegisteredFields(oldRegisteredFields => [
      ...oldRegisteredFields,
      { name, value: ref },
    ]);
  });

  const handleSubmit = event => {
    event.preventDefault();
    const data = {};
    registeredFields.forEach(element => {
      const { value } = element.value;
      if (value) data[element.name] = value;
    });
    onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit} style={{ display: 'contents' }}>
      <FormContext.Provider value={{ registerField }}>
        {children}
      </FormContext.Provider>
    </form>
  );
}

Form.propTypes = {
  children: PropTypes.any,
  onSubmit: PropTypes.func,
};

Form.defaultProps = {
  onSubmit: () => {},
  children: undefined
};
