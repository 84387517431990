/* eslint-disable react/prop-types */
import TagFilter from 'components/Forms/TagFilter/index';
import ChartFiltersStyled from 'pages/Conciliation/components/ConciliationChart/ChartFilters/styles';
import React from 'react';

const ChartFilters = ({listFilters, onRemoveFilter}) => (

  <ChartFiltersStyled
    listFilters={listFilters}
    data-testid="chart-filter"
  >
      
    {listFilters && (
    <TagFilter
      data-testid="chart-filter-item"
      key={listFilters.value}
      element={listFilters.dtStart}
      tagKey="Periodo"
      tagValue={listFilters.dtStart}
      onRemoveFilter={onRemoveFilter}
    />
)} 
  </ChartFiltersStyled>
);



export default ChartFilters;
