/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
/* eslint-disable func-names */
/* eslint-disable no-empty */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import DreImage from 'assets/icons/dre.svg';
import fechamentoIcon from 'assets/icons/fechamento.svg';
import DataGrid from 'components/Datagrid';
import DatePicker from 'components/Forms/DatePicker';
import Col from 'components/Grid/Col';
import Row from 'components/Grid/Row';
import BackOfficeHeader from 'components/Layout/BackOfficeHeader';
import Text from 'components/Typografy/Text';
import moment from 'moment';
import checkIsNullOrUndefined from 'NullCheck';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Config from '../../assets/icons/config.svg';
import Lupa from '../../assets/icons/lupa.svg';
import Card from '../../components/Grid/Card';
import { getNeoXEndpoint, openNeoXLink } from '../../services/config';
import everestLocalStorage from '../../storage/everest-local-storage'
import { Types as ConferenciaDeCaixaTypes } from '../../store/ducks/Dashboard/Conferencia&Conciliacao/ConferenciaDeCaixa';
import { Types as ContaBancariaTypes } from '../../store/ducks/Dashboard/Conferencia&Conciliacao/ContaBancaria';
import { Types as VendaDeCartaoTypes } from '../../store/ducks/Dashboard/Conferencia&Conciliacao/VendaDeCartao';
import { Types as ObterContaTypes } from '../../store/ducks/Dashboard/LucroLiquido/ObterConta';
import { Types as ObterVisaoTypes } from '../../store/ducks/Dashboard/LucroLiquido/ObterVisao';
import { Types as LucroLiquidoTypes } from '../../store/ducks/Dashboard/LucroLiquido/Ranking';
import { Types as UpdateVisaoContaTypes } from '../../store/ducks/Dashboard/LucroLiquido/UpdateVisaoConta';
import { Types as VisaoDeContaTypes } from '../../store/ducks/Dashboard/LucroLiquido/VisaoDeConta';
import { Types as PendenciasFinanceirasTypes } from '../../store/ducks/Dashboard/PendenciasFinanceiras';
import { Types as ResultadosDreTypes } from '../../store/ducks/Dashboard/ResultadosDRE';
import { Types as SaldoDisponivelAntecipacaoTypes } from '../../store/ducks/Dashboard/SaldoDisponivelAntecipacao';
import theme from '../../styles/theme';
import BalanceAccountsCard from './BalanceAccountsCard';
import BankAccountConciliationStatusCard from './BankAccountConciliationStatusCard';
import CashConferenceStatusCard from './CashConferenceStatusCard';
import HomeDateContext from './context';
import CreditCardSalesConciliationStatusCard from './CreditCardSalesConciliationStatusCard';
import FinancialSummaryBox from './FinancialSummaryBox';
import Shortcut from './Shortcut';
import {
  BoldSpan,
  TitleSpan,
  HorizontalDivider,
  SectionDRE,
  SubContentDRE,
  ContentDRE,
  ImageDRE,
  TitleSpanDRE,
  ItemLista,
  ListDL,
  TextLink,
  MoreInfoLink,
  ContainerCard,
  StyledModal,
  StyledModalDre,
  StyledButton,
  StyledLabelVisao,
  StatusTitle
} from './styles';
// import { pendencias, saldo, mockDRE, mockDRE2, lucro, prejuizo } from './mock';

export default function Home() {
  const dispatch = useDispatch();
  const PendenciasFinanceirasData = useSelector(
    state => state.PendenciasFinanceirasReducer,
  );
  const SaldoDisponivelAntecipacaoData = useSelector(
    state => state.SaldoDisponivelAntecipacaoReducer,
  );
  const ResultadosDreData = useSelector(state => state.ResultadosDreReducer);
  const ObterVisaoData = useSelector(state => state.ObterVisaoReducer);
  const ObterContaData = useSelector(state => state.ObterContaReducer);
  const UpdateVisaoData = useSelector(state => state.UpdateVisaoContaReducer);
  const LucroLiquidoData = useSelector(state => state.LucroLiquidoReducer);
  const [isModalVisible, setModalVisible] = useState(false);
  const [isModalDREVisible, setModalDREVisible] = useState(false);
  const [visaoEncontrada, setVisaoEncontrada] = useState(false);
  const [visaoDeConta, setVisaoDeConta] = useState('Não encontrado');
  const [visaoDeContaDre, setVisaoDeContaDre] = useState('');
  const [contaTotalizadora, setContaTotalizadora] = useState('');
  const [date, setDate] = useState({
    startDate: moment().subtract(1, 'months'),
    endDate: moment(),
  });


  useEffect(() => {
    const visao =  everestLocalStorage.getItem('visaoDre')

    if(visao) {
      setVisaoDeContaDre({ id: visao.id, visao: visao.visao })
    }

    dispatch({
      type: ResultadosDreTypes.GET_RESULTADOS_DRE,
      payload: {
        date,
        idVisao: visao ? visao.id : null
      },
    });
    dispatch({
      type: VendaDeCartaoTypes.GET_VENDA_DE_CARTAO,
      payload: {
        date,
      },
    });
    dispatch({
      type: ConferenciaDeCaixaTypes.GET_CONFERENCIA_DE_CAIXA,
      payload: {
        date,
      },
    });
    dispatch({
      type: ContaBancariaTypes.GET_CONTA_BANCARIA,
      payload: {
        date,
      },
    });
  }, [date]);

  useEffect(() => {
    dispatch({
      type: PendenciasFinanceirasTypes.GET_PENDENCIAS_FINANCEIRAS,
    });
    dispatch({
      type: SaldoDisponivelAntecipacaoTypes.GET_SALDO_DISPONIVEL_ANTECIPACAO,
    });
    dispatch({
      type: VisaoDeContaTypes.GET_VISAO_DE_CONTA,
    });
    dispatch({
      type: ObterVisaoTypes.GET_OBTER_VISAO,
    });

    const visaoLucroLiquido =  everestLocalStorage.getItem('visaoLucroLiquido')
    const idContaLucroLiquido =  everestLocalStorage.getItem('idContaLucroLiquido')

    if(visaoLucroLiquido && idContaLucroLiquido){
      setVisaoDeConta(visaoLucroLiquido)
      setVisaoEncontrada(true);
      dispatch({
        type: LucroLiquidoTypes.GET_LUCRO_LIQUIDO,
        payload: {
          date,
          visao: visaoLucroLiquido.id,
          conta: idContaLucroLiquido
        },
      });
    
      dispatch({
        type: UpdateVisaoContaTypes.SET_VISAO,
        payload: {
          visao: visaoLucroLiquido.id,
          conta: idContaLucroLiquido,
        },
      });
    }
  }, []);


  function handleDatesChange(newDate) {
    setDate({ ...date, ...newDate });
  }


  function handleChangeVisaoConta(e) {
    e.preventDefault();
    const index = e.target.selectedIndex;
    setVisaoDeConta({ id: e.target.value, visao: e.target[index].text });
    dispatch({
      type: ObterContaTypes.GET_OBTER_CONTA,
      payload: {
        idFinVisao: e.target.value,
      },
    });
  }

  function handleChangeVisaoContaDre(e) {
    e.preventDefault();
    const index = e.target.selectedIndex;
    
    setVisaoDeContaDre({ id: e.target.value, visao: e.target[index].text });
  }

  function HandleApply() {
    setVisaoEncontrada(true);
    setModalVisible(!isModalVisible);
    dispatch({
      type: LucroLiquidoTypes.GET_LUCRO_LIQUIDO,
      payload: {
        date,
        visao: visaoDeConta.id,
        conta: contaTotalizadora
      },
    });
    // setTimeout(() => 
    dispatch({
      type: UpdateVisaoContaTypes.SET_VISAO,
      payload: {
        visao: visaoDeConta.id,
        conta: contaTotalizadora,
      },
    });
    // , 500);
    everestLocalStorage.setItem('visaoLucroLiquido', visaoDeConta)
    everestLocalStorage.setItem('idContaLucroLiquido', contaTotalizadora)
  }

  function HandleApplyDre() {
    setModalDREVisible(!isModalDREVisible);
    dispatch({
      type: ResultadosDreTypes.GET_RESULTADOS_DRE,
      payload: {
        date,
        idVisao: visaoDeContaDre.id,
      },
    });

    everestLocalStorage.setItem('visaoDre', visaoDeContaDre)
  }

  function resizeParent() {
    try {
      // var contentContainer = document.querySelector(".site-container");
      // var contentHeight = contentContainer.style["height"];
      const res = document.body.scrollHeight;// contentHeight.replace("px", "");
      let num = Number(res);
      num = num < 820 ? 820 : num;
      num = `${(num + 50).toString()  }px`;
      window.parent.postMessage("getLS", "*");
      window.parent.postMessage(num, "*");
    } catch { }
  }
  useEffect(() => {
    
    setTimeout(function () {
      resizeParent();
    }, 5000);
    window.addEventListener('resize', function (event) {
      resizeParent();
    }, true);
    window.document.getElementsByTagName("body")[0].addEventListener('DOMSubtreeModified', function () {
      resizeParent();
    });
  });

  let dreId = 0;
  let dreSubId = 0;
  function renderDRE(item) {
    return (
      <ListDL borderBottom="1px solid rgba(224, 224, 224, 1)" key={dreId++}>
        <ContentDRE>
          <ItemLista fontSize="13px">
            {item.Descricao}
          </ItemLista>
          <ItemLista
            color={
            item.Valor == 0
              ? '#828282'
              : item.Valor > 0
                ? '#6FCF97'
                : item.Valor == null
                  ? '#F2994A'
                  : '#EB5757'
          }
            fontSize="13px"
          >
            {item != null
            ? Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(
              item.Valor || 0,
            )
            : 'Não encontrado'}
          </ItemLista>
        </ContentDRE>
        {!!(item.Filhos || []).length && item.Filhos.map(renderDRESubitem)}
      </ListDL>
);
  }
  function renderDRESubitem(item) {
    return (
      <SubContentDRE key={dreSubId++}>
        <ItemLista marginTop="5px" fontSize="13px">
          {item.Descricao}
        </ItemLista>
        <ItemLista
          marginTop="5px"
          fontSize="13px"
          marginRight="20px"
          color={
          item.Valor == 0
            ? '#828282'
            : item.Valor >
              0
              ? '#6FCF97'
              : item.Valor == null
                ? '#F2994A'
                : '#EB5757'
        }
        >
          {item !=
          null
          ? Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(
            item.Valor || 0,
          )
          : 'Não encontrado'}
        </ItemLista>
      </SubContentDRE>
);
  }
  return (
    <div className="site-container">
      <BackOfficeHeader title="Visão Geral" />
      {/* <Row>
            <TitleSpan>Visão Geral - Linx UX</TitleSpan>
          </Row> */}
      <HorizontalDivider />
      <div style={{ margin: '30px' }}>
        <HomeDateContext.Provider value={date}>
          <Row>
            <Col size={2}>
              <BoldSpan>
                Resumo Financeiro
              </BoldSpan>
            </Col>
            <Col size={4} />
            <Col size={3} />
            <Col size={3}>
              <Row>
                <span>Período</span>
              </Row>
              <Row>
                <DatePicker
                  initialStartDate={date.startDate}
                  initialEndDate={date.endDate}
                  onDatesChange={handleDatesChange}
                />
              </Row>
            </Col>
          </Row>


          <Row>
            <Col size={8}>
              <FinancialSummaryBox />
            </Col>
            <Col size={4}>
              <Row>
                <Col size={12} padding="0px">
                  <BalanceAccountsCard />
                </Col>
              </Row>
              {/* <Row>
                <Col size="6">
                  <Shortcut
                    text="DRE"
                    image={DreImage}
                    url={`${URL_UX}#Linx-Administrativo/Financeiro/Consultas/DRE---Gerencial-New`}
                  />
                </Col>
                <Col size="6">
                  <Shortcut
                    text="Fechamento"
                    image={fechamentoIcon}
                    url={`${URL_UX}#Linx-Administrativo/Financeiro/Manutencao/Fechamento-do-Financeiro-New`}
                  />
                </Col>
              </Row> */}
            </Col>
          </Row>
          <br />
          <Row>
            {/* <ContainerCard justifyContent={'auto'} alingItems={'flex-start'}> */}
            <Col size={6}>
              <Card
                width="530px"
                minHeight="338px"
                boxShadow="0px 0px 5px RGBA(0,0,0,0.11)"
              >
                <SectionDRE>
                  <TitleSpanDRE>
                    {' '}
                    <ImageDRE src={DreImage} alt="DRE" />
                    <Text fontSize="21px" fontWeight={400} color="#828282">
                      Resultados (DRE)
                    </Text>
                    <button
                      style={{
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                        float: 'right',
                      }}
                      onClick={() => setModalDREVisible(!isModalDREVisible)}
                    >
                      <img src={Config} />
                    </button>
                    <StatusTitle isOk={false}>
                      Tipo Regime: Competência
                    </StatusTitle>
                    <StatusTitle isOk={false}>
                      {'Visão de conta: '} 
                      {' '}
                      {visaoDeContaDre ? visaoDeContaDre.visao : ''} 
                    </StatusTitle>
                  </TitleSpanDRE>
                  {ResultadosDreData.loading ? (
                    <div
                      style={{
                      width: '250px',
                      minHeight: '150px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      margin: '0 auto',
                    }}
                    >
                      <p
                        style={{
                        color: '#828282',
                        fontFamily: 'Open Sans',
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        fontSize: '14px',
                        lineHeight: '18px',
                        textAlign: 'center',
                        marginTop: '12px',
                        marginBottom: '16px',
                      }}
                      >
                        Carregando Informações...
                      </p>
                    </div>
                )
                  :
                  !!(ResultadosDreData.data || []).length && ResultadosDreData.data.map(renderDRE)}
                  <MoreInfoLink
                    href={getNeoXEndpoint(checkIsNullOrUndefined(localStorage.getItem('@UxTransPathEverest/DRE')) ?? `#Linx-Administrativo/Financeiro/Consultas/DRE---Gerencial-New`)}
                    onClick={openNeoXLink}
                  >
                    VER MAIS
                  </MoreInfoLink>
                </SectionDRE>
              </Card>
              {isModalDREVisible && (
              <StyledModalDre>
                <div>
                  <span>Alterar visão de contas</span>
                  <label>Selecione uma visão</label>
                  <select onChange={e => handleChangeVisaoContaDre(e)}>
                    <option value="" />
                    {ObterVisaoData.data.map(item => (
                      <option key={item.Id} value={item.Id}>
                        {item.Descricao}
                      </option>
                        ))}
                  </select>
                  <div
                    style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'flex-end',
                          marginTop: '21px',
                        }}
                  >
                    <div style={{ marginRight: '16px' }}>
                      <StyledButton
                        width="75px"
                        fontSize="10px"
                        color="#828282"
                        backgroundColor={theme.colors.white}
                        onClick={() => setModalDREVisible(!isModalDREVisible)}
                      >
                        CANCELAR
                      </StyledButton>
                    </div>
                    <StyledButton
                      fontSize="10px"
                      width="75px"
                      backgroundColor={theme.colors.focus}
                      onClick={() => HandleApplyDre()}
                    >
                      APLICAR
                    </StyledButton>
                  </div>
                </div>
              </StyledModalDre>
                )}
            </Col>
            <Col size={6}>
              <Row>
                <Card
                  width="530px"
                  height="auto"
                  padding="30px"
                  boxShadow="0px 0px 5px RGBA(0,0,0,0.11)"
                >
                  <Text fontSize="21px" fontWeight={400} color="#828282">
                    Pendências Financeiras
                  </Text>
                  {PendenciasFinanceirasData.loading ? (
                    <div
                      style={{
                        width: '250px',
                        height: '150px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        margin: '0 auto',
                      }}
                    >
                      <p
                        style={{
                          color: '#828282',
                          fontFamily: 'Open Sans',
                          fontStyle: 'normal',
                          fontWeight: 'normal',
                          fontSize: '14px',
                          lineHeight: '18px',
                          textAlign: 'center',
                          marginTop: '12px',
                          marginBottom: '16px',
                        }}
                      >
                        Carregando Informações...
                      </p>
                    </div>
                  )
                    : (
                      <DataGrid
                        width="100%"
                        height="auto"
                        style={{ marginTop: '5px', marginBottom: '0px' }}
                      >
                        <colgroup>
                          <col style={{ width: '50%' }} />
                          <col style={{ width: '25%' }} />
                          <col style={{ width: '25%' }} />
                        </colgroup>

                        <thead style={{ textAlign: 'left' }}>
                          <tr>
                            <th> </th>
                            <th>
                              A Pagar
                              {/* }
                          <MoreInfoLink
                            href={getNeoXEndpoint(`#Linx-Administrativo/Financeiro//WorkArea---Contas-a-Pagar`)}
                            onClick={openNeoXLink}
                            style={{fontWeight:"normal"}}
                          >
                          &nbsp;(ver mais)
                          </MoreInfoLink> { */}

                            </th>
                            <th>
                              A Receber
                              {/* }                  
                          <MoreInfoLink
                            href={getNeoXEndpoint(`#Linx-Administrativo/Financeiro//WorkArea---Contas-a-Receber`)}
                            onClick={openNeoXLink}
                            style={{fontWeight:"normal"}}
                          >
                          &nbsp;(ver mais)
                          </MoreInfoLink>
                          </MoreInfoLink> { */}
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {PendenciasFinanceirasData &&
                          PendenciasFinanceirasData.data.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <Text
                                  fontSize="13px"
                                  fontWeight={400}
                                  color="#5B2E90"
                                >
                                  {item.Data}
                                </Text>
                              </td>
                              <td style={{ textAlign: 'left' }}>
                                <Text
                                  fontSize="13px"
                                  fontWeight={400}
                                  color={item.APagar == 0 ? '#828282' : '#EB5757'}
                                >
                                  {Intl.NumberFormat('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL',
                                  }).format(item.APagar || 0)}
                                </Text>
                              </td>
                              <td style={{ textAlign: 'left' }}>
                                <Text
                                  fontSize="13px"
                                  fontWeight={400}
                                  color={
                                    item.AReceber == 0 ? '#828282' : '#6FCF97'
                                  }
                                >
                                  {Intl.NumberFormat('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL',
                                  }).format(item.AReceber || 0)}
                                </Text>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </DataGrid>
                  )}

                </Card>
              </Row>
              <Row>
                <Card
                  width="530px"
                  height="auto"
                  marginTop="15px"
                  padding="30px"
                  boxShadow="0px 0px 5px RGBA(0,0,0,0.11)"
                >
                  <Text fontSize="21px" fontWeight={400} color="#828282">
                    Saldo disponível para antecipação
                  </Text>
                  <DataGrid
                    width="100%"
                    height="auto"
                    style={{ marginTop: '5px', marginBottom: '0px' }}
                  >
                    <colgroup>
                      <col style={{ width: '80%' }} />
                      <col style={{ width: '20%' }} />
                    </colgroup>

                    <tbody>
                      <tr>
                        <td>
                          <Text
                            fontSize="13px"
                            fontWeight={400}
                            color="#828282"
                          >
                            Cartão
                          </Text>
                        </td>
                        <td
                          style={{
                            textAlign: 'left',
                          }}
                        >
                          <Text
                            fontSize="13px"
                            fontWeight={400}
                            color="#828282"
                          >
                            {Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            }).format(
                              SaldoDisponivelAntecipacaoData.data
                                .CartaoCredito || 0,
                            )}
                          </Text>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Text
                            fontSize="13px"
                            fontWeight={400}
                            color="#828282"
                          >
                            Boleto
                          </Text>
                        </td>
                        <td
                          style={{
                            textAlign: 'left',
                          }}
                        >
                          <Text
                            fontSize="13px"
                            fontWeight={400}
                            color="#828282"
                          >
                            {Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            }).format(
                              SaldoDisponivelAntecipacaoData.data.Boleto || 0,
                            )}
                          </Text>
                        </td>
                      </tr>
                    </tbody>
                  </DataGrid>
                </Card>
              </Row>
            </Col>
          </Row>

          {/* <Row>
            <Col size="3" justifyContent="flex-start">
              <BoldSpan>Conferência & Conciliação</BoldSpan>
            </Col>
            <Col size="3" />
            <Col size="3" />
            <Col size="3">
              <Row>
                <span>Período</span>
              </Row>
              <Row>
                <DatePicker
                  initialStartDate={date.startDate}
                  initialEndDate={date.endDate}
                  onSelectedStartDate={handleStartDateChange}
                  onSelectedEndDate={handleEndDateChange}
                />
              </Row>
            </Col>
          </Row> */}
          <ContainerCard padding="24px 10px 30px 10px">
            <div>
              <CashConferenceStatusCard />
            </div>
            <div>
              <CreditCardSalesConciliationStatusCard />
            </div>
            <div>
              <BankAccountConciliationStatusCard />
            </div>
          </ContainerCard>
          <Row>
            <Col size={12} justifyContent="flex-start">
              <BoldSpan>Lucro Líquido</BoldSpan>
            </Col>
          </Row>
          <Row>
            <Col size={8}>
              <Row>
                <Card
                  width="100%"
                  height="auto"
                  padding="30px"
                  boxShadow="0px 0px 5px RGBA(0,0,0,0.11)"
                >
                  <div
                    style={{
                      justifyContent: 'space-between',
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <Text fontSize="21px" fontWeight={400} color="#828282">
                        Ranking de lojas com maior lucro líquido/Prejuízo
                      </Text>
                      <StyledLabelVisao>
                        Visão de contas: 
                        {' '}
                        {visaoDeConta.visao}
                      </StyledLabelVisao>
                    </div>
                    <button
                      style={{
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                      }}
                      onClick={() => setModalVisible(!isModalVisible)}
                    >
                      <img src={Config} />
                    </button>
                  </div>
                  {visaoEncontrada ? (
                    (LucroLiquidoData.loading)
                      ? (
                        <div
                          style={{
                          width: '250px',
                          height: '300px',
                          justifyContent: 'center',
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'column',
                          margin: '0 auto',
                        }}
                        >
                          <p
                            style={{
                            color: '#828282',
                            fontFamily: 'Open Sans',
                            fontStyle: 'normal',
                            fontWeight: 'normal',
                            fontSize: '14px',
                            lineHeight: '18px',
                            textAlign: 'center',
                            marginTop: '12px',
                            marginBottom: '16px',
                          }}
                          >
                            Carregando Informações...
                          </p>
                        </div>
                    )
                      :
                      (!LucroLiquidoData.loading && !(LucroLiquidoData.data.Prejuizo || []).length && !(LucroLiquidoData.data.Lucro || []).length)
                        ? (
                          <div
                            style={{
                            width: '250px',
                            height: '300px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            margin: '0 auto',
                          }}
                          >
                            <p
                              style={{
                              color: '#828282',
                              fontFamily: 'Open Sans',
                              fontStyle: 'normal',
                              fontWeight: 'normal',
                              fontSize: '14px',
                              lineHeight: '18px',
                              textAlign: 'center',
                              marginTop: '12px',
                              marginBottom: '16px',
                            }}
                            >
                              Não existem dados para o período informado
                            </p>
                          </div>
                      )
                        : (
                          <>
                            <DataGrid
                              width="100%"
                              height="auto"
                              style={{ marginTop: '35px', marginBottom: '0px' }}
                            >
                              <colgroup>
                                <col style={{ width: '70%' }} />
                                <col style={{ width: '15%' }} />
                                <col style={{ width: '15%' }} />
                              </colgroup>

                              <thead
                                style={{
                                textAlign: 'left',
                              }}
                              >
                                <tr>
                                  <th
                                    style={{
                                    fontWeight: '700',
                                    color: '#828282',
                                  }}
                                  >
                                    Lucro
                                  </th>
                                  <th />
                                  <th />
                                </tr>
                              </thead>

                              <tbody>
                                {(LucroLiquidoData.data.Lucro || []).map(item => (
                                  <tr key={item.Id}>
                                    <td>
                                      <Text
                                        style={{
                                        letterSpacing: '0.3px',
                                        lineHeight: '10px',
                                      }}
                                        fontSize="13px"
                                        fontWeight={300}
                                        color="#828282"
                                      >
                                        {item.Loja}
                                      </Text>
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                      <Text
                                        fontSize="13px"
                                        fontWeight={400}
                                        color="#828282"
                                      >



                                        {Intl.NumberFormat('pt-BR', {
                                        style: 'percent',
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                      }).format(Number((item.Porcentagem) || 0) / 100)}
                                      </Text>
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                      <Text
                                        fontSize="13px"
                                        fontWeight={400}
                                        color={
                                        item.Valor == 0 ? '#828282' : '#59B47E'
                                      }
                                      >
                                        {Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                      }).format(Number(item.Valor) || 0)}
                                      </Text>
                                    </td>
                                  </tr>
                              ))}
                              </tbody>
                            </DataGrid>
                            <DataGrid
                              width="100%"
                              height="auto"
                              style={{ marginTop: '35px', marginBottom: '0px' }}
                            >
                              <colgroup>
                                <col style={{ width: '70%' }} />
                                <col style={{ width: '15%' }} />
                                <col style={{ width: '15%' }} />
                              </colgroup>

                              <thead style={{ textAlign: 'left' }}>
                                <tr>
                                  <th
                                    style={{
                                    fontWeight: '700',
                                    color: '#828282',
                                  }}
                                  >
                                    Prejuizo
                                  </th>
                                  <th />
                                  <th />
                                </tr>
                              </thead>

                              <tbody>
                                {(LucroLiquidoData.data.Prejuizo || []).map(item => (
                                  <tr key={item.Id}>
                                    <td>
                                      <Text
                                        fontSize="13px"
                                        fontWeight={300}
                                        color="#828282"
                                      >
                                        {item.Loja}
                                      </Text>
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                      <Text
                                        fontSize="13px"
                                        fontWeight={400}
                                        color="#828282"
                                      >

                                        {Intl.NumberFormat('pt-BR', {
                                        style: 'percent',
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                      }).format(Number((item.Porcentagem) || 0) / 100)}
                                      </Text>
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                      <Text
                                        fontSize="13px"
                                        fontWeight={400}
                                        color={
                                        item.Valor == 0 ? '#828282' : '#EB5757'
                                      }
                                      >
                                        {Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                      }).format(Number(item.Valor) || 0)}
                                      </Text>
                                    </td>
                                  </tr>
                              ))}
                              </tbody>
                            </DataGrid>
                          </>
                      )
                  ) : (
                    <div
                      style={{
                        width: '250px',
                        height: '300px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        margin: '0 auto',
                      }}
                    >
                      <img src={Lupa} />
                      <p
                        style={{
                          color: '#828282',
                          fontFamily: 'Open Sans',
                          fontStyle: 'normal',
                          fontWeight: 'normal',
                          fontSize: '14px',
                          lineHeight: '18px',
                          textAlign: 'center',
                          marginTop: '12px',
                          marginBottom: '16px',
                        }}
                      >
                        Selecione uma visão de contas para visualizar esta
                        informação
                      </p>
                      <StyledButton
                        width="145px"
                        fontSize="11px"
                        border="1px solid #3EA2FF"
                        color="#3EA2FF"
                        backgroundColor={theme.colors.white}
                        onClick={() => setModalVisible(!isModalVisible)}
                      >
                        SELECIONAR AGORA
                      </StyledButton>
                    </div>
                  )}
                  {/* <Row>
                    <Col
                      size="4"
                      justifyContent="left"
                      padding="5px 5px 0px 5px"
                    >
                      <MoreInfoLink>VER MAIS</MoreInfoLink>
                    </Col>
                  </Row> */}
                </Card>
                {isModalVisible && (
                  <StyledModal>
                    <div>
                      <span>Alterar visão de contas</span>
                      <label>Selecione uma visão</label>
                      <select onChange={e => handleChangeVisaoConta(e)}>
                        <option value="" />
                        {ObterVisaoData.data.map(item => (
                          <option key={item.Id} value={item.Id}>
                            {item.Descricao}
                          </option>
                        ))}
                      </select>
                      <label style={{ marginTop: '12px' }}>
                        Selecione a conta totalizadora
                      </label>
                      <select
                        onChange={e => setContaTotalizadora(e.target.value)}
                      >
                        <option value="" />
                        {ObterContaData.data.map(item => (
                          <option key={item.Id} value={item.Id}>
                            {item.Descricao}
                          </option>
                        ))}
                      </select>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'flex-end',
                          marginTop: '21px',
                        }}
                      >
                        <div style={{ marginRight: '16px' }}>
                          <StyledButton
                            width="75px"
                            fontSize="10px"
                            color="#828282"
                            backgroundColor={theme.colors.white}
                            onClick={() => setModalVisible(!isModalVisible)}
                          >
                            CANCELAR
                          </StyledButton>
                        </div>
                        <StyledButton
                          fontSize="10px"
                          width="75px"
                          backgroundColor={theme.colors.focus}
                          onClick={() => HandleApply()}
                        >
                          APLICAR
                        </StyledButton>
                      </div>
                    </div>
                  </StyledModal>
                )}
              </Row>
            </Col>
          </Row>

        </HomeDateContext.Provider>
      </div>
    </div>
  );
}

