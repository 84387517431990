import styled from 'styled-components';


export const StyledTable = styled.table`
  flex: 1;
  border-spacing: 0;
  box-shadow: 0px 0px 4px 0.1px rgba(0, 0, 0, 0.2);

  tbody > tr > td {
    border-bottom: 1px solid #dee2e6;
    padding: 15px;
    font-size: 13px;
    color: ${ props => props.theme.colors.black55 };
    max-width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  thead th {
    font-weight: 500;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    padding: 15px;
    font-size: 13px;
    text-align: left;
    color: ${ props => props.theme.colors.black20 };
  }
`;
