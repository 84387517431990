import { StyledTableContainer } from 'components/Datagrid/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { StyledTable } from './styles';

function DataGrid({ children, width, height, color, ...rest }) {
  return (
    <StyledTableContainer width={width} height={height} {...rest}>
      <StyledTable>{children}</StyledTable>
    </StyledTableContainer>
  );
}

DataGrid.propTypes = {
  children: PropTypes.any,
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

DataGrid.defaultProps = {
  width: '100%',
  height: '450px',
  color: 'black',
  children: undefined
};

export default DataGrid;
