/* eslint-disable no-unused-vars */
import { call, put } from 'redux-saga/effects';

import api from '../../../../services/api';
import { Types as SaldoDisponivelAntecipacaoTypes } from '../../../ducks/Dashboard/SaldoDisponivelAntecipacao';

export function* getSaldoDisponivelAntecipacao(action) {
  try {
    const response = yield call(
      api.get,
      'Home/ObterSaldoDisponivelAntecipacao',
    );

    if (response.status === 200) {
      yield put({
        type:
          SaldoDisponivelAntecipacaoTypes.GET_SALDO_DISPONIVEL_ANTECIPACAO_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({
      type:
        SaldoDisponivelAntecipacaoTypes.GET_SALDO_DISPONIVEL_ANTECIPACAO_ERROR,
      error,
    });
  }
}
