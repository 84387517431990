/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import ptbrLocale from 'moment/locale/pt-br';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import {DateRangePicker} from 'react-dates';

import { StyledWrapper } from './styles';

const DatePicker = ({
  name,
  register,
  testId,
  initialStartDate,
  initialEndDate,
  maximumDays,
  onDatesChange
}) => {
  moment.updateLocale('pt-br', {
    weekdaysMin: 'Dom_Seg_Ter_Qua_Qui_Sex_Sáb'.split('_'),
  });
  moment.updateLocale('pt-br', ptbrLocale);

  const [startDate, setStartDate] = useState(initialStartDate || moment());
  const [endDate, setEndDate] = useState(initialEndDate || moment());
  const [lastStartDate, setLastStartDate] = useState(initialStartDate || moment());
  const [lastEndDate, setLastEndDate] = useState(initialEndDate || moment());

  const [focusedInput, setFocusedInput] = useState(null);

  const startDateRef = useRef();
  const endDateRef = useRef();


  const handleDatesChange = ({ startDate, endDate }) => {
    if (startDate)
      setLastStartDate(startDate);

    if (endDate)
      setLastEndDate(endDate);

    setStartDate(startDate);
    setEndDate(endDate);
  };
  const onClose = (final) => {
    if (!final.startDate && !!lastStartDate) {
      setStartDate(lastStartDate);
    }

    if (!final.endDate && !!lastEndDate) {
      setEndDate(lastEndDate);
    }

    onDatesChange(final);
  }

  const outsideRange = day => {
    if (maximumDays < 0) {
      if (
        day.isAfter(startDate.clone().add(maximumDays, 'days')) &&
        day.isBefore(endDate.clone().subtract(maximumDays, 'days'))
      ) {
        const endDT = startDate.clone().add(maximumDays, 'days');
        setEndDate(endDT);
      }
      return (
        day.isAfter(startDate.clone().add(maximumDays, 'days')) &&
        day.isBefore(endDate.clone().subtract(maximumDays, 'days'))
      );
    }
    return false;
  };



  return (
    <>
      <input type='hidden' name="initialDate" ref={register} value={startDate?.format('YYYY-MM-DD')} readOnly />
      <input type='hidden' name="finalDate" ref={register} value={endDate?.format('YYYY-MM-DD')} readOnly />
  
      <input style={{display: 'none'}} ref={startDateRef} value={startDate?.format('YYYY-MM-DD')} readOnly />
      <input style={{display: 'none'}} ref={endDateRef} value={endDate?.format('YYYY-MM-DD')} readOnly />

      <StyledWrapper>
        <DateRangePicker
          startDate={startDate}
          startDateId="tata-start-date"
          endDate={endDate}
          endDateId="tata-end-date"
          onDatesChange={handleDatesChange}
          focusedInput={focusedInput}
          onFocusChange={focusedInput => setFocusedInput(focusedInput)}
          displayFormat={() => 'DD/MM/YYYY'}
          isOutsideRange={outsideRange}
          numberOfMonths={2}
          inputIconPosition="after"
          showDefaultInputIcon
          startDatePlaceholderText="Data Inicial"
          endDatePlaceholderText="Data Final"
          onClose={onClose}
          hideKeyboardShortcutsPanel
        />
      </StyledWrapper>
    </>
  );
};

DatePicker.propTypes = {
  name: PropTypes.any,
  register: PropTypes.any,
  testId: PropTypes.any,
  children: PropTypes.any,
  maximumDays: PropTypes.number
};

DatePicker.defaultProps = {
  maximumDays: 0,
  name: undefined,
  register: undefined,
  testId: undefined,
  children: undefined
};

export default DatePicker;
