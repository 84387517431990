import styled from 'styled-components';


export const TooltipTitle = styled.span`
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 300;
  color: #828282;
`;

export const TooltipItemKey = styled.span`
  font-family: Open Sans;
  font-weight: 500;
  color: #828282;
  font-size: 14px;
`;

export const TooltipItemValue = styled.span`
  font-family: Open Sans;
  font-weight: 700;
  font-size: 14px;
  color: ${ props => props.color };
`;

export const BackgroundDiv = styled.div`
  border-radius: 8px;
  background-color: #ffffff;
  margin: 30px;
  border: 1px solid #e5e5e5;
  width: 250px;
`;