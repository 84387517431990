import Button from 'components/Forms/Button/index';
import DatePicker from 'components/Forms/DatePicker';
import Col from 'components/Grid/Col';
import Row from 'components/Grid/Row';
import Text from 'components/Typografy/Text';
import moment from 'moment';
import AcquirersDropDown from 'pages/Conciliation/components/ConciliationCard/Filtering/AcquirersDropDown';
import CardBrandsDropDown from 'pages/Conciliation/components/ConciliationCard/Filtering/CardBrandsDropDown';
import SubsidiariesDropDown from 'pages/Conciliation/components/ConciliationCard/Filtering/SubsidiariesDropDown';
import ConciliationContext from 'pages/Conciliation/ConciliationContext';
import React, {useContext} from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {Types as FiltersBarsCards} from 'store/ducks/Conciliation/FiltersBars';
import {Types as FiltersChart} from 'store/ducks/Conciliation/FiltersChart';
import theme from 'styles/theme';


const FiltersBars = () => {
    
    const { register,  getValues } = useForm();
    
    const [startDate, setStartDate] = React.useState(moment());
    const [finalDate, setFinalDate] = React.useState(moment().subtract(1, 'M'));
    const {setData, setAcquirerSelectedValue, setSystemSelectedValue} = useContext(ConciliationContext);
    
    const dispatch = useDispatch();
    
    const onSubmit = data => {

        dispatch({
          type: FiltersBarsCards.GET_FILTERSBARS,
          payload: data
        });

        dispatch({ 
          type: FiltersChart.GET_FILTERSBARS_CHART, 
          payload: data 
        });
        
        setData(data)
        setAcquirerSelectedValue(0.0)
        setSystemSelectedValue(0.0)
    };
    
    return (
      <Row>
                
        <Col size={2} justifyContent='flex-start'>
          <Text fontWeight={600}>Período</Text>
          <DatePicker
            testId='date-picker'
            initialStartDate={startDate}
            initialEndDate={finalDate}
            onSelectedStartDate={date => setStartDate(date)}
            onSelectedEndDate={date => setFinalDate(date)}
            register={register}
            maximumDays={40}
          />
        </Col>
                
        <Col size={2} justifyContent='flex-start'>
          <CardBrandsDropDown register={register} />
        </Col>
        <Col size={2} justifyContent='flex-start'>
          <AcquirersDropDown register={register} />
        </Col>
        <Col size={2} justifyContent='flex-start'>
          <SubsidiariesDropDown register={register} />
        </Col>
        <Col size={2} justifyContent='flex-start' padding='26px 0 0 0'>
          <Button
            name="filter"
            type="submit"
            testId="conciliation-filter-button"
            outline
            backgroundColor={theme.colors.black55}
            onClick={() => onSubmit(getValues())}
          >
            FILTRAR
          </Button>
        </Col>
      </Row>
    );
};

export default FiltersBars;
