import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

    * {
      margin: 0;
      padding: 0;
      outline: 0;
      box-sizing: border-box;
      
      scrollbar-color: #E0E0E0 #F9F9F9;
      scrollbar-width: thin;
      -moz-outline-style: none;
    }

    *::-webkit-scrollbar {
      width: 8px;
      height: 5px;
    }

    *::-webkit-scrollbar-track {
      background: #f9f9f9;
    }

    *::-webkit-scrollbar-thumb {
      background: #ccc;
      border-radius: 8px;
    }

    html, body, #root {
      min-height: 100%;
    }

    body {
      font-family: 'Open Sans', sans-serif;
      font-size: 16px;
      -webkit-font-smooth: always;
      -webkit-font-smoothing: antialiased !important;
    }

    section.chartBoxs {
      display: flex;
      flex: 0 0 100%;
      height: 300px;
      margin: 20px 0;
      padding: 10px;
    }
`;
