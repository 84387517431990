import { call, put } from 'redux-saga/effects';

import api from '../../../../../services/api';
import { Types as LucroLiquidoTypes } from '../../../../ducks/Dashboard/LucroLiquido/Ranking';

export function* getLucroLiquido(action) {
  try {
    const { date, visao, conta } = action.payload;
    const { startDate, endDate } = date;
    if (!date || !visao) return;

    const response = yield call(
      api.get,
      `Home/ObterLucroLiquidoDashboard?dataInicial=${startDate.format('Y-MM-DD',)}&dataFinal=${endDate.format('Y-MM-DD')}&idVisao=${visao}&idConta=${conta}`,
    );
    if (response.status === 200) {
      yield put({
        type: LucroLiquidoTypes.GET_LUCRO_LIQUIDO_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: LucroLiquidoTypes.GET_LUCRO_LIQUIDO_ERROR,
      error,
    });
  }
}
