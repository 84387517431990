export const Types = {
  GET_CONFERENCIA_DE_CAIXA:
    '@Dashboard/Conferencia&Conciliacao/ConferenciaDeCaixa/GET_CONFERENCIA_DE_CAIXA',
  GET_CONFERENCIA_DE_CAIXA_SUCCESS:
    '@Dashboard/Conferencia&Conciliacao/ConferenciaDeCaixa/GET_CONFERENCIA_DE_CAIXA_SUCCESS',
  GET_CONFERENCIA_DE_CAIXA_ERROR:
    '@Dashboard/Conferencia&Conciliacao/ConferenciaDeCaixa/GET_CONFERENCIA_DE_CAIXA_ERROR',
};

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: '',
};

export default function conferenciaDeCaixa(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_CONFERENCIA_DE_CAIXA: {
      return { data: {}, loading: true, error: '' };
    }
    case Types.GET_CONFERENCIA_DE_CAIXA_SUCCESS: {
      return {
        data: action.data,
        loading: false,
        error: '',
      };
    }
    case Types.GET_CONFERENCIA_DE_CAIXA_ERROR: {
      return {
        data: {},
        loading: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
}
