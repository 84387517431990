/* eslint-disable react-hooks/exhaustive-deps */

import chartIcon from 'assets/icons/chart.svg';
import Card from 'components/Grid/Card';
import {Scroll} from 'components/Scroll/index';
import SkeletonAcquirerList from 'components/Skeleton/SkeletonAcquirerList';
import ConciliationCard from 'pages/Conciliation/components/ConciliationCard/index';
import ConciliationContext from 'pages/Conciliation/ConciliationContext';
import React, {useCallback, useContext, useEffect} from 'react';
import {useSelector} from "react-redux";


export default function AcquirerList() {

  const dataAcquirers = useSelector(state => state.FiltersBarsCardsReducer);
  const {
    acquirerSalesIds, 
    setAcquirerSalesIds, 
    selectedCards, 
    setSelectedCards, 
    setAcquirerSelectedValue, 
    data 
  } = useContext(ConciliationContext);
  

  const handleClick = useCallback((id) =>{

    const include = selectedCards.some(x => x.id === id);

    const cardValue = dataAcquirers.cardAcquirers.records.find(x => x.id === id);

    if (include) {
      setSelectedCards(selectedCards.filter(x => x.id !== id));  
      
      setAcquirerSalesIds(acquirerSalesIds.filter(x => x !== id));
    } else {
      setSelectedCards([...selectedCards, cardValue]);

      setAcquirerSalesIds([id, ...acquirerSalesIds]);
    }
  }) 

  useEffect(() => {
    const value = 0;
    setSelectedCards([]);
    setAcquirerSalesIds([]);
    setAcquirerSelectedValue(value);
  }, [data])
  
  useEffect(() => {

    const value = selectedCards.reduce(
      (sum, x) =>
        (Number.parseFloat(sum) + Number.parseFloat(x.grossValue)).toFixed(2),
      0,
    );
    
    setAcquirerSelectedValue(value);
  }, [selectedCards, setAcquirerSelectedValue])


  if(dataAcquirers.acquirersLength <=0 || dataAcquirers.loading) 
  return (
    <>
      {dataAcquirers.loading ? (
        <>  
          <Card width="100%">
            <Loader />
          </Card>
          <Card width="100%">
            <Loader />
          </Card>
          <Card width="100%">
            <Loader />
          </Card>
        </>
      ) :
        <NoResults />}
    </>
  );

  return (
    <>
      <Scroll>
        {dataAcquirers.cardAcquirers.records 
            && dataAcquirers.cardAcquirers.records.map(itemCard => (
              <ConciliationCard
                data-testid="test-card"
                name={itemCard.subsidiaryName}
                isSelected={selectedCards.some(sel => sel.id === itemCard.id)}
                key={itemCard.id}
                date={itemCard.dateOfSale}
                value={itemCard.id}
                grossValue={itemCard.grossValue}
                nsu={itemCard.nsu}
                authorizationCode={itemCard.authorizationId}
                idBandeira={itemCard.idBandeiraRede}
                onClick={handleClick}
                
              />
        ))}
      </Scroll>
    </>
  );
}

export const Loader = () => (
  <SkeletonAcquirerList /> 
);

export const NoResults = () => {
  return (
    <div
      style={{
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '45px',
        width: '40%',
      }}
    >
      <img src={chartIcon} alt="chartIcon" />
    </div>
  )
};
