import Row from 'components/Grid/Row';
import styled from 'styled-components';


export const CardTitle = styled.span`
  color: #828282;
  font-size: 21px;
  font-family: Open Sans;
  width: 100%;
  display: block;
`;

export const StatusTitle = styled.span`
  color: ${props => (props.isOk ? '#6FCF97' : '#F2994A')};
  font-size: 14px;
  font-family: Open Sans;
  width: 100%;
  display: block;
`;

export const ItemKey = styled.span`
  color: #828282;
  font-size: 13px;
  font-family: Open Sans;
`;

export const ItemValue = styled.span`
  color: #828282;
  font-size: 13px;
  font-family: Open Sans;
  font-weight: 600;
`;

export const MarginRow = styled(Row)`
  margin-top: 20px;
`;

export const MoreInfoLink = styled.a`
  text-decoration: none;
  color: #828282;
  font-weight: bold;
  font-size: 13px;
  font-family: Open Sans;
  cursor: pointer;
`;
