/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react-hooks/exhaustive-deps */

 import chevronDown from 'assets/icons/chevron-down.svg';
import Label from 'components/Forms/Label';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import theme from 'styles/theme';

import DropdownContext from './DropdownContext';
import {StyledDropdown, StyledDropdownContainer, StyledSelectedItem, StyledDropdownIcon} from './styles';
import './styles.css'

const Dropdown = ({
  name,
  register,
  testId,
  
  onChange,
  
  multiple,
  children,
}) => {
  
  const [selectedItems, setSelectedItems] = useState([]);
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });
  const [isOpen, setIsOpen] = useState(false);


  
  const handleClick = (e, { label, value }) => {
    const item = { label, value };

    if (multiple) {
      e.stopPropagation();
      
      const items = selectedItems.some(element => element.value === value)
          ? selectedItems.filter(x => x.value !== value && x !== 0)
          : [...selectedItems, item];
    
      setSelectedItems(items);
    } else {
      setSelectedItems([item]);
    }


    setIsOpen(true);
  };

  useEffect(() => {
    if (selectedItems.length){
      onChange(selectedItems[0].value);
    }
  }, [selectedItems]);

  const toggle = e => {
    setCoordinates({ y: e.currentTarget.offsetHeight - 4, x: 0 });

    setIsOpen(!isOpen);
  };

  return (
    <>
      <select name={name} ref={register} style={{display: 'none'}} multiple={multiple}>
        {selectedItems.map(({ label, value }) => (
          <>
            <option key={value} value={value} selected="selected"> 
              {' '}
              {label}
              {' '}
            </option>
          </>
        ))}
      </select>
      
      <DropdownContext.Provider
        value={{
          onClick: handleClick,
          multiple,
          selectedItems,
        }}
      >
        <OutsideClickHandler
          display="contents"
          onOutsideClick={() => setIsOpen(!isOpen)}
          disabled={!isOpen}
        >
          <StyledDropdown onClick={toggle} data-testid={testId}>
            <StyledSelectedItem contenteditable data-placeholder="Todos">
                  
              {!multiple && selectedItems.length > 0 ? <span>{selectedItems[0].label}</span> : null }
               
              {multiple && selectedItems.some(x => x.value === 0) && (
              <Label color={theme.colors.white} bgColor={theme.colors.lightBlue} width="100%">
                {selectedItems[0].label}
              </Label>
                )}
                
              {multiple &&
                  selectedItems.length > 0 &&
                  selectedItems.length < 2 &&
                  !selectedItems.some(x => x.value === 0) && (
                  <Label color={theme.colors.white} bgColor={theme.colors.lightBlue} width="100%">
                    {selectedItems[0].label}
                  </Label>
                )}
                
              {multiple &&
                  selectedItems.length === 2 &&
                  !selectedItems.some(x => x.value === 0) &&
                  selectedItems.map(({ label, value }) => (
                    <Label
                      key={value}
                      color={theme.colors.white}
                      bgColor={theme.colors.lightBlue}
                      width="50%"
                    >
                      {label}
                    </Label>
                ))}
                
              {multiple &&
                  selectedItems.length > 2 &&
                  !selectedItems.some(x => x.value === 0) && (
                    <div style={{
                      zIndex: '1000',
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                    >
                      <Label color={theme.colors.white} bgColor={theme.colors.lightBlue} width="40%">
                        {selectedItems[0].label}
                      </Label>
                      <Label color={theme.colors.white} bgColor={theme.colors.lightBlue} width="40%">
                        {selectedItems[1].label}
                      </Label>
                      <Label color={theme.colors.white} bgColor={theme.colors.lightBlue} width="20%">
                        {`+${selectedItems.length - 2}`}
                      </Label>
                    </div>
                )}
                
            </StyledSelectedItem>
              
            <StyledDropdownIcon>
              <img alt="icon" src={chevronDown} />
            </StyledDropdownIcon>
              
            {isOpen ? (
              <StyledDropdownContainer coordinates={coordinates}>
                {children}
              </StyledDropdownContainer>
              ) : <null />}
              
            {/* renderização de um select nativo para melhor controle em forms */}
              
          </StyledDropdown>
        </OutsideClickHandler>
      </DropdownContext.Provider>
      
    </>
  );
};

Dropdown.propTypes = {
  name    : PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  testId  : PropTypes.string.isRequired,
  
  onChange: PropTypes.func.isRequired,

  multiple: PropTypes.bool,
  children: PropTypes.any,
};

Dropdown.defaultProps = {
  testId  : 'dropdown',
  onChange: () => {},
  multiple: false,
  children: undefined
};

export default Dropdown;
