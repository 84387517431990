/* eslint-disable react-hooks/exhaustive-deps */

import DropdownItem from 'components/Forms/Dropdown/DropdownItem/index';
import Dropdown from 'components/Forms/Dropdown/index';
import Text from 'components/Typografy/Text/index';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";

import {Types as CardBrands} from '../../../../../store/ducks/Conciliation/CardBrands';

function CardBrandsDropDown({
  register
}) {

  const dispatch = useDispatch();

  useEffect(() => {

    dispatch({type:CardBrands.GET_CARDBRANDS});

  },[])

    const datacardBrands = useSelector(cardBrands => cardBrands.CardBrandsReducer)

    
    return(
      <>
        <Text fontWeight={600}>Bandeira</Text>
        <Dropdown
          name="cardBrands"
          testId="dropdown-card-brands-filter"
          register={register}
          multiple
        >
          {datacardBrands.loading === true ? <p>Carregando...</p> :
           datacardBrands.data.map(item => (

             <DropdownItem 
               key={item.id} 
               value={item.id} 
               label={item.description}
             /> 
              
            ))}

        </Dropdown>
      </>
    );
}

CardBrandsDropDown.propTypes = {
    register: PropTypes.func.isRequired,
};

export default CardBrandsDropDown;
