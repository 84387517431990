/* eslint-disable no-underscore-dangle */
/* eslint-disable func-names */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useContext } from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import api from 'services/api';

import HomeDateContext from '../context';
import Loading from './loading';
import FinancialSummaryChartTooltip from './tooltip';

const FinancialSummaryChart = ({
  handleSumarization
}) => {
  const _handleSumarization = handleSumarization || function () { };
  const [data, setData] = useState([]);
  const [chartStatus, setChartStatus] = useState({
    chartLoadingStatus: 'idle',
    errorMessage: '',
  });

  const { startDate, endDate } = useContext(HomeDateContext);

  useEffect(() => {
    setChartStatus({
      ...setChartStatus,
      chartLoadingStatus: 'loading',
    });

    api
      .get(
        `Home/DadosGrafico?inicio=${startDate.format(
          'Y-MM-DD',
        )}&fim=${endDate.format('Y-MM-DD')}`,
      )
      .then(response => {
        setData(response.data.Valores);
        setChartStatus({
          ...setChartStatus,
          chartLoadingStatus: 'loaded',
        });
        _handleSumarization({ data: { balance: response.data.Saldo, payments: response.data.Pagamentos, receipts: response.data.Recebimentos }, status: 'loaded', err: null });
      })
      .catch(err => {
        setChartStatus({
          chartLoadingStatus: 'error',
          errorMessage: err.response,
        });
        _handleSumarization({ data: [], status: 'error', err: err.response });
      });
  }, [startDate, endDate]);

  const formatYAxis = value => {
    const absValue = Math.abs(value);
    const signal = value < 0 ? '-' : '';

    if (absValue < 1000) {
      return `${signal}${value.toString()}`;
    }

    if (absValue >= 1000 && absValue < 1000000) {
      return `${signal}${absValue / 1000}k`;
    }

    if (absValue >= 1000000 && absValue < 1000000000) {
      return `${signal}${absValue / 1000000}m`;
    }
    return `${signal}${absValue.toString()}`;
  };

  const formatXAxisLabel = values => {
    const { x, y, payload } = values;
    
    if(!payload.value) payload.value = ' ';    
    const day = payload.value.split(' ')[0];
    const month = payload.value.split(' ')[1];

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          fontSize={10}
          fontFamily="Open Sans"
          x={5}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
        >
          <tspan y={0}>{day}</tspan>
          <tspan y={25} x={month.length > 3 ? 15 : 7}>
            {month}
          </tspan>
        </text>
      </g>
    );
  };

  switch (chartStatus.chartLoadingStatus) {
    case 'error':
      return (
        <h1>Ocorreu um erro ao gerar o gráfico. Tente novamente mais tarde.</h1>
      );
    case 'loaded':
      return (
        <ResponsiveContainer width="100%" height={300}>
          <AreaChart
            width={1000}
            height={300}
            data={data}
            margin={{ top: 10, right: 30, left: 0, bottom: 10 }}
          >
            <defs>
              <linearGradient id="colorBalance" x1="0" y1="0" x2="0" y2="1">
                <stop offset="1%" stopColor="#0285ff" stopOpacity={0.5} />
                <stop offset="20%" stopColor="#0285ff" stopOpacity={0.2} />
                <stop offset="50%" stopColor="#0285ff" stopOpacity={0.1} />
              </linearGradient>
              <linearGradient id="colorReceipts" x1="0" y1="0" x2="0" y2="1">
                <stop offset="1%" stopColor="#82ca9d" stopOpacity={0.5} />
                <stop offset="20%" stopColor="#82ca9d" stopOpacity={0.2} />
                <stop offset="50%" stopColor="#82ca9d" stopOpacity={0.1} />
              </linearGradient>
              <linearGradient id="colorPayments" x1="0" y1="0" x2="0" y2="1">
                <stop offset="1%" stopColor="#eb5757" stopOpacity={0.8} />
                <stop offset="20%" stopColor="#eb5757" stopOpacity={0.5} />
                <stop offset="50%" stopColor="#eb5757" stopOpacity={0.2} />
              </linearGradient>
            </defs>
            <XAxis
              dataKey="name"
              dy={10}
              tick={formatXAxisLabel}
              interval={0}
            />
            <YAxis
              dx={-10}
              width={50}
              tick={{ fontSize: 10, fontFamily: 'Open-Sans' }}
              tickFormatter={value => formatYAxis(value)}
            />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip content={<FinancialSummaryChartTooltip />} />
            <Area
              type="monotone"
              name="Saldo do dia"
              dataKey="balance"
              stroke="#0285ff"
              fillOpacity={1}
              fill="url(#colorBalance)"
            />
            <Area
              type="monotone"
              name="Recebimentos"
              dataKey="receipts"
              stroke="#23ff7f"
              fillOpacity={1}
              fill="url(#colorReceipts)"
            />
            <Area
              type="monotone"
              name="Pagamentos"
              dataKey="payments"
              stroke="#ff0000"
              fillOpacity={1}
              fill="url(#colorPayments)"
            />
          </AreaChart>
        </ResponsiveContainer>
      );
    default:
      return <></>;
    // return <Loading />;
  }
}
export default FinancialSummaryChart;