/* eslint-disable react-hooks/exhaustive-deps */

import chartIcon from 'assets/icons/chart.svg';
import Card from 'components/Grid/Card';
import {Scroll} from 'components/Scroll/index'
import SkeletonCardReceiveList from 'components/Skeleton/SkeletonCardReceiveList';
import ConciliationCard from 'pages/Conciliation/components/ConciliationCard/index'
import ConciliationContext from 'pages/Conciliation/ConciliationContext';
import React, {useCallback, useContext, useEffect } from 'react';
import { useSelector } from "react-redux"; 

export default function CardReceiveList() {
  
  const dataCardReceiveList = useSelector(state => state.FiltersBarsCardsReducer);
  const {
    cardReceiveIds, 
    setCardReceiveIds, 
    selectedCardsReceive, 
    setSelectedCardsReceive, 
    setSystemSelectedValue,
    data
  } = useContext(ConciliationContext);

  const handleClick = useCallback((id) => {

    const include = selectedCardsReceive.some(x => x.idReceberCartao === id);

    const cardValue = dataCardReceiveList.cardReceive.records.find(x => x.idReceberCartao === id);

    if (include) {
        setSelectedCardsReceive(selectedCardsReceive.filter(x => x.idReceberCartao !== id));  
        setCardReceiveIds(cardReceiveIds.filter(x => x !== id));
    } else {
        setSelectedCardsReceive([...selectedCardsReceive, cardValue]);
        setCardReceiveIds([id, ...cardReceiveIds]);
    }
    
  });
  
  useEffect(() => {

    const value = 0;

    setSelectedCardsReceive([]);

    setCardReceiveIds([]);

    setSystemSelectedValue(value);

  }, [data])
  
    useEffect(() => {
    
    const value = selectedCardsReceive.reduce((sum, x) => {

      return (
        Number.parseFloat(sum) + Number.parseFloat(x.valorOriginal)
      ).toFixed(2);
      
    }, 0);
    
    setSystemSelectedValue(value);

  }, [selectedCardsReceive, setSystemSelectedValue]);


  if(dataCardReceiveList.receiveLength <=0 || dataCardReceiveList.loading) 
  return (
    <>
      {dataCardReceiveList.loading ? (
        <>  
          <Card width="100%">
            <Loader />
          </Card>
          <Card width="100%">
            <Loader />
          </Card>
          <Card width="100%">
            <Loader />
          </Card>
        </>
      ) :
        <NoResults />}
    </>
  );
  
  return (
    <>
      <Scroll>
        {dataCardReceiveList.cardReceive.records && 
            dataCardReceiveList.cardReceive.records.map(itemCard => (
              <ConciliationCard
                data-testid="test-card"
                name={itemCard.nomeCliente}
                isSelected={selectedCardsReceive.some(sel => sel.idReceberCartao === itemCard.idReceberCartao)}
                key={itemCard.idReceberCartao}
                date={itemCard.emissao}
                value={itemCard.idReceberCartao}
                grossValue={itemCard.valorOriginal}
                nsu={itemCard.nrCartao}
                authorizationCode={itemCard.nrAprovacaoCartao}
                idBandeira={itemCard.idBandeira}
                onClick={handleClick}
                nota={itemCard.nota}
              />
      ))}
      </Scroll>
   
    </>
  );
}

export const Loader = () => (
  <SkeletonCardReceiveList />
);

export const NoResults = () => {
  return (
    <div
      style={{
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '45px',
        width: '40%',
      }}
    >
      <img src={chartIcon} alt="chartIcon" />
    </div>
  )
};
