import { StyledDivider } from 'components/Grid/Divider/styles';
import PropTypes from 'prop-types';
import React from 'react';


export default function Divider({
  size,
  lineStyle,
  color,
  orientation,
  width,
}) {
  return (
    <StyledDivider
      size={size}
      lineStyle={lineStyle}
      color={color}
      orientation={orientation}
      width={width}
    />
  );
}

Divider.propTypes = {
  orientation: PropTypes.string,
  size: PropTypes.string,
  lineStyle: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.string,
};

Divider.defaultProps = {
  orientation: 'horizontal',
  size: '1px',
  lineStyle: 'solid',
  color: '#F2F2F2',
  width: '',
};
