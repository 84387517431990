import Text from 'components/Typografy/Text/index';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { withTheme } from 'styled-components';

import SelectStoreModal from '../SelectStoreModal/index';

const SelectStoreButton = ({ label }) => {
  const [showModalState, setShowModalState] = useState(false);

  function resetShowModalStateHandler() {
    setShowModalState(false);
  }

  function onShowModalVisible() {
    setShowModalState(!showModalState);
  }

  const showModal = () => (
    <SelectStoreModal onCloseCallback={resetShowModalStateHandler} />
  );

  return (
    <>
      {showModalState && showModal()}

      <Text
        color="#5AB0FF"
        onClick={() => onShowModalVisible()}
        fontSize="9px"
        textAlign="center"
        fontWeight={600}
        cursor="pointer"
      >
        {label}
      </Text>
    </>
  );
};

SelectStoreButton.propTypes = {
  label: PropTypes.string,
};

SelectStoreButton.defaultProps = {
  label: '',
};

export default withTheme(SelectStoreButton);
