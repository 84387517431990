import axios from 'axios';
import history from 'services/history-services';

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

// #region Validações Anteriores a 14/04/2023 - MODASP-18826
// const hashDux =
//   'eyJJZExpbnhPcGVyYWNpb25hbCI6MSwiSWRMaW54QWRtaW5pc3RyYXRpdm8iOjQsIklkR3BlY29uIjo0LCJJZFVzdWFyaW8iOjc1LCJOb21lVXN1YXJpbyI6ImRlc2Vudi5mcmFucXVlYWRvIiwiQ29ubmVjdGlvblN0cmluZyI6IkRhdGEgU291cmNlPWEtc3J2MTExXFxzcWwyMDE3OyBJbml0aWFsIENhdGFsb2c9REVWLVVYLUFwcC0zLTExOyBQZXJzaXN0IFNlY3VyaXR5IEluZm89VHJ1ZTsgVHJ1c3RlZF9Db25uZWN0aW9uPVRydWU7In0=';
// const uxHash =
//   urlParams.get('ux') ?? localStorage.getItem('@everest/HASH') ?? hashDux;
// const serviceUrl = urlParams.get('url');// ?? localStorage.getItem('@everest/URL');

// if (uxHash === null || uxHash === 'null') {
//   history.push('/restrict');
// }

// const getEnvironmentUrl = () => {
//   if(!!serviceUrl) return serviceUrl;

//   const { host } = window.location;
//   switch (host) {
//     case 'localhost:3000':
//       return 'http://localhost:1234/Linx.Manager';
//     case 'devlinxeverest.z13.web.core.windows.net':
//       return 'https://dev-linx-manager.linx.com.br';
//     case 'qalinxeverest.z13.web.core.windows.net':
//       return 'https://qa-linx-manager.linx.com.br';
//     case '172.24.228.81:85': // Ambiente dux boticário
//       return 'http://172.24.228.81:80';
//     default:
//       return serviceUrl;
//   }
// };

// const hashDux = 'eyJJZExpbnhPcGVyYWNpb25hbCI6MSwiSWRMaW54QWRtaW5pc3RyYXRpdm8iOjEsIklkR3BlY29uIjo0LCJJZFVzdWFyaW8iOjc1LCJOb21lVXN1YXJpbyI6IkRldmVsb3BlciIsIkNvbm5lY3Rpb25TdHJpbmciOiJEYXRhIFNvdXJjZT1hLXNydjExMVxcc3FsMjAxNztJbml0aWFsIENhdGFsb2c9UUEtVVgtQXBwLTMtMTI7SW50ZWdyYXRlZCBTZWN1cml0eT1TU1BJOyJ9=';
// #endregion

const neoxUrl = urlParams.get('neox') ?? localStorage.getItem('@everest/NeoxUrl') ?? 'http://localhost:12336/';
const uxHash = urlParams.get('ux') ?? localStorage.getItem('@everest/UxHash') ?? 'eyJJZExpbnhPcGVyYWNpb25hbCI6MSwiSWRMaW54QWRtaW5pc3RyYXRpdm8iOjEsIklkR3BlY29uIjo0LCJJZFVzdWFyaW8iOjc1LCJOb21lVXN1YXJpbyI6IkRldmVsb3BlciIsIkNvbm5lY3Rpb25TdHJpbmciOiJEYXRhIFNvdXJjZT1hLXNydjExMVxcc3FsMjAxNztJbml0aWFsIENhdGFsb2c9UUEtVVgtQXBwLTMtMTI7SW50ZWdyYXRlZCBTZWN1cml0eT1TU1BJOyJ9';
const serviceManager = urlParams.get('url') ?? localStorage.getItem('@everest/UrlManager') ?? 'http://localhost:1234/Linx.Manager';

const domains = [
  neoxUrl.charAt(neoxUrl.length - 1) === '/' ? neoxUrl.slice(0, -1) : neoxUrl
];

if (uxHash === null || uxHash === 'null') {
  history.push('/restrict');
}

const getEnvironmentUrl = () => {
  return serviceManager;
};

const api = axios.create({
  baseURL: getEnvironmentUrl(),
});
api.defaults.headers["Access-Control-Allow-Origin"] = window.location.origin;

api.defaults.headers.Authorization = `Bearer ${uxHash}`;
api.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 401) {
      history.push('/restrict');
    }

    return Promise.reject(error);
  },
);

localStorage.setItem('@everest/UxHash', uxHash);
localStorage.setItem('@everest/UrlManager', getEnvironmentUrl());
localStorage.setItem('@everest/NeoxUrl', neoxUrl);

window.addEventListener("message", getOriginLocalStorage, false);

function getOriginLocalStorage(event) {
  if (!domains.includes(event.origin))
    return;

  Object.entries(event.data).forEach(
    function setOriginLocalStorage(v){ 
      localStorage.setItem(v[0], v[1]);
    });
}

export default api;
