import Col from 'components/Grid/Col';
import Divider from 'components/Grid/Divider';
import Row from 'components/Grid/Row';
import Text from 'components/Typografy/Text';
import React from 'react'


const ConciliationTitle = () => {
  
  return (
    <>
      <Row>
        <Col justifyContent="flex-start">
          <Text fontSize="24px" color="#616161">Conciliação de venda de cartão</Text>
        </Col>
      </Row>
      <Divider />
    </>
  )
}

export default ConciliationTitle;
