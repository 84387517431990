import styled from 'styled-components';

export const Container = styled.div`
  background-color: #eb5757;
  border-radius: 4px;
  color: #ffffff;
  padding: 8px;
  font-size: 10px;
  font-weight: 600;
  width: 100%;
  justify-content: space-evenly;
  position: relative;
  cursor: ${props => (props.onClick ? 'pointer' : 'default')};
  max-width:1600px
`;

export const ImgWarning = styled.img`
  margin-right: 5px;
  vertical-align: top;
`;

export const ImgClose = styled.img`
  cursor: pointer;
  float: right;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 10px;
`;
