/* eslint-disable no-unused-vars */
import api from './api';


export default async function getBalanceAccounts(initialDate, finalDate) {
    return api.post(`saldo-contas-corrente`)
        .then(res => {
            return res.data;
        });
}

export async function getUpdatedOfx() {
    return api.post(`ultima-imporcao-ofx`).then(res => {
        return res.data;
    });
}
