export const Types = {
  GET_PENDENCIAS_FINANCEIRAS:
    '@Dashboard/PendenciasFinanceiras/GET_PENDENCIAS_FINANCEIRAS',
  GET_PENDENCIAS_FINANCEIRAS_SUCCESS:
    '@Dashboard/PendenciasFinanceiras/GET_PENDENCIAS_FINANCEIRAS_SUCCESS',
  GET_PENDENCIAS_FINANCEIRAS_ERROR:
    '@Dashboard/PendenciasFinanceiras/GET_PENDENCIAS_FINANCEIRAS_ERROR',
};

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: '',
};

export default function resultadosDre(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_PENDENCIAS_FINANCEIRAS: {
      return { data: [], loading: true, error: '' };
    }
    case Types.GET_PENDENCIAS_FINANCEIRAS_SUCCESS: {
      return {
        data: action.data,
        loading: false,
        error: '',
      };
    }
    case Types.GET_PENDENCIAS_FINANCEIRAS_ERROR: {
      return {
        data: [],
        loading: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
}
