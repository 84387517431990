import ConciliationContext from 'pages/Conciliation/ConciliationContext';
import React,{useState} from 'react';

import Cards from './components/Cards';
import ConciliationBarFiltros from './components/ConciliationBarFiltros/index';
import ConciliationBarsCards from './components/ConciliationBarsCards/index';
import ConciliationGraphic from './components/ConciliationGraphic/index';
import ConciliationTitle from './components/ConciliationTitle/index';


export default function Conciliation() {

  const initialDay   = new Date().getDate();
  const initialMonth = new Date().getMonth();
  const initialYear  = new Date().getFullYear();

  const finalDay  = new Date().getDate();
  const finalMonth  = new Date().getMonth();
  const finalYear = new Date().getFullYear();

  const initialDate = `${initialYear}-${initialMonth }-${initialDay}`;
  const finalDate   = `${finalYear}-${finalMonth + 1}-${finalDay}`;

  const newDate = {
      initialDate,
      finalDate,
      cardBrands  : [],
      acquirers   : [],
      subsidiaries: []
  }
    
    const [systemSelectedValue, setSystemSelectedValue]     = useState(0.0);
    const [acquirerSelectedValue, setAcquirerSelectedValue] = useState(0.0);
    const [acquirerSalesIds, setAcquirerSalesIds]           = useState([]);
    const [cardReceiveIds, setCardReceiveIds]               = useState([]);
    const [selectedCards, setSelectedCards]                 = useState([]);
    const [selectedCardsReceive, setSelectedCardsReceive]   = useState([]);
    const [conciliation, setConciliation]                   = useState([]);
    const [data, setData]                                   = useState(newDate);

    return (
      <>
        <ConciliationContext.Provider value={{
                systemSelectedValue,
                setSystemSelectedValue,
                acquirerSelectedValue,
                setAcquirerSelectedValue,
                cardReceiveIds,
                setCardReceiveIds,
                acquirerSalesIds,
                setAcquirerSalesIds,
                selectedCards,
                setSelectedCards,
                selectedCardsReceive,
                setSelectedCardsReceive,
                conciliation,
                setConciliation,
                data,
                setData
          }}
        >
          
          <ConciliationTitle />
          
          <ConciliationBarFiltros />
                
          <ConciliationGraphic />
                
          <ConciliationBarsCards />
                
          <Cards />
            
        </ConciliationContext.Provider>
        
      </>
    );
}
