/* eslint-disable no-unused-vars */
import { call, put } from 'redux-saga/effects';

import api from '../../../../../services/api';
import { Types as VisaoDeContaTypes } from '../../../../ducks/Dashboard/LucroLiquido/VisaoDeConta';

export function* getVisaoDeConta(action) {
  try {
    const response = yield call(api.get, 'Home/GetNetProfitParameters');

    if (response.status === 200) {
      yield put({
        type: VisaoDeContaTypes.GET_VISAO_DE_CONTA_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: VisaoDeContaTypes.GET_VISAO_DE_CONTA_ERROR,
      error,
    });
  }
}
