import Container from 'components/Grid/Container/index';
import React from 'react'
import Skeleton,{SkeletonTheme} from "react-loading-skeleton";

 const SkeletonConciliationChart = () => (
   <Container>
     <SkeletonTheme>
       <section style={{display:"flex", justifyContent:"center"}}>
        
         <div style={{display:"flex"}}>
           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"100px",
                margin:0,
                padding:0,
              }}
           />

           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"70px",
                margin:0,
                marginLeft:5,
                marginTop:33,
                padding:0,
              }}
           />

           <Skeleton
             duration={3}  
             style={{
                width:"10px",
                height:"30px",
                margin:0,
                marginLeft:5,
                marginTop:73,
                padding:0,
              }}
           />
         </div>

         <div style={{display:"flex", marginLeft:5}}>
           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"100px",
                margin:0,
                padding:0,
              }}
           />

           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"70px",
                margin:0,
                marginLeft:5,
                marginTop:33,
                padding:0,
              }}
           />

           <Skeleton
             duration={3}  
             style={{
                width:"10px",
                height:"30px",
                margin:0,
                marginLeft:5,
                marginTop:73,
                padding:0,
              }}
           />
         </div>

         <div style={{display:"flex", marginLeft:5}}>
           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"100px",
                margin:0,
                padding:0,
              }}
           />

           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"70px",
                margin:0,
                marginLeft:5,
                marginTop:33,
                padding:0,
              }}
           />

           <Skeleton
             duration={3}  
             style={{
                width:"10px",
                height:"30px",
                margin:0,
                marginLeft:5,
                marginTop:73,
                padding:0,
              }}
           />
         </div>

         <div style={{display:"flex", marginLeft:5}}>
           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"100px",
                margin:0,
                padding:0,
              }}
           />

           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"70px",
                margin:0,
                marginLeft:5,
                marginTop:33,
                padding:0,
              }}
           />

           <Skeleton
             duration={3}  
             style={{
                width:"10px",
                height:"30px",
                margin:0,
                marginLeft:5,
                marginTop:73,
                padding:0,
              }}
           />
         </div>

         <div style={{display:"flex", marginLeft:5}}>
           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"100px",
                margin:0,
                padding:0,
              }}
           />

           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"70px",
                margin:0,
                marginLeft:5,
                marginTop:33,
                padding:0,
              }}
           />

           <Skeleton
             duration={3}  
             style={{
                width:"10px",
                height:"30px",
                margin:0,
                marginLeft:5,
                marginTop:73,
                padding:0,
              }}
           />
         </div>

         <div style={{display:"flex", marginLeft:5}}>
           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"100px",
                margin:0,
                padding:0,
              }}
           />

           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"70px",
                margin:0,
                marginLeft:5,
                marginTop:33,
                padding:0,
              }}
           />

           <Skeleton
             duration={3}  
             style={{
                width:"10px",
                height:"30px",
                margin:0,
                marginLeft:5,
                marginTop:73,
                padding:0,
              }}
           />
         </div>

         <div style={{display:"flex", marginLeft:5}}>
           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"100px",
                margin:0,
                padding:0,
              }}
           />

           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"70px",
                margin:0,
                marginLeft:5,
                marginTop:33,
                padding:0,
              }}
           />

           <Skeleton
             duration={3}  
             style={{
                width:"10px",
                height:"30px",
                margin:0,
                marginLeft:5,
                marginTop:73,
                padding:0,
              }}
           />
         </div>

         <div style={{display:"flex", marginLeft:5}}>
           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"100px",
                margin:0,
                padding:0,
              }}
           />

           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"70px",
                margin:0,
                marginLeft:5,
                marginTop:33,
                padding:0,
              }}
           />

           <Skeleton
             duration={3}  
             style={{
                width:"10px",
                height:"30px",
                margin:0,
                marginLeft:5,
                marginTop:73,
                padding:0,
              }}
           />
         </div>

         <div style={{display:"flex", marginLeft:5}}>
           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"100px",
                margin:0,
                padding:0,
              }}
           />

           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"70px",
                margin:0,
                marginLeft:5,
                marginTop:33,
                padding:0,
              }}
           />

           <Skeleton
             duration={3}  
             style={{
                width:"10px",
                height:"30px",
                margin:0,
                marginLeft:5,
                marginTop:73,
                padding:0,
              }}
           />
         </div>

         <div style={{display:"flex", marginLeft:5}}>
           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"100px",
                margin:0,
                padding:0,
              }}
           />

           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"70px",
                margin:0,
                marginLeft:5,
                marginTop:33,
                padding:0,
              }}
           />

           <Skeleton
             duration={3}  
             style={{
                width:"10px",
                height:"30px",
                margin:0,
                marginLeft:5,
                marginTop:73,
                padding:0,
              }}
           />
         </div>

         <div style={{display:"flex", marginLeft:5}}>
           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"100px",
                margin:0,
                padding:0,
              }}
           />

           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"70px",
                margin:0,
                marginLeft:5,
                marginTop:33,
                padding:0,
              }}
           />

           <Skeleton
             duration={3}  
             style={{
                width:"10px",
                height:"30px",
                margin:0,
                marginLeft:5,
                marginTop:73,
                padding:0,
              }}
           />
         </div>

         <div style={{display:"flex", marginLeft:5}}>
           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"100px",
                margin:0,
                padding:0,
              }}
           />

           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"70px",
                margin:0,
                marginLeft:5,
                marginTop:33,
                padding:0,
              }}
           />

           <Skeleton
             duration={3}  
             style={{
                width:"10px",
                height:"30px",
                margin:0,
                marginLeft:5,
                marginTop:73,
                padding:0,
              }}
           />
         </div>

         <div style={{display:"flex", marginLeft:5}}>
           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"100px",
                margin:0,
                padding:0,
              }}
           />

           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"70px",
                margin:0,
                marginLeft:5,
                marginTop:33,
                padding:0,
              }}
           />

           <Skeleton
             duration={3}  
             style={{
                width:"10px",
                height:"30px",
                margin:0,
                marginLeft:5,
                marginTop:73,
                padding:0,
              }}
           />
         </div>

         <div style={{display:"flex", marginLeft:5}}>
           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"100px",
                margin:0,
                padding:0,
              }}
           />

           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"70px",
                margin:0,
                marginLeft:5,
                marginTop:33,
                padding:0,
              }}
           />

           <Skeleton
             duration={3}  
             style={{
                width:"10px",
                height:"30px",
                margin:0,
                marginLeft:5,
                marginTop:73,
                padding:0,
              }}
           />
         </div>

         <div style={{display:"flex", marginLeft:5}}>
           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"100px",
                margin:0,
                padding:0,
              }}
           />

           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"70px",
                margin:0,
                marginLeft:5,
                marginTop:33,
                padding:0,
              }}
           />

           <Skeleton
             duration={3}  
             style={{
                width:"10px",
                height:"30px",
                margin:0,
                marginLeft:5,
                marginTop:73,
                padding:0,
              }}
           />
         </div>

         <div style={{display:"flex", marginLeft:5}}>
           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"100px",
                margin:0,
                padding:0,
              }}
           />

           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"70px",
                margin:0,
                marginLeft:5,
                marginTop:33,
                padding:0,
              }}
           />

           <Skeleton
             duration={3}  
             style={{
                width:"10px",
                height:"30px",
                margin:0,
                marginLeft:5,
                marginTop:73,
                padding:0,
              }}
           />
         </div>

         <div style={{display:"flex", marginLeft:5}}>
           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"100px",
                margin:0,
                padding:0,
              }}
           />

           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"70px",
                margin:0,
                marginLeft:5,
                marginTop:33,
                padding:0,
              }}
           />

           <Skeleton
             duration={3}  
             style={{
                width:"10px",
                height:"30px",
                margin:0,
                marginLeft:5,
                marginTop:73,
                padding:0,
              }}
           />
         </div>

         <div style={{display:"flex", marginLeft:5}}>
           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"100px",
                margin:0,
                padding:0,
              }}
           />

           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"70px",
                margin:0,
                marginLeft:5,
                marginTop:33,
                padding:0,
              }}
           />

           <Skeleton
             duration={3}  
             style={{
                width:"10px",
                height:"30px",
                margin:0,
                marginLeft:5,
                marginTop:73,
                padding:0,
              }}
           />
         </div>

         <div style={{display:"flex", marginLeft:5}}>
           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"100px",
                margin:0,
                padding:0,
              }}
           />

           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"70px",
                margin:0,
                marginLeft:5,
                marginTop:33,
                padding:0,
              }}
           />

           <Skeleton
             duration={3}  
             style={{
                width:"10px",
                height:"30px",
                margin:0,
                marginLeft:5,
                marginTop:73,
                padding:0,
              }}
           />
         </div>

         <div style={{display:"flex", marginLeft:5}}>
           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"100px",
                margin:0,
                padding:0,
              }}
           />

           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"70px",
                margin:0,
                marginLeft:5,
                marginTop:33,
                padding:0,
              }}
           />

           <Skeleton
             duration={3}  
             style={{
                width:"10px",
                height:"30px",
                margin:0,
                marginLeft:5,
                marginTop:73,
                padding:0,
              }}
           />
         </div>

         <div style={{display:"flex", marginLeft:5}}>
           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"100px",
                margin:0,
                padding:0,
              }}
           />

           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"70px",
                margin:0,
                marginLeft:5,
                marginTop:33,
                padding:0,
              }}
           />

           <Skeleton
             duration={3}  
             style={{
                width:"10px",
                height:"30px",
                margin:0,
                marginLeft:5,
                marginTop:73,
                padding:0,
              }}
           />
         </div>

         <div style={{display:"flex", marginLeft:5}}>
           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"100px",
                margin:0,
                padding:0,
              }}
           />

           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"70px",
                margin:0,
                marginLeft:5,
                marginTop:33,
                padding:0,
              }}
           />

           <Skeleton
             duration={3}  
             style={{
                width:"10px",
                height:"30px",
                margin:0,
                marginLeft:5,
                marginTop:73,
                padding:0,
              }}
           />
         </div>

         <div style={{display:"flex", marginLeft:5}}>
           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"100px",
                margin:0,
                padding:0,
              }}
           />

           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"70px",
                margin:0,
                marginLeft:5,
                marginTop:33,
                padding:0,
              }}
           />

           <Skeleton
             duration={3}  
             style={{
                width:"10px",
                height:"30px",
                margin:0,
                marginLeft:5,
                marginTop:73,
                padding:0,
              }}
           />
         </div>

         <div style={{display:"flex", marginLeft:5}}>
           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"100px",
                margin:0,
                padding:0,
              }}
           />

           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"70px",
                margin:0,
                marginLeft:5,
                marginTop:33,
                padding:0,
              }}
           />

           <Skeleton
             duration={3}  
             style={{
                width:"10px",
                height:"30px",
                margin:0,
                marginLeft:5,
                marginTop:73,
                padding:0,
              }}
           />
         </div>

         <div style={{display:"flex", marginLeft:5}}>
           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"100px",
                margin:0,
                padding:0,
              }}
           />

           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"70px",
                margin:0,
                marginLeft:5,
                marginTop:33,
                padding:0,
              }}
           />

           <Skeleton
             duration={3}  
             style={{
                width:"10px",
                height:"30px",
                margin:0,
                marginLeft:5,
                marginTop:73,
                padding:0,
              }}
           />
         </div>

         <div style={{display:"flex", marginLeft:5}}>
           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"100px",
                margin:0,
                padding:0,
              }}
           />

           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"70px",
                margin:0,
                marginLeft:5,
                marginTop:33,
                padding:0,
              }}
           />

           <Skeleton
             duration={3}  
             style={{
                width:"10px",
                height:"30px",
                margin:0,
                marginLeft:5,
                marginTop:73,
                padding:0,
              }}
           />
         </div>

         <div style={{display:"flex", marginLeft:5}}>
           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"100px",
                margin:0,
                padding:0,
              }}
           />

           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"70px",
                margin:0,
                marginLeft:5,
                marginTop:33,
                padding:0,
              }}
           />

           <Skeleton
             duration={3}  
             style={{
                width:"10px",
                height:"30px",
                margin:0,
                marginLeft:5,
                marginTop:73,
                padding:0,
              }}
           />
         </div>

         <div style={{display:"flex", marginLeft:5}}>
           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"100px",
                margin:0,
                padding:0,
              }}
           />

           <Skeleton
             duration={3} 
             style={{
                width:"10px",
                height:"70px",
                margin:0,
                marginLeft:5,
                marginTop:33,
                padding:0,
              }}
           />

           <Skeleton
             duration={3}  
             style={{
                width:"10px",
                height:"30px",
                margin:0,
                marginLeft:5,
                marginTop:73,
                padding:0,
              }}
           />
         </div>
      

        

       </section>
     </SkeletonTheme>
   </Container>
);

export default SkeletonConciliationChart;
