import PropTypes from 'prop-types';
import React from 'react';
import { withTheme } from 'styled-components';
import theme from 'styles/theme';

import { StyledButton } from './styles';

const Button = ({
  name,
  register,
  testId,

  onClick,
  fontSize,
  backgroundColor,
  border,
  color,
  height,
  width,
  outline,
  children,
}) => (
  <StyledButton
    name={name}
    ref={register}
    data-testid={testId}
    border={border}
    color={color}
    onClick={onClick}
    backgroundColor={backgroundColor}
    height={height}
    width={width}
    outline={outline}
    fontSize={fontSize}
  >
    {children}
  </StyledButton>
);

Button.propTypes = {
  name: PropTypes.string,
  register: PropTypes.func,
  testId: PropTypes.string,

  onClick: PropTypes.func,

  backgroundColor: PropTypes.string,
  border: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  outline: PropTypes.bool,
  fontSize: PropTypes.string,

  children: PropTypes.any,
};

Button.defaultProps = {
  onClick: () => {},
  backgroundColor: theme.colors.principal,
  color: '#fff',
  border: 'none',
  width: '185px',
  height: '35px',
  outline: false,
  testId: 'button',
  fontSize: '13px',
  children: undefined,
  name: '',
  register: undefined
};

export default withTheme(Button);
