import {call, put} from 'redux-saga/effects';
import * as ConciliationServices from 'services/conciliationServices';

import {Types as Subsidiaries} from '../../../ducks/Conciliation/Subsidiaries'; 

export function* getSubsidiaries(){
  try {
    
    const response = yield call(ConciliationServices.getSubsidiaries);

    yield put({
      type: Subsidiaries.GET_SUBSIDIARIES_SUCCESS,
      data:response
    })
    
  } catch (error) {

    yield put({
      type: Subsidiaries.GET_SUBSIDIARIES_ERROR, error
    })
    
  }
}
