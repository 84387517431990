import Conciliation from 'pages/Conciliation';
import NotAuthorizedPage from 'pages/ErrorPages/NotAuthorizedPage';
import FinancialMovement from 'pages/FinancialMovement';
import Home from 'pages/Home';
import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

export default function MainRoutes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/restrict" component={NotAuthorizedPage} />
        <Route path="/conciliation" component={Conciliation} />
        <Route path="/financial-movement" component={FinancialMovement} />
        <Route path="/" component={Home} />
      </Switch>
    </BrowserRouter>
  );
}
