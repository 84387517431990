import styled, { css } from 'styled-components';

export const Container = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: ${props => props.fontSize};
  line-height: 13px;
  display: flex;
  align-items: flex-end;
  width: ${props => props.width};
  color: ${props => props.color};

  ${props =>
    props.bgColor !== '' &&
    css`
      background-color: ${props.bgColor};
      padding: 0 0.5rem;
      border-radius: 3px;
    `}
`;

// display: flex;
//   margin: 0 5px;
//   color: #ffffff;
//   background-color: #3ea2ff;
//   position: relative;
//   padding: 0 0.5rem;
//   padding-left: 2rem;
//   border-radius: 3px;
//   justify-content: flex-start;
//   align-items: center;
