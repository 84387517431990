import styled, {css} from 'styled-components';


export const StyledCol = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: ${ props => props.padding || props.theme.spacing.padding10 };
  justify-content: ${ props => props.justifyContent };
  justify-self: ${ props => props.justifySelf };
  align-items: ${ props => props.alignItems };
  align-self: ${ props => props.alignSelf };

  ${ props =>
    props.size &&
    css`
      flex: 0 1 ${ (100 / 12) * props.size }%;
      max-width: ${ (100 / 12) * props.size }%;
    ` }
`;
