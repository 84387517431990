import styled from 'styled-components';


export const StyledParagraph = styled.p`
  padding: ${ props => props.padding };
  
  text-align: ${ props => props.textAlign };
  text-justify: inter-character;
  
  font-size: ${ props => props.fontSize };
  font-weight: ${ props => props.fontWeight };
  color: ${ props => props.color };
  cursor: ${ props => props.cursor };
  width: auto;
`;
