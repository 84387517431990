import PropTypes from 'prop-types';
import React from 'react';

import {StyledTable} from './styles';


function Table({ 
   children, 
   width, 
   height 
}) {
    return (
      <StyledTable width={width} height={height}>
        {children}
      </StyledTable>
    );
}

Table.propTypes = {
    children: PropTypes.any,
    width: PropTypes.string,
    height: PropTypes.string
};

Table.defaultProps = {
  width: '100%',
  height: 'auto',
  children: undefined
};

export default Table;
