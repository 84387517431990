import {createContext} from 'react';

  
    
    const ConciliationContext = createContext({
        
        // Passa as informações de valores selecionados dos cards
        systemSelectedValue:0.0,
        setSystemSelectedValue:() => {},

        acquirerSelectedValue:0.0,
        setAcquirerSelectedValue:() => {},


        // Passa as informações dos Ids selecionados dos cards
        acquirerSalesIds:[],
        setAcquirerSalesIds:() => {},

        cardReceiveIds:[],
        setCardReceiveIds: ()=> {},

        // Passa as informações dos estados selecionados dos cards
        selectedCards:[],
        setSelectedCards:() => {},

        selectedCardsReceive:[],
        setSelectedCardsReceive: ()=> {},

        // resposta da conciliação
        conciliation:[],
        setConciliation: ()=> {},

        // pegando resposta dos filtros
        data: {},
        setData: ()=> {}
    });


export default ConciliationContext;
