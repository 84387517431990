import { all, takeLatest } from 'redux-saga/effects';

import { Types as Acquirers } from './ducks/Conciliation/Acquirers';
import { Types as CardBrands } from './ducks/Conciliation/CardBrands';
import { Types as Conciliation } from './ducks/Conciliation/ConciliationAcquirerList';
import { Types as ConciliationReceive } from './ducks/Conciliation/ConciliationCardReceiveList';
import { Types as FiltersBarsCards } from './ducks/Conciliation/FiltersBars';
import { Types as FiltersChart } from './ducks/Conciliation/FiltersChart';
import { Types as Subsidiaries } from './ducks/Conciliation/Subsidiaries';
import { Types as ConferenciaDeCaixa } from './ducks/Dashboard/Conferencia&Conciliacao/ConferenciaDeCaixa';
import { Types as ContaBancaria } from './ducks/Dashboard/Conferencia&Conciliacao/ContaBancaria';
import { Types as VendaDeCartao } from './ducks/Dashboard/Conferencia&Conciliacao/VendaDeCartao';
import { Types as ObterConta } from './ducks/Dashboard/LucroLiquido/ObterConta';
import { Types as ObterVisao } from './ducks/Dashboard/LucroLiquido/ObterVisao';
import { Types as LucroLiquido } from './ducks/Dashboard/LucroLiquido/Ranking';
import { Types as UpdateVisaoConta } from './ducks/Dashboard/LucroLiquido/UpdateVisaoConta';
import { Types as VisaoDeConta } from './ducks/Dashboard/LucroLiquido/VisaoDeConta';
import { Types as PendenciasFinanceiras } from './ducks/Dashboard/PendenciasFinanceiras';
import { Types as ResultadosDre } from './ducks/Dashboard/ResultadosDRE';
import { Types as SaldoDisponivelAntecipacao } from './ducks/Dashboard/SaldoDisponivelAntecipacao';
import { getAcquirers } from './sagas/Conciliation/Acquirers';
import { getCardBrands } from './sagas/Conciliation/CardBrands';
import { getConciliation } from './sagas/Conciliation/Conciliation';
import {
  getFiltersChart,
  getFiltersBars,
} from './sagas/Conciliation/FiltersBars';
import { getSubsidiaries } from './sagas/Conciliation/Subsidiaries';
import { getConferenciaDeCaixa } from './sagas/Dashboard/Conferencia&Conciliacao/ConferenciaDeCaixa';
import { getContaBancaria } from './sagas/Dashboard/Conferencia&Conciliacao/ContaBancaria';
import { getVendaDeCartao } from './sagas/Dashboard/Conferencia&Conciliacao/VendaDeCartao';
import { getObterConta } from './sagas/Dashboard/LucroLiquido/ObterConta';
import { getObterVisao } from './sagas/Dashboard/LucroLiquido/ObterVisao';
import { getLucroLiquido } from './sagas/Dashboard/LucroLiquido/Ranking';
import { setUpdateVisao } from './sagas/Dashboard/LucroLiquido/UpdateVisaoConta';
import { getVisaoDeConta } from './sagas/Dashboard/LucroLiquido/VisaoDeConta';
import { getPendenciasFinanceiras } from './sagas/Dashboard/PendenciasFinanceiras';
import { getResultadosDre } from './sagas/Dashboard/ResultadosDRE';
import { getSaldoDisponivelAntecipacao } from './sagas/Dashboard/SaldoDisponivelAntecipacao';

export default function* rootSaga() {
  return yield all([
    takeLatest(Acquirers.GET_ACQUIRERS, getAcquirers),
    takeLatest(CardBrands.GET_CARDBRANDS, getCardBrands),
    takeLatest(Subsidiaries.GET_SUBSIDIARIES, getSubsidiaries),
    takeLatest(FiltersChart.GET_FILTERSBARS_CHART, getFiltersChart),
    takeLatest(Conciliation.GET_CONCILIATION_ACQUIRER, getConciliation),
    takeLatest(ConciliationReceive.GET_CONCILIATION_RECEIVE, getConciliation),
    takeLatest(FiltersBarsCards.GET_FILTERSBARS, getFiltersBars),
    takeLatest(ResultadosDre.GET_RESULTADOS_DRE, getResultadosDre),
    takeLatest(LucroLiquido.GET_LUCRO_LIQUIDO, getLucroLiquido),
    takeLatest(ObterVisao.GET_OBTER_VISAO, getObterVisao),
    takeLatest(ObterConta.GET_OBTER_CONTA, getObterConta),
    takeLatest(UpdateVisaoConta.SET_VISAO, setUpdateVisao),
    takeLatest(VisaoDeConta.GET_VISAO_DE_CONTA, getVisaoDeConta),
    takeLatest(
      PendenciasFinanceiras.GET_PENDENCIAS_FINANCEIRAS,
      getPendenciasFinanceiras,
    ),
    takeLatest(
      SaldoDisponivelAntecipacao.GET_SALDO_DISPONIVEL_ANTECIPACAO,
      getSaldoDisponivelAntecipacao,
    ),
    takeLatest(VendaDeCartao.GET_VENDA_DE_CARTAO, getVendaDeCartao),
    takeLatest(ContaBancaria.GET_CONTA_BANCARIA, getContaBancaria),
    takeLatest(
      ConferenciaDeCaixa.GET_CONFERENCIA_DE_CAIXA,
      getConferenciaDeCaixa,
    ),
  ]);
}
